import { Controller } from '@hotwired/stimulus'

export default class SettingsFieldController extends Controller {
  static targets = ['buttons', 'input']
  static classes = ['error', 'invalidInput']

  initialize () {
    if (this.isErrorVisible()) {
      this.initialButtonsPaddingBottom = this.stylePropertyPixelValue(this.buttonsTarget, 'padding-bottom')
      this.buttonsTarget.style = `padding-bottom: ${this.errorElementHeight()}px;`
    }
  }

  reset () {
    if (this.isErrorVisible()) {
      this.errorElement().remove()
      this.inputTarget.classList.remove(this.invalidInputClass)
      this.buttonsTarget.style = `padding-bottom: ${this.initialButtonsPaddingBottom}px;`
    }
  }

  isErrorVisible () {
    return this.errorElement() !== null
  }

  errorElement () {
    return this.element.querySelector(`.${this.errorClass}`)
  }

  errorElementHeight () {
    let height = this.errorElement().offsetHeight
    height += this.stylePropertyPixelValue(this.errorElement(), 'margin-top')
    height += this.stylePropertyPixelValue(this.errorElement(), 'margin-bottom')

    return height
  }

  stylePropertyPixelValue (element, styleProperty) {
    return parseInt(window.getComputedStyle(element).getPropertyValue(styleProperty))
  }
}
