import { Controller } from '@hotwired/stimulus'
import { useWindowResize } from 'stimulus-use'

export default class ModalBodyFixElementHeightController extends Controller {
  static targets = ['fixedHeightElement']

  static values = {
    offset: Number
  }

  connect () {
    useWindowResize(this)
    setTimeout(() => { this.windowResize() }, 100)
  }

  windowResize () {
    const bodyHeight = this.element.clientHeight
    this.fixedHeightElementTargets.forEach(element => {
      element.style.height = `${bodyHeight - this.offsetValue}px`
    })
  }
}
