import { Controller } from '@hotwired/stimulus'

export default class PresetProfileTemplateController extends Controller {
  static values = {
    templateSlug: String,
    colorSchemeSlug: String,
    typographySlug: String,
    sidebarEditorFormId: String
  }

  setPreviewValues () {
    const form = document.querySelector(this.sidebarEditorFormIdValue)

    form.querySelector(`input[name='template'][value=${this.templateSlugValue}]`).checked = true
    form.querySelector(`input[name='typography'][value=${this.typographySlugValue}]`).checked = true
    form.querySelector(`input[name='color_scheme'][value=${this.colorSchemeSlugValue}]`).checked = true
  }
}
