import { Controller } from '@hotwired/stimulus'

export default class CycleVisibilityController extends Controller {
  static targets = ['item']
  // If you would like to have a particular step take longer than the others,
  // pass in a customTimings object with the index of the step as the key and
  // the length of time in milliseconds as the value.
  static values = {
    defaultTiming: Number,
    customTimings: Object
  }

  connect () {
    this.showItem(0)
  }

  showItem (index) {
    this.itemTargets.forEach((el, i) => {
      if (i === index) {
        el.classList.remove('d-none')
      } else {
        el.classList.add('d-none')
      }
    })

    if (index < this.itemTargets.length - 1) {
      setTimeout(() => {
        this.showItem(index + 1)
      }, this.timeoutLength(index))
    }
  }

  timeoutLength (index) {
    if (index in this.customTimingsValue) {
      return this.customTimingsValue[index]
    } else {
      return this.defaultTimingValue
    }
  }

  get defaultTimingValue () {
    return this.hasDefaultTimingValue ? this.defaultTimingValue : 5000
  }
}
