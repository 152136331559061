import { Controller } from '@hotwired/stimulus'

export default class SearchWithFormDataController extends Controller {
  static values = {
    endpoint: String,
    resultsFrameId: String
  }

  search () {
    const form = this.element.closest('form')
    const resultsContainer = document.getElementById(this.resultsFrameIdValue)

    const formData = new FormData(form)
    const asString = new URLSearchParams(formData).toString()
    const newURL = this.endpointValue + '?' + asString

    resultsContainer.src = newURL
  }
}
