import { Controller } from '@hotwired/stimulus'

const CLOSE_BUTTON_HTML = `
<button
  type="button"
  class="embed-close-button url-embed-close-button position-absolute border-none m-2 p-0"
  data-action= "click->dynamic-content-editor-remove-embeds#removeEmbed"
></button>
`
export default class extends Controller {
  async connect () {
    this.element.classList.add('position-relative')
    this.element.setAttribute('data-controller', 'unfurler dynamic-content-editor-remove-embeds')
    this.element.setAttribute('data-dynamic-content-editor-remove-embeds-target', 'urlEmbed')
    this.element.insertAdjacentHTML('beforeend', CLOSE_BUTTON_HTML)
  }
}
