import { Controller } from '@hotwired/stimulus'
import { useIntersection } from 'stimulus-use'

export default class ScrollToTopButtonController extends Controller {
  static targets = ['button']
  static classes = ['showButton']

  connect () {
    useIntersection(this, {
      rootMargin: '0px'
    })
  }

  appear () {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove(this.showButtonClass)
    }
  }

  disappear () {
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.add(this.showButtonClass)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToTop () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
}
