import { Controller } from '@hotwired/stimulus'

export default class NewOrganisationController extends Controller {
  static targets = [
    'displayName',
    'domain',
    'placeholderContainer',
    'placeholderDisplayName',
    'placeholderDomain'
  ]

  static classes = ['hiddenPlaceholder']

  connect () {
    this.setPlaceholderDisplay()
  }

  updateOrganisationName () {
    this.placeholderDisplayNameTarget.innerHTML = this.displayNameTarget.value
  }

  updateOrganisationDomain () {
    this.placeholderDomainTarget.innerHTML = this.domainTarget.value
  }

  setPlaceholderDisplay () {
    if (this.displayNameTarget.value || this.domainTarget.value) {
      this.showPlaceholderOrganisation()
    } else {
      this.hidePlaceholderOrganisation()
    }
  }

  updateSearchInput (e) {
    this.updateOrganisationName()
    this.updateOrganisationDomain()
    this.setPlaceholderDisplay()
  }

  showPlaceholderOrganisation () {
    this.placeholderContainerTarget.classList.remove(
      this.hiddenPlaceholderClass
    )
  }

  hidePlaceholderOrganisation () {
    this.placeholderContainerTarget.classList.add(this.hiddenPlaceholderClass)
  }
}
