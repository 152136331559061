import Rails from '@rails/ujs'
import NativeMenuController from './shared/native_menu_controller'

export default class extends NativeMenuController {
  static component = 'action-sheet'
  static values = {
    title: String,
    description: String,
    iconName: String
  }

  openNativeMenu (event) {
    if (!this.enabled) return
    if (event.currentTarget.dataset.skipConfirm === 'true') {
      return
    }

    event.stopImmediatePropagation()
    event.preventDefault()

    this.displayNativeMenu(this.buildMenuData())
  }

  buildMenuData () {
    return {
      title: this.titleValue,
      description: this.descriptionValue,
      icon_name: this.iconNameValue,
      actions: this.buildActionsArray()
    }
  }

  onActionSelected (actionItem) {
    Rails.fire(this.itemTargets[actionItem.tag], 'click')
  }
}
