import { Component } from '../../vendors/strada'

/*
  https://developer.apple.com/documentation/uikit/uifeedbackgenerator#

  Suppported style values:
    selection
    error
    success
    warning
    impactHeavy
    impactLight
    impactMedium
    impactRigid
    impactSoft
*/

export default class extends Component {
  static component = 'haptics'
  static values = {
    style: String
  }

  connect () {
    super.connect()
    if (!this.enabled) { return }

    this.send('connect', { style: this.styleValue })
  }

  vibrate () {
    this.send('vibrate')
  }
}
