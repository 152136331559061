import { Controller } from '@hotwired/stimulus'
import fitty from 'fitty'
import { every } from 'lodash'

export default class FitTextController extends Controller {
  static values = {
    minSize: Number,
    maxSize: Number
  }

  connect () {
    this.drawFittyInterval = setInterval(
      this.drawFitty.bind(this),
      20
    )
  }

  get minSize () {
    return this.hasMinSizeValue ? this.minSizeValue : 14
  }

  get maxSize () {
    return this.hasMaxSizeValue ? this.maxSizeValue : 350
  }

  disconnect () {
    if (this.drawFittyInterval) clearInterval(this.drawFittyInterval)
  }

  drawFitty () {
    if (!document.documentElement.classList.contains('ui-fonts')) return

    const fitties = fitty('.fit-item', {
      minSize: this.minSize,
      maxSize: this.maxSize
    })

    if (every(fitties, this.hasFontSizeSet)) {
      clearInterval(this.drawFittyInterval)
    }
  }

  hasFontSizeSet (fitty) {
    return fitty.element.hasAttribute('style') && fitty.element.getAttribute('style').indexOf('font-size:') !== -1
  }
}
