import { Controller } from '@hotwired/stimulus'

export default class RedirectOnConnectController extends Controller {
  static values = {
    endpoint: String
  }

  connect () {
    window.location.replace(this.endpointValue)
  }
}
