import { Controller } from '@hotwired/stimulus'

export default class ScreenResizeToggleController extends Controller {
  static targets = ['radioButton', 'body']
  static classes = ['mobile']

  enableMobileMode () {
    this.bodyTarget.classList.add(this.mobileClass)
  }

  enableDesktopMode () {
    this.bodyTarget.classList.remove(this.mobileClass)
  }
}
