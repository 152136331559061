import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['replace']

  static values = {
    expanded: String
  }

  expand (event) {
    // prevent navigation to wrapping link when clicked on opportunities index view
    event.stopPropagation()
    this.replaceTarget.innerHTML = this.expandedValue
  }
}
