import { Controller } from '@hotwired/stimulus'
import 'bootstrap-datepicker'
import dayJS from 'dayjs'
import { originatesFrom } from '../utils/events'

const originatesFromDatePicker = originatesFrom(
  '.dropdown-highlight-date-picker'
)
const originatesFromDropDown = originatesFrom('.dropdown-item')

export default class PostDisplayDateController extends Controller {
  static targets = [
    'datePicker',
    'date',
    'button',
    'leftChevronSvgTag',
    'rightChevronSvgTag'
  ]

  connect () {
    this.initializeDatePicker()
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  // eslint-disable-next-line class-methods-use-this
  initializeDatePicker () {
    $('#highlight-date')
      .datepicker({
        ...this.defaultDatePickerConfiguration,
        endDate: '0d',
        startDate: '1 1, 1900'
      })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => {
        this.setDateValue(e)
        this.hideDatePicker()
      })
  }

  get defaultDatePickerConfiguration () {
    return {
      format: 'M d, yyyy',
      startView: 'year',
      minViewMode: 0,
      maxViewMode: 2,
      orientation: 'bottom left',
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    }
  }

  get tomorrowsDate () {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }

  // eslint-disable-next-line class-methods-use-this
  preventModalPropagation (e) {
    e?.stopPropagation()
  }

  showDatePicker (e) {
    this.preventModalPropagation(e)
    this.datePickerTarget.classList.remove('d-none')
  }

  hideDatePicker (e) {
    this.preventModalPropagation(e)
    this.datePickerTarget.classList.add('d-none')
  }

  get isDatePickerShown () {
    return !this.datePickerTarget.classList.contains('d-none')
  }

  // eslint-disable-next-line class-methods-use-this
  isOutsideClick (event) {
    return !originatesFromDatePicker(event) && !originatesFromDropDown(event)
  }

  handleOutsideClick = event => {
    if (this.isDatePickerShown && this.isOutsideClick(event)) {
      this.hideDatePicker()
    }
  }

  setDateValue (e) {
    this.dateTarget.value = dayJS(e.date).format('DD-MM-YYYY')
    this.buttonTarget.innerHTML = dayJS(e.date).format('MMM D, YYYY')
  }
}
