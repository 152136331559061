import { Controller } from '@hotwired/stimulus'

export default class TriggerClaimAccountModalController extends Controller {
  static values = {
    waitForEventToInitialize: Boolean,
    modalPath: String
  }

  connect () {
    if (this.waitForEventToInitializeValue) {
      window.addEventListener('message-to-event-handler:tooltip-tour-completed', this.initializeController.bind(this))
    } else {
      this.initializeController()
    }
  }

  initializeController () {
    setTimeout(() => {
      this.triggerFetch()
    }, 15000)
  }

  triggerFetch () {
    if (!this.modalContainer.src) {
      this.modalContainer.src = this.modalPathValue
    }
  }

  get modalContainer () {
    return document.querySelector('#modal_container')
  }
}
