import { Controller } from '@hotwired/stimulus'
import textFit from 'textfit'

export default class MultiLineFitTextController extends Controller {
  static values = {
    multiLine: Boolean,
    widthOnly: Boolean,
    maxFontSize: Number
  }

  connect () {
    this.initialFitCompleted = false

    this.fitInterval = setInterval(
      this.doFit.bind(this),
      20
    )
  }

  get multiLine () {
    return this.hasMultiLineValue ? this.multiLineValue : true
  }

  get widthOnly () {
    return this.hasWidthOnlyValue ? this.widthOnlyValue : false
  }

  get maxFontSize () {
    return this.hasMaxFontSizeValue ? this.maxFontSizeValue : 300
  }

  get elementWidth () {
    return this.element.getBoundingClientRect().width
  }

  get fittedElement () {
    return this.element.getBoundingClientRect().width
  }

  get fittedWidth () {
    return this.element.querySelector('.textFitted').getBoundingClientRect().width
  }

  doFit () {
    if (!document.documentElement.classList.contains('ui-fonts') || this.elementWidth === 0) return

    const timeoutValue = this.initialFitCompleted ? 0 : 50

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.setElementWidth()
        this.element.classList.remove('d-none')
        textFit(this.element, { multiLine: this.multiLine, widthOnly: this.widthOnly, maxFontSize: this.maxFontSize })
        clearInterval(this.fitInterval)
        this.initialFitCompleted = true
      })
    }, timeoutValue)
  }

  setElementWidth () {
    this.element.style.width = this.elementWidth
  }

  hasFontSizeSet () {
    return this.element.hasAttribute('style') && this.element.getAttribute('style').indexOf('font-size:') !== -1
  }
}
