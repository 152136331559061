import { Component } from '../../vendors/strada'
import { format, parseISO } from 'date-fns'

export default class extends Component {
  static component = 'date-picker'

  static values = {
    mode: String,
    selectedDate: String,
    maxDate: String,
    minDate: String,
    format: String
  }

  toggle (event) {
    if (!this.enabled) { return }

    event.preventDefault() && event.stopPropagation() && event.stopImmediatePropagation()

    this.send('connect', {
      mode: this.modeValue || 'date',
      selectedDate: `${this.selectedDateValue}Z`,
      maxDate: this.maxDateValue,
      minDate: this.minDateValue
    }, ({ data: { selectedDate } }) => {
      selectedDate = selectedDate.replace(/Z$/, '') // Remove timezone indicator from the end
      this.element.value = this.formatDate(selectedDate)
      this.selectedDateValue = selectedDate
    })
  }

  formatDate (iso8601) {
    if (this.hasFormatValue) {
      return format(parseISO(iso8601), this.formatValue)
    } else {
      return iso8601
    }
  }
}
