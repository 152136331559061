import $ from 'jquery'
import { Controller } from '@hotwired/stimulus'

export default class CarouselTwitterLoaderController extends Controller {
  connect () {
    $.getScript('https://platform.twitter.com/widgets.js', function () {
      const event = new CustomEvent('carousel:loaded', {
        bubbles: true,
        cancelable: true
      })
      const element = this.element
      setTimeout(function () {
        element.dispatchEvent(event)
      }, 2500)
    }.bind(this))
  }
}
