import { Controller } from '@hotwired/stimulus'

export default class HighlightOpportunityMaxSizeController extends Controller {
  static targets = ['errorPanel']
  static classes = ['hiddenPanel']

  displayError () {
    this.errorPanelTarget.classList.remove(this.hiddenPanelClass)
  }
}
