import XMLHttpRequest from 'xhr2'

export function turboVisitPostRedirect (baseUrl, queryParams) {
  const http = new XMLHttpRequest()
  const url = baseUrl + '?' + new URLSearchParams(queryParams).toString()

  http.open('POST', url)
  http.onload = () => {
    Turbo.visit(http.getResponseHeader('X-Turbo-Transition'))
  }
  http.send()
}
