import { Controller } from '@hotwired/stimulus'

export default class ChoiceSelectionController extends Controller {
  static targets = ['countInput', 'operatorInput', 'choiceIdInput', 'button', 'customField']
  static values = {
    inactiveClasses: String,
    activeClasses: String
  }

  connect () {
    if (
      this.hasCountInputTarget &&
      this.hasOperatorInputTarget &&
      this.countInputTarget.value &&
      this.operatorInputTarget.value
    ) {
      this.preselectChoice(this.countInputTarget.value, this.operatorInputTarget.value)
    } else if (this.hasChoiceIdInputTarget && this.choiceIdInputTarget.value) {
      this.preselectChoiceById(this.choiceIdInputTarget.value)
    }
  }

  onKeyUp (event) {
    this.setValue(event.target.value, '=', null)
    this.setActiveButton(null)
  }

  onPress (event) {
    const value = event.target.getAttribute('data-value')
    const operator = event.target.getAttribute('data-operator')
    const id = event.target.getAttribute('data-id')
    this.clearInput()
    this.setValue(value, operator, id)
    this.setActiveButton(event.target)
  }

  setValue (value, operator, id) {
    if (this.hasCountInputTarget) this.countInputTarget.value = value
    if (this.hasOperatorInputTarget) this.operatorInputTarget.value = operator
    if (this.hasChoiceIdInputTarget) this.choiceIdInputTarget.value = id
  }

  preselectChoice (value, operator) {
    const selectedButton = this.buttonTargets.find(buttonTarget => (
      buttonTarget.getAttribute('data-value') === value && buttonTarget.getAttribute('data-operator') === operator
    ))

    if (selectedButton) {
      return this.setActiveButton(selectedButton)
    } else {
      this.customFieldTarget.value = value
    }
  }

  preselectChoiceById (id) {
    const selectedButton = this.buttonTargets.find(buttonTarget => (
      buttonTarget.getAttribute('data-id') === id
    ))

    if (selectedButton) {
      return this.setActiveButton(selectedButton)
    }
  }

  setActiveButton (button) {
    this.buttonTargets.forEach(buttonTarget => {
      this.removeClasses(buttonTarget, this.activeClasses)
      this.addClasses(buttonTarget, this.inactiveClasses)
    })

    if (button) {
      this.removeClasses(button, this.inactiveClasses)
      this.addClasses(button, this.activeClasses)
    }
  }

  addClasses (element, classNames) {
    classNames.forEach(className => {
      element.classList.add(className)
    })
  }

  removeClasses (element, classNames) {
    classNames.forEach(className => {
      element.classList.remove(className)
    })
  }

  clearInput () {
    if (this.hasCustomFieldTarget) {
      this.customFieldTarget.value = ''
    }
  }

  get inactiveClasses () {
    if (this.hasInactiveClassesValue) {
      return this.inactiveClassesValue.split(' ')
    }
    return ['text-gray-900']
  }

  get activeClasses () {
    if (this.hasActiveClassesValue) {
      return this.activeClassesValue.split(' ')
    }
    return ['bg-gray-900', 'text-white']
  }
}
