import { Controller } from '@hotwired/stimulus'

export default class ScrollToTopController extends Controller {
  static values = {
    scrollableElementId: String
  }

  connect () {
    setTimeout(() => {
      this.scrollableElement.scrollTo({ top: this.element.offsetTop, behavior: 'smooth' })
    }, 500)
  }

  get scrollableElement () {
    if (this.hasScrollableElementIdValue) {
      return document.getElementById(this.scrollableElementIdValue)
    } else {
      return window
    }
  }
}
