import { StradaController } from './strada_controller'
import { post } from '@rails/request.js'
import { hideElement } from '../../utils/dom'

// Connects to data-controller="strada--native-message-input"
export default class extends StradaController {
  static component = 'native-message-input'
  static values = {
    placeholder: String
  }

  connect () {
    super.connect()

    this.submitMessage = this.submitMessage.bind(this)

    if (!this.enabled) return

    // Hide the web form
    hideElement(this.element.parentNode)

    this.send('connect', {
      placeholder_text: this.placeholderValue
    }, ({ data }) => {
      this.submitMessage(data.text)
    })
  }

  showLoadingSpinner () {
    this.send('set_state', { state: 'loading' })
  }

  hideLoadingSpinner () {
    this.send('set_state', { state: 'default' })
  }

  async submitMessage (text) {
    this.send('set_state', { is_sending: true })

    const resp = await post(this.element.action, {
      body: {
        message: {
          body: text
        },
        raw_text: true
      },
      contentType: 'application/json',
      responseKind: 'html'
    })

    if (resp.ok) {
      this.send('reset')
    } else {
      this.send('set_state', { is_sending: false })
    }
  }
}
