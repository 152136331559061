import { Controller } from '@hotwired/stimulus'

export default class RemoteOptionSelectedController extends Controller {
  connect () {
    const event = new CustomEvent('remote-option:selected', {
      bubbles: true,
      cancelable: true
    })

    this.element.dispatchEvent(event)
  }
}
