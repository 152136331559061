import { Controller } from '@hotwired/stimulus'

export default class ActionOnEventController extends Controller {
  static classes = ['action']

  click () {
    this.element.click()
  }

  addClass () {
    this.element.classList.add(this.actionClass)
  }

  removeClass () {
    this.element.classList.remove(this.actionClass)
  }

  closeModal () {
    this.modalContainer.src = 'modals/close'
  }

  enable () {
    this.element.removeAttribute('disabled')
  }

  disable () {
    this.element.setAttribute('disabled', true)
  }

  submit () {
    Rails.fire(this.element, 'submit')
  }

  get modalContainer () {
    return document.querySelector('#modal_container')
  }
}
