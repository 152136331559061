import { Controller } from '@hotwired/stimulus'
import 'bootstrap-datepicker'
import dayJS from 'dayjs'
import { hasVariant } from '../utils/request'

export default class PositionController extends Controller {
  static targets = ['endDate', 'startDate', 'leftChevronSvgTag', 'rightChevronSvgTag']

  connect () {
    this.initializeDatePickers()
  }

  // eslint-disable-next-line class-methods-use-this
  initializeDatePickers () {
    if (hasVariant('ios')) return // Skip datepickers on iOS

    $('#onboarding-position-start-date').datepicker({
      format: 'M yyyy',
      startView: 'year',
      endDate: new Date(),
      minViewMode: 1,
      maxViewMode: 2,
      orientation: 'left',
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => this.setStartDateValue(e))

    $('#position-start-date').datepicker({
      format: 'M yyyy',
      startView: 'year',
      minViewMode: 1,
      maxViewMode: 2,
      orientation: 'left',
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => this.setStartDateValue(e))

    $('#position-end-date').datepicker({
      format: 'M yyyy',
      startView: 'year',
      minViewMode: 1,
      maxViewMode: 2,
      orientation: 'left',
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => this.setEndDateValue(e))

    $('#future-position-end-date').datepicker({
      format: 'yyyy',
      forceParse: 'yyyy',
      viewMode: 'years',
      minViewMode: 'years',
      startDate: new Date(),
      orientation: 'left',
      defaultViewDate: {
        month: 12
      },
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => this.setEndDateValue(e))
  }

  // eslint-disable-next-line class-methods-use-this
  preventModalPropagation (e) {
    e.stopPropagation()
  }

  setStartDateValue (e) {
    this.startDateTarget.value = dayJS(e.date).format('MMM YYYY')
  }

  setEndDateValue (e) {
    this.endDateTarget.value = dayJS(e.date).format('MMM YYYY')
  }

  disableEndDate () {
    this.endDateTarget.setAttribute('disabled', 'disabled')
    this.endDateTarget.value = ''
  }

  enableEndDate () {
    this.endDateTarget.removeAttribute('disabled')
  }

  toggleEndDateVisibilty () {
    if (this.endDateTarget.hasAttribute('disabled')) {
      this.enableEndDate()
    } else {
      this.disableEndDate()
    }
  }
}
