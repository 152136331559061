import { Controller } from '@hotwired/stimulus'

export default class FetchEditBlockModalOnEventController extends Controller {
  static values = {
    eventToRouteMapping: Object,
    initializeAfterEvent: String
  }

  connect () {
    if (this.hasInitializeAfterEventValue && this.initializeAfterEventValue) {
      window.addEventListener(this.initializeAfterEventValue, this.addFetchModalEventListeners.bind(this))
    } else {
      this.addFetchModalEventListeners()
    }
  }

  addFetchModalEventListeners () {
    Object.keys(this.eventToRouteMappingValue).forEach(key => {
      document.addEventListener(`message-to-event-handler:${key}`, this.fetchModal.bind(this))
    })
  }

  fetchModal () {
    const parsedEventData = event.type.split(':')
    const name = parsedEventData[1]
    this.modalContainer.src = this.eventToRouteMappingValue[name]
    setTimeout(() => { window.postMessage('polywork:message-to-event:switch-to-blocks-tab', '*') }, 500)
  }

  get modalContainer () {
    return document.querySelector('#modal_container')
  }
}
