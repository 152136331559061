import { Controller } from '@hotwired/stimulus'
import * as clamp from 'clamp-js'

export default class LineClampController extends Controller {
  static values = { maxLineCount: Number, accordion: Boolean, useNativeClamp: Boolean }

  connect () {
    this.applyClamp()

    if (this.accordionValue) {
      document.addEventListener('accordion:expanded', this.applyClamp.bind(this))
    }
  }

  applyClamp () {
    const clampParameters = {
      clamp: this.maxLineCountValue,
      useNativeClamp: this.useNativeClampValue
    }

    clamp(this.element, clampParameters)
  }
}
