import { Controller } from '@hotwired/stimulus'

export default class PostMessageOnConnectController extends Controller {
  static values = {
    message: String,
    messages: Array
  }

  connect () {
    if (this.hasMessagesValue) {
      this.messagesValue.forEach(message => window.postMessage(message, '*'))
    } else {
      window.postMessage(this.messageValue, '*')
    }
  }
}
