import { Controller } from '@hotwired/stimulus'
import * as Choices from 'choices.js'
import debounce from 'lodash/debounce'
import template from 'lodash/template'
import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import { get } from '@rails/request.js'

function choicesTemplate (el) {
  return template(
    el.querySelector('[data-choices-target="choicesTemplate"]').innerHTML
  )
}

function itemTemplate (el) {
  return template(
    el.querySelector('[data-choices-target="itemTemplate"]').innerHTML
  )
}

export default class ChoicesController extends Controller {
  static targets = [
    'select',
    'options',
    'addNewItemTemplate',
    'choicesTemplate',
    'itemTemplate',
    'container',
    'freeChoiceFieldIconTemplate',
    'freeChoiceResultIconTemplate',
    'clearButton',
    'suggestionButton'
  ]

  static values = {
    additionalClassNames: Object,
    dropdownAdditionalClassNames: String,
    displayParam: String,
    addNewItemPath: String,
    addNewItemParams: String,
    searchPath: String,
    initialSelection: String,
    defaultChoiceText: String,
    withinModal: Boolean,
    options: Object,
    initialChoices: Array,
    selectableDefaultChoice: Boolean,
    allowFreeTextEntry: Boolean,
    clearChoicesAfterSelection: Boolean,
    resetChoicesAfterSelection: Boolean,
    resetChoicesToInitialOnEmptyQuery: Boolean,
    instanceId: String,
    cssStyle: String,
    fallbackIcon: String,
    fallbackImgUrl: String
  }

  initialize () {
    this.element.choices = this
    this.refresh = this.refresh.bind(this)
    this.add = this.add.bind(this)
    this.highlightItem = this.highlightItem.bind(this)
    this.unHighlightItem = this.unHighlightItem.bind(this)
    this.remove = this.remove.bind(this)
    this.search = this.search.bind(this)
    this.update = this.update.bind(this)
    this.options = this.options.bind(this)
    this.templateCallbacks = this.templateCallbacks.bind(this)
    this.mapFetchResults = this.mapFetchResults.bind(this)
    this.searchPath = this.searchPathValue
    this.forceOption = this.element.dataset.forceOption || true
    this.placeholderText = undefined

    if (this.hasAddNewItemTemplateTarget) {
      this.addNewItemTemplate = template(this.addNewItemTemplateTarget.innerHTML)
    }
  }

  connect () {
    this.setup()
    this.configureChoiceUpdater()
  }

  configureChoiceUpdater () {
    this.addUpdateChoicesListener()
    this.createChoicesContainerForResults()
  }

  addUpdateChoicesListener () {
    this.listener = document.addEventListener(`choices.updateChoices.${this.instanceIdValue}`, (event) => {
      this.update(event.detail.results)
    })
  }

  highlightItem () {
    this.resetMultipleSelectedItemsOffset()
  }

  unHighlightItem () {
    this.resetMultipleSelectedItemsOffset()
  }

  removeUpdateChoicesListener () {
    if (this.listener) {
      document.removeEventListener(`choices.updateChoices.${this.instanceIdValue}`, this.listener)
    }
  }

  createChoicesContainerForResults () {
    const resultsContainer = document.createElement('turbo-frame')
    resultsContainer.setAttribute('id', `choices_container_${this.instanceIdValue}_results`)
    this.containerTarget.prepend(resultsContainer)
  }

  addSuggestion (event) {
    const { currentTarget } = event
    const data = JSON.parse(currentTarget.getAttribute('data-value'))
    this.choices.setValue([this.mapFetchResults(data)])
  }

  getSuggestedButtonWithValue (value) {
    return this.suggestionButtonTargets.find(buttonTarget => {
      const data = this.mapFetchResults(JSON.parse(buttonTarget.getAttribute('data-value')))
      return data.value === value
    })
  }

  hasSuggestedButtonWithValue (value) {
    return !!this.getSuggestedButtonWithValue(value)
  }

  showSuggestionButtonWithValue (value) {
    this.getSuggestedButtonWithValue(value).classList.add('d-flex')
    this.getSuggestedButtonWithValue(value).classList.remove('d-none')
  }

  hideSuggestionButtonWithValue (value) {
    this.getSuggestedButtonWithValue(value).classList.remove('d-flex')
    this.getSuggestedButtonWithValue(value).classList.add('d-none')
  }

  get initialSelection () {
    const initialSelection = this.initialSelectionValue || null
    return JSON.parse(unescape(initialSelection))
  }

  get defaultChoice () {
    return {
      id: '0',
      [this.displayParamValue]: this.defaultChoiceTextValue
    }
  }

  get initialChoices () {
    const initialData = this.initialChoicesValue || []

    if (this.selectableDefaultChoiceValue && this.defaultChoiceTextValue) {
      initialData.unshift(this.defaultChoice)
    }

    return initialData.map(this.mapFetchResults)
  }

  setup () {
    this.choices = new Choices(this.selectTarget, this.options())
    this.input = this.containerTarget.querySelector('input')
    this.selectTarget.addEventListener('change', this.refresh)
    this.selectTarget.addEventListener('addItem', this.add)
    this.selectTarget.addEventListener('highlightItem', this.highlightItem)
    this.selectTarget.addEventListener('unHighlightItem', this.unHighlightItem)
    this.selectTarget.addEventListener('removeItem', this.remove)

    if (this.searchPath) this.input.addEventListener('input', this.search)
    if (this.withinModalValue) {
      this.selectTarget.addEventListener(
        'showDropdown',
        this.setDropdownVisibility.bind(this)
      )
      this.selectTarget.addEventListener(
        'hideDropdown',
        this.removeDropdownVisibility
      )
    }

    this.selectTarget.addEventListener(
      'showDropdown',
      this.sendOpenedDropdownEvent.bind(this)
    )
    this.selectTarget.addEventListener(
      'hideDropdown',
      this.sendClosedDropdownEvent.bind(this)
    )

    this.setInitialValue()

    this.choices.setChoices(this.initialChoices, 'value', 'label', true)

    this.refresh(false)

    this.resetMultipleSelectedItemsOffset()

    // this has to be added separately from additionalClassNames due to a bug in choices
    // https://github.com/Choices-js/Choices/issues/1121
    if (this.dropdownAdditionalClassNamesValue) {
      const dropdownElement = this.element.querySelector('.choices__list--dropdown')
      this.dropdownAdditionalClassNamesValue.split(' ').map((dropdownClass) => dropdownElement?.classList?.add(dropdownClass))
    }
  }

  setInitialValue () {
    if (this.hasInitialSelectionValue) {
      this.showClearButton()
    }
    if (this.initialSelection) {
      if (isString(this.initialSelection)) {
        const mappedInitialSelection = this.mapFetchResults(
          this.initialSelection
        )
        if (this.initialChoices.length > 0) {
          this.choices.setChoiceByValue(mappedInitialSelection.value)
        } else {
          this.choices.setValue([mappedInitialSelection])
        }
      } else if (isArray(this.initialSelection)) {
        const mappedInitialSelection = this.initialSelection.map(
          this.mapFetchResults
        )
        this.choices.setValue(mappedInitialSelection)
      } else if (isObject(this.initialSelection)) {
        const mappedInitialSelection = this.mapFetchResults(
          this.initialSelection
        )
        this.choices.setValue([mappedInitialSelection])
      }
    }
  }

  resetMultipleSelectedItemsOffset () {
    if (this.selectTarget.multiple === true) {
      // we have to use a combo of inline (doesn't allow top/bottom margin/padding) and inline-block
      // (does allow t/p m/p), in order to get the input (not in the --multiple list) to be in the same row as the
      // --multiple list. This then necessitates using JS to detect the top row of the added badges, in order to
      // inject margin top offsets into them.
      const els = this.element.querySelectorAll(
        '.choices__list--multiple > .choices__item'
      )
      els.forEach(el => {
        if (el.offsetTop === 0) {
          el.classList.add('mt-2')
        }
      })
    }
  }

  disconnect () {
    if (this.searchPath) this.input.removeEventListener('input', this.search)
    this.selectTarget.removeEventListener('change', this.refresh)
    this.selectTarget.removeEventListener('addItem', this.add)
    this.selectTarget.removeEventListener('removeItem', this.remove)
    try {
      this.choices.destroy()
    } catch (_) {
      // continue regardless of error
    }
    this.choices = undefined
    this.removeUpdateChoicesListener()
  }

  sendOpenedDropdownEvent () {
    const event = new CustomEvent(
      `openedDropdown.${this.instanceIdValue}`,
      {
        bubbles: true,
        cancelable: true,
        detail: {
          id: this.instanceIdValue
        }
      }
    )
    document.dispatchEvent(event)
    this.resetMultipleSelectedItemsOffset()
  }

  setDropdownVisibility () {
    const pageWidth = document.documentElement.clientWidth
    const modalWidth = document.querySelector('.modal-dialog').clientWidth

    document.querySelectorAll('.modal-body').forEach(el => {
      el.style.overflowY = 'visible'
    })

    document.querySelectorAll('.modal-content').forEach(el => {
      el.style.overflow = 'revert'
      if (pageWidth > modalWidth) {
        el.style.maxHeight = 'calc(100vh - 5rem)'
      }
    })
    this.resetMultipleSelectedItemsOffset()
  }

  sendClosedDropdownEvent () {
    const event = new CustomEvent(
      `closedDropdown.${this.instanceIdValue}`,
      {
        bubbles: true,
        cancelable: true,
        detail: {
          id: this.instanceIdValue
        }
      }
    )
    document.dispatchEvent(event)
    this.resetMultipleSelectedItemsOffset()
  }

  removeDropdownVisibility () {
    document.querySelectorAll('.modal-body').forEach(el => {
      el.style.removeProperty('overflow-Y')
    })

    document.querySelectorAll('.modal-content').forEach(el => {
      el.style.removeProperty('overflow')
      el.style.removeProperty('max-height')
    })
  }

  refresh (event) {
    if (event && event.detail && event.detail.value && event.detail.value !== '0') {
      this.showClearButton()
    } else {
      if (event !== false) this.hideClearButton()
    }

    if (this.choices) {
      if (this.clearChoicesAfterSelectionValue || this.resetChoicesAfterSelectionValue) {
        try {
          this.choices.setChoices([], 'value', 'label', true)
        } catch (_) {
          // continue regardless of error
        }
      }

      if (this.resetChoicesAfterSelectionValue || this.resetChoicesToInitialOnEmptyQueryValue) {
        this.choices.setChoices(this.initialChoices, 'value', 'label', true)
      }
    }
  }

  add (event) {
    if (this.hasSuggestedButtonWithValue(event.detail.value)) {
      this.hideSuggestionButtonWithValue(event.detail.value)
    }
    if (this.hasOptionsTarget) {
      const option = [...this.optionsTarget.children].find(option => {
        return option.label === event.detail.label
      })
      if (option) {
        option.setAttribute('selected', '')
      } else {
        const newOption = document.createElement('option')
        newOption.setAttribute('label', event.detail.label)
        newOption.setAttribute('value', event.detail.value)
        newOption.setAttribute('selected', '')
        this.optionsTarget.appendChild(newOption)
      }
    }
    if (this.selectTarget.multiple) {
      // remove placeholder if any items chosen
      if (this.choices.itemList.element.children.length > 0) {
        const inputElement = this.choices.input.element

        if (this.placeholderText === undefined) {
          this.placeholderText = inputElement.placeholder
          inputElement.placeholder = ''
        }
        // squish inputElement size down so it can be on same line as the pills
        inputElement.style.minWidth = '1ch'
        inputElement.style.width = '1ch'

        // fix inputElement top padding so text is centered on pills if it's not in the top row
        if (inputElement.offsetTop === 0) {
          inputElement.classList.remove('multi-select-input-not-in-top-row')
        } else {
          inputElement.classList.add('multi-select-input-not-in-top-row')
        }
      }
    }
    this.resetMultipleSelectedItemsOffset()
  }

  remove (event) {
    if (this.hasSuggestedButtonWithValue(event.detail.value)) {
      this.showSuggestionButtonWithValue(event.detail.value)
    }
    if (this.hasOptionsTarget) {
      const option = [...this.optionsTarget.children].find(item => {
        return item.label === event.detail.label
      })
      if (option) { this.searchPath ? option.remove() : option.removeAttribute('selected') }
    }
    if (this.forceOption && !this.selectTarget.options.length) { this.selectTarget.add(document.createElement('option')) }

    const inputElement = this.choices.input.element
    // reset everything if there are no current choices
    if (this.choices.itemList.element.children.length === 0) {
      // if we've stored the original placeholder text when we added an element
      if (this.placeholderText !== undefined) {
        inputElement.placeholder = this.placeholderText
        this.placeholderText = undefined
        inputElement.style.minWidth = '41ch'
        inputElement.style.width = '1ch'
        inputElement.style.paddingTop = null
      }
    }

    // fix inputElement top padding so text is centered on pills if it's not in the top row
    if (inputElement.offsetTop === 0) {
      inputElement.classList.remove('multi-select-input-not-in-top-row')
    } else {
      inputElement.classList.add('multi-select-input-not-in-top-row')
    }

    this.resetMultipleSelectedItemsOffset()
  }

  showClearButton () {
    if (this.hasClearButtonTarget) {
      window.requestAnimationFrame(() => {
        this.clearButtonTarget.classList.remove('d-none')
      })
    }
  }

  hideClearButton () {
    if (this.hasClearButtonTarget) {
      window.requestAnimationFrame(() => {
        this.clearButtonTarget.classList.add('d-none')
      })
    }
  }

  clearSelection (event) {
    event.preventDefault()
    event.stopPropagation()

    this.choices.removeActiveItems()

    const html = template(itemTemplate(this.element)({
      classNames: this.styleClassNames,
      data: {
        highlighted: false,
        label: this.optionsValue.searchPlaceholderValue
      }
    }))

    this.activeHighlightedElement.innerHTML = html()
  }

  search (event) {
    if (event.target.value) {
      this.remoteSearch(event)
    } else {
      this.refresh()
    }
  }

  _remoteSearch (event) {
    get(this.searchPath + encodeURIComponent(event.target.value) + `&choices_instance_id=${this.instanceIdValue}`, {
      responseKind: 'turbo-stream'
    })
  }

  remoteSearch = debounce(this._remoteSearch, 300)

  update (data) {
    if (this.choices) {
      const newChoices = data.map(this.mapFetchResults)

      if (this.allowFreeTextEntryValue) {
        newChoices.unshift(this.freeTextChoice)
      }

      this.choices.setChoices(newChoices, 'value', 'label', true)

      if (this.choices.input.value !== '') {
        if (data.length === 0) {
          this.insertNoResultsPrompt()
        }

        if (data.length >= 0 && this.addNewItemTemplate) {
          this.insertAddNewItemPrompt()
        }
      }
    }
  }

  get freeTextChoice () {
    return {
      label: this.choices.input.value,
      value: this.choices.input.value,
      customProperties: {
        icon: this.freeChoiceFieldIconTemplateTarget.innerHTML,
        result_icon: this.freeChoiceResultIconTemplateTarget.innerHTML,
        icon_color: 'gray',
        is_free_text_choice: true
      }
    }
  }

  insertAddNewItemPrompt () {
    const dropdownItem = this.choices._getTemplate(
      'notice',
      this.addNewItemChoice()
    )
    this.choices.choiceList.element.prepend(dropdownItem)
  }

  addNewItemChoice () {
    const resultsData = {
      path: this.addNewItemPathValue,
      params: this.addNewItemParamsValue,
      input: this.input.value,
      query: encodeURIComponent(this.input.value)
    }
    return this.addNewItemTemplate(resultsData)
  }

  insertNoResultsPrompt () {
    const dropdownItem = this.choices._getTemplate(
      'notice',
      this.choices.config.noResultsText,
      'no-results'
    )
    this.choices.choiceList.element.append(dropdownItem)
  }

  mapFetchResults (result) {
    return {
      value: result.id,
      label: result[this.displayParamValue],
      customProperties: {
        avatar: result.avatar,
        icon: result.field_icon,
        result_icon: result.result_icon,
        icon_color: result.icon_color,
        fallback_icon: this.fallbackIconValue,
        fallback_img_url: this.fallbackImgUrlValue
      }
    }
  }

  options () {
    const defaultOptions = this.defaultOptions

    defaultOptions.classNames = this.styleClassNames

    if (this.styleClassNames && this.additionalClassNamesValue) {
      Object.keys(this.styleClassNames).map((classNameOption) => (
        defaultOptions.classNames[classNameOption] = `${this.styleClassNames[classNameOption]} ${(this.additionalClassNamesValue[classNameOption] || '')}`.trim()
      ))
    }

    defaultOptions.classNames = {
      ...this.additionalClassNamesValue,
      ...defaultOptions.classNames
    }

    if (this.hasItemTemplateTarget && this.hasChoicesTemplateTarget) {
      defaultOptions.callbackOnCreateTemplates = this.templateCallbacks
    }

    const updatedOptions = Object.assign(defaultOptions, this.optionsValue)

    return updatedOptions
  }

  get styleClassNames () {
    if (this.cssStyleValue === 'pill') {
      return this.pillStyleCssClasses
    } else if (this.cssStyleValue === 'searchPill') {
      return this.searchPillStyleCssClasses
    } else if (this.cssStyleValue === 'rightAligned') {
      return this.rightAlignedStyleCssClasses
    } else if (this.cssStyleValue === 'text') {
      return this.textStyleCssClasses
    }
  }

  get activeHighlightedElement () {
    return this.element.querySelector('.choices__list.choices__list--single')
  }

  get pillStyleCssClasses () {
    return {
      containerOuter: `choices ${this.initialSelection ? 'choices--has-state' : ''}`,
      containerInner: `pill-filter ${this.initialSelection ? 'active' : ''}`
    }
  }

  get searchPillStyleCssClasses () {
    return {
      containerOuter: 'choices search-pill',
      containerInner: 'pill-filter',
      listDropdown: 'search-pill-dropdown'
    }
  }

  get rightAlignedStyleCssClasses () {
    return {
      listDropdown: 'choices__list--right-aligned-dropdown'
    }
  }

  get textStyleCssClasses () {
    return {
      containerOuter: 'choices text-style'
    }
  }

  templateCallbacks (template) {
    return {
      item: (classNames, data) => {
        return template(itemTemplate(this.element)({ classNames, data }))
      },
      choice: (classNames, data) => {
        return template(choicesTemplate(this.element)({ classNames, data }))
      }
    }
  }

  get defaultOptions () {
    return {
      addItems: true,
      choices: [],
      duplicateItemsAllowed: false,
      items: [],
      itemSelectText: '',
      noChoicesText: '',
      paste: true,
      placeholder: false,
      placeholderValue: null,
      position: 'below',
      removeItemButton: false,
      removeItems: true,
      resetScrollPosition: false,
      searchEnabled: true,
      searchChoices: false,
      searchPlaceholderValue: 'Search....',
      searchResultLimit: 1000,
      shouldSort: false,
      silent: true
    }
  }
}
