import { Controller } from '@hotwired/stimulus'

export default class LinkedTabsController extends Controller {
  static targets = ['parent', 'linkedTab']

  connect () {
    $(this.parentTarget).on(
      'hidden.bs.tab',
      this.hideLinkedTabs.bind(this)
    )

    $(this.parentTarget).on(
      'shown.bs.tab',
      this.showLinkedTabs.bind(this)
    )
  }

  showLinkedTabs () {
    this.linkedTabTargets.forEach(tabTarget => {
      // eslint-disable-next-line no-param-reassign
      tabTarget.classList.remove('d-none')
    })
  }

  hideLinkedTabs () {
    this.linkedTabTargets.forEach(tabTarget => {
      // eslint-disable-next-line no-param-reassign
      tabTarget.classList.add('d-none')
    })
  }
}
