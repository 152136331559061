import { Controller } from '@hotwired/stimulus'

export default class BoostController extends Controller {
  static targets = ['count', 'countContainer', 'additionalText', 'boostText']
  static classes = ['boosted', 'unboosted']
  static values = {
    boostedTitle: String,
    unboostedTitle: String,
    boostedAdditionalTextTitle: String,
    unboostedAdditionalTextTitle: String,
    showTooltip: Boolean,
    hideZeroValues: Boolean
  }

  get isBoosted () {
    return this.element.classList.contains(this.boostedClass)
  }

  get currentBoostCount () {
    return parseInt(this.countTarget.textContent, 10)
  }

  toggleBoostedState () {
    const boosted = this.isBoosted

    if (boosted) {
      this.element.classList.remove(this.boostedClass)
      this.element.classList.add(this.unboostedClass)
      if (this.hasCountTarget) this.decrementCount()
    } else {
      this.element.classList.remove(this.unboostedClass)
      this.element.classList.add(this.boostedClass)
      if (this.hasCountTarget) this.incrementCount()
    }

    if (this.hasBoostTextTarget) this.updateBoostText()
    if (this.showTooltipValue) this.setTooltipTitle()
    if (this.hasAdditionalTextTarget) this.updateAdditionalText()
  }

  incrementCount () {
    this.countTarget.textContent = this.currentBoostCount + 1

    if (this.hideZeroValuesValue && this.hasCountContainerTarget) {
      this.showCountContainer()
    }
  }

  decrementCount () {
    this.countTarget.textContent = this.currentBoostCount - 1

    if (
      this.hideZeroValuesValue &&
      this.hasCountContainerTarget &&
      this.currentBoostCount === 0
    ) {
      this.hideCountContainer()
    }
  }

  updateBoostText () {
    const newText = this.isBoosted
      ? this.boostedTitleValue
      : this.unboostedTitleValue

    this.boostTextTarget.textContent = newText
  }

  setTooltipTitle () {
    const newTitle = this.isBoosted
      ? this.boostedTitleValue
      : this.unboostedTitleValue

    $(this.element)
      .attr('title', newTitle)
      .attr('data-original-title', newTitle)
      .tooltip('update')
      .tooltip('show')
  }

  updateAdditionalText () {
    const newText = this.isBoosted
      ? this.boostedAdditionalTextTitleValue
      : this.unboostedAdditionalTextTitleValue

    this.additionalTextTarget.textContent = newText
  }

  showCountContainer () {
    this.countContainerTarget.classList.remove('d-none')
    this.countContainerTarget.classList.add('d-contents')
  }

  hideCountContainer () {
    this.countContainerTarget.classList.remove('d-contents')
    this.countContainerTarget.classList.add('d-none')
  }
}
