import { Controller } from '@hotwired/stimulus'

const POSITIONS_KEY = 'polywork_new_employment_position_form_values'
export default class EmploymentPositionController extends Controller {
  static targets = ['endDate', 'startDate', 'title']

  static values = {
    restoreForm: Boolean
  }

  connect () {
    if (this.restoreFormValue) {
      this.setValuesFromLocalStorage()
    }
    window.localStorage.removeItem(POSITIONS_KEY)
  }

  setValuesFromLocalStorage () {
    const existingFormValues = JSON.parse(window.localStorage.getItem(POSITIONS_KEY)) || {}

    if (Object.keys(existingFormValues).length === 0) return

    this.titleTarget.value = existingFormValues.title
    this.startDateTarget.value = existingFormValues.startDate
    this.endDateTarget.value = existingFormValues.endDate
  }

  saveParamsToLocalStorage (e) {
    const formValues = {
      title: this.titleTarget.value,
      startDate: this.startDateTarget.value,
      endDate: this.endDateTarget.value
    }

    window.localStorage.setItem(POSITIONS_KEY, JSON.stringify(formValues))
  }
}
