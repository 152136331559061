import { Controller } from '@hotwired/stimulus'

export default class DropdownInFormFooterSpacerController extends Controller {
  static targets = ['largeSpacer', 'smallSpacer']

  static values = {
    largeSpacerDropdownIds: Array,
    smallSpacerDropdownIds: Array
  }

  connect () {
    this.largeSpacerDropdownIdsValue.forEach(id => {
      document.addEventListener(
        `openedDropdown.${id}`,
        this.showLargeSpacer.bind(this)
      )

      document.addEventListener(
        `closedDropdown.${id}`,
        this.hideLargeSpacer.bind(this)
      )
    })

    this.smallSpacerDropdownIdsValue.forEach(id => {
      document.addEventListener(
        `openedDropdown.${id}`,
        this.showSmallSpacer.bind(this)
      )

      document.addEventListener(
        `closedDropdown.${id}`,
        this.hideSmallSpacer.bind(this)
      )
    })
  }

  showSmallSpacer () {
    if (this.smallSpacerTarget) {
      this.smallSpacerTarget.classList.remove('d-none')
    }
  }

  hideSmallSpacer () {
    if (this.smallSpacerTarget) {
      this.smallSpacerTarget.classList.add('d-none')
    }
  }

  showLargeSpacer () {
    if (this.largeSpacerTarget) {
      this.largeSpacerTarget.classList.remove('d-none')
    }
  }

  hideLargeSpacer () {
    if (this.largeSpacerTarget) {
      this.largeSpacerTarget.classList.add('d-none')
    }
  }
}
