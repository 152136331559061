import { Controller } from '@hotwired/stimulus'

export default class AddClassOnHoverController extends Controller {
  static targets = ['element']

  static classes = ['onHover']

  connect () {
    this.elementTargets.forEach(target => {
      target.addEventListener('mouseover', () => {
        target.classList.add(this.onHoverClass)
      })
    })
  }
}
