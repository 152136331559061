import { Controller } from '@hotwired/stimulus'

export default class AsyncFetchComponentController extends Controller {
  static targets = ['loadingBody', 'turboFrame']

  static values = {
    waitForEventToLoadNewContent: String,
    componentIdentifier: String
  }

  connect () {
    if (this.waitForEventToLoadNewContentValue) {
      this.element.addEventListener('turbo:before-fetch-response', () => {
        this.turboFrameTarget.classList.add('tw-hidden')
        this.turboFrameContentReady = true
        this.showTurboFrameContent()
      })

      document.addEventListener(this.waitForEventToLoadNewContentValue, () => {
        this.loadingBodyFinished = true
        this.showTurboFrameContent()
      })
    }
  }

  showTurboFrameContent () {
    if (this.turboFrameContentReady && this.loadingBodyFinished) {
      this.loadingBodyTarget.classList.add('tw-hidden')
      this.turboFrameTarget.classList.remove('tw-hidden')

      if (this.componentIdentifierValue) {
        window.postMessage(`polywork:message-to-event:${this.componentIdentifierValue}-loading-complete`, '*')
      }
    }
  }
}
