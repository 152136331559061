import { Controller } from '@hotwired/stimulus'

export default class AddClassOnScrollController extends Controller {
  static targets = ['element']

  static classes = ['onScroll']

  connect () {
    if (this.isScrollNeeded) {
      document.addEventListener('scroll', this.addScrollClass.bind(this))
    } else {
      this.addScrollClass()
    }
  }

  disconnect () {
    this.removeScrollListener()
  }

  addScrollClass () {
    this.elementTargets.forEach(target => {
      target.classList.add(this.onScrollClass)
    })
    this.removeScrollListener()
  }

  removeScrollListener () {
    document.removeEventListener('scroll', this.addScrollClass)
  }

  get isScrollNeeded () {
    const clientHeight = document.documentElement.clientHeight
    const scrollHeight = document.documentElement.scrollHeight
    const buffer = 40

    return scrollHeight > (clientHeight + buffer)
  }
}
