import { Controller } from '@hotwired/stimulus'

export default class RemoveClassAfterDelayController extends Controller {
  static classes = ['classToRemove']

  connect () {
    setTimeout(() => {
      this.element.classList.remove(this.classToRemoveClass)
    }, 1500)
  }
}
