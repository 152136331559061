import { Controller } from '@hotwired/stimulus'

import Quill from 'quill/core'
import Toolbar from 'quill/modules/toolbar'

import Bold from 'quill/formats/bold'
import Italic from 'quill/formats/italic'
import Link from 'quill/formats/link'
import Header from 'quill/formats/header'
import Blockquote from 'quill/formats/blockquote'
import Code from 'quill/formats/code'
import List, { ListItem } from 'quill/formats/list'

import Strike from './quill/custom_formats/strike'

export default class ContentEditorController extends Controller {
  static targets = ['toolbar', 'container', 'field', 'placeholder']

  static values = {
    placeholder: String,
    toolbarSelector: String
  }

  connect () {
    this.registerFormats()

    this.controllerName = 'content-editor'
    this.editor = new Quill(this.containerTarget, this.options())
    this.editor.root.dataset.test = 'content-editor'

    this.editor.on('text-change', ({ ops = {} }) => {
      this.updateField(ops)
      this.triggerChangeEvent(ops)
    })

    this.registerToolbar()
  }

  registerFormats () {
    return Quill.register({
      'modules/toolbar': Toolbar,
      'formats/bold': Bold,
      'formats/italic': Italic,
      'formats/strike': Strike,
      'formats/link': Link,
      'formats/header': Header,
      'formats/blockquote': Blockquote,
      'formats/code': Code,
      'formats/list': List,
      'formats/list-item': ListItem
    })
  }

  options () {
    const opts = { placeholder: this.placeholderValue }

    if (this.toolbarElement()) {
      opts.modules = {
        toolbar: {
          container: this.toolbarElement(),
          handlers: this.handlers()
        }
      }
    } else {
      opts.modules = { toolbar: false }
      opts.formats = []
    }

    return opts
  }

  handlers () {
    return {
      link: this.linkHandler.bind(this)
    }
  }

  toolbarElement () {
    if (this.hasToolbarTarget) {
      return this.toolbarTarget
    } else if (this.hasToolbarSelectorValue) {
      return document.querySelector(this.toolbarSelectorValue)
    }

    return false
  }

  linkHandler (active) {
    const range = this.editor.getSelection()

    // exit early if nothing's selected
    if (range == null || range.length === 0) {
      return
    }

    // otherwise handoff to the toolbar controller
    this.toolbarController.linkHandler(active, this.editor.getText(range))
  }

  updateField () {
    this.fieldTarget.value = this.editor.root.innerHTML
    this.editor.focus()
  }

  triggerChangeEvent () {
    const event = new Event('change', { text: this.editor.root.innerHTML })
    this.element.dispatchEvent(event)
  }

  focus () {
    this.editor.focus()
    this.editor.setSelection(this.contentLength, 0)
  }

  get contentLength () {
    return this.editor.getLength()
  }

  registerToolbar (event) {
    if (event) {
      // when triggered by a toolbar dispatch, verify it's the correct toolbar
      if (event.detail.toolbar !== this.toolbarElement()) {
        return
      }
    }

    this.toolbarController = this.application.getControllerForElementAndIdentifier(this.toolbarElement(), 'toolbar')
    if (this.toolbarController) {
      this.toolbarController.registerEditor(this)
    }
  }
}
