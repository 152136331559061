import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class FetchNewCuratedFeedContentController extends Controller {
  static values = {
    url: String,
    enabled: Boolean
  }

  connect () {
    if (this.enabledValue) {
      setTimeout(() => {
        this.fetchNew()
      }, 1000)

      this.fetchInterval = setInterval(
        this.fetchNew.bind(this),
        8000
      )
    }
  }

  disconnect () {
    if (this.fetchInterval) clearInterval(this.fetchInterval)
  }

  fetchNew () {
    if (this.hasMouseOverContainer) return

    const ids = this.findIds()

    get(this.urlValue, {
      query: { ids },
      responseKind: 'turbo-stream'
    })
  }

  findIds () {
    const ids = [];

    [...document.querySelectorAll('[data-unified-id]')].forEach(element => {
      ids.push(element.dataset.unifiedId)
    })

    return ids
  }

  get hasMouseOverContainer () {
    return this.element.querySelector(':hover') !== null
  }
}
