import ContentEditorController from './content_editor_controller'
import { DirectUpload } from '@rails/activestorage'

import Quill from 'quill/core'
import Toolbar from 'quill/modules/toolbar'

import Bold from 'quill/formats/bold'
import Italic from 'quill/formats/italic'
import Underline from 'quill/formats/underline'
import { ListItem } from 'quill/formats/list'

import UrlEmbed from './quill/custom_formats/url_embed'
import LoadingImagePlaceholder from './quill/custom_formats/loading_image_placeholder'
import Link from './quill/custom_formats/link'
import Image from './quill/custom_formats/image'
import List from './quill/custom_formats/list'
import Strike from './quill/custom_formats/strike'

import Clipboard from './quill/custom_formats/clipboard'
import ImageDrop from './quill/custom_formats/image_drop'

const FORMATS = {
  bold: Bold,
  italic: Italic,
  strike: Strike,
  underline: Underline,
  link: Link,
  listBullet: List,
  listNumber: List,
  image: Image
}
export const VALID_LINK_REGEX = /^(?:https?:\/\/)?[a-zA-Z0-9]+\.[^\s]{2,}$|^(?:(?!w{1,3})[a-zA-Z0-9])+\.[^\s]{2,}$|mailto:/
export default class DynamicContentEditorController extends ContentEditorController {
  static targets = ['toolbar', 'container', 'field', 'placeholder', 'linkTextField', 'linkUrlField']

  static values = {
    formats: Array,
    activeStoragePath: String,
    placeholder: String,
    toolbarSelector: String,
    isIos: Boolean,
    linkFormTitleAdd: String,
    linkFormTitleEdit: String
  }

  connect () {
    super.connect()

    // add images to input when dropped on window
    window.addEventListener('dragover', (event) => {
      event.preventDefault()
    }, false)

    window.addEventListener('drop', (event) => {
      event.preventDefault()
      new ImageDrop(this.editor).handleDrop(event)
    }, false)
  }

  registerFormats () {
    Quill.register('modules/toolbar', Toolbar)
    Quill.register('modules/clipboard', Clipboard)
    Quill.register('formats/list-item', ListItem)
    Quill.register(LoadingImagePlaceholder, true)
    Quill.register(UrlEmbed, true)
    this.formatsValue.map(format => Quill.register(`formats/${format}`, FORMATS[format]))
  }

  handlers () {
    return {
      link: this.linkHandler.bind(this),
      image: this.imageHandler.bind(this)
    }
  }

  options () {
    const opts = {
      placeholder: this.placeholderValue,
      modules: {
        toolbar: {
          container: this.toolbarElement(),
          handlers: this.handlers()
        }
      },
      dynamicContentEditorController: this,
      scrollingContainer: '.quill-scroll-container'
    }

    return opts
  }

  removeElement (element) {
    const quillElement = Quill.find(element)
    const index = this.editor.getIndex(quillElement)

    this.editor.deleteText(index, 1)
  }

  insertLink ({ range, text, link, insertUnfurled }) {
    this.deleteSelectedText(range)

    let textToAdd = text
    let linkUrl = String(link).trim()

    if (!link || link === '') {
      this.clearLinkDialog()
      return this.editor.insertText(range.index, textToAdd)
    }

    if (!textToAdd || textToAdd === '') {
      textToAdd = linkUrl
    }

    linkUrl = this.formatLink(linkUrl)

    this.editor.insertText(range.index, textToAdd, 'link', linkUrl)

    const textIsSelected = range.length > 0

    if (!textIsSelected || insertUnfurled) {
      this.insertUrlEmbed({ range, text: textToAdd, link: linkUrl })
    }

    this.clearLinkDialog()
  }

  formatLink (linkUrl) {
    let formattedLink = linkUrl

    if (!/^(https?:\/\/|mailto:)/.test(formattedLink)) {
      formattedLink = `https://${formattedLink}`
    }
    if (!/^(https?:\/\/www.|mailto:)/.test(formattedLink)) {
      formattedLink = formattedLink.replace('http://', 'https://')
    }
    if (/^http:\/\/www./.test(formattedLink)) {
      formattedLink = formattedLink.replace('http://', 'https://')
    }

    return formattedLink
  }

  insertUrlEmbed ({ range, text, link }) {
    const newIndex = range.index + text.length

    this.editor.insertEmbed(newIndex, 'urlEmbed', link)
    this.insertSpace(newIndex)
    this.editor.removeFormat(newIndex, 1)
  }

  insertNewLine (index) {
    this.editor.insertText(index + 1, '\n')
  }

  insertSpace (index) {
    this.editor.insertText(index, ' ')
  }

  linkHandler () {
    $('#link-form-title')[0].textContent = this.linkFormTitleAddValue

    const linkUrl = this.editor.getFormat()?.link
    const range = this.getRange()

    const linksController = this.linksController
    const linkTextFieldTarget = linksController.linkTextFieldTarget
    const linkUrlFieldTarget = linksController.linkUrlFieldTarget

    // cursor is over a link so we prepopulate form and highlight the link so that it will be replaced with new info
    if (linkUrl) {
      $('#link-form-title')[0].textContent = this.linkFormTitleEditValue

      const linkBlot = this.editor.getLeaf(range.index)[0]
      const linkText = linkBlot.text
      this.editor.setSelection(this.editor.getIndex(linkBlot), linkText.length)

      linkTextFieldTarget.value = linkText
      linkUrlFieldTarget.value = linkUrl
    } else if (range.length > 0) {
      linkTextFieldTarget.value = this.editor.getText(range)
    }

    ([linkTextFieldTarget, linkUrlFieldTarget]).map((input) => (
      input.addEventListener('keydown', event => {
        if (event.key === 'Enter') {
          event.preventDefault()
          event.stopPropagation()
          if (linksController.handleLinkInsertEvent(event)) {
            $('#close-link-form').click()
          }
        } else if (event.key === 'Escape') {
          event.preventDefault()
          event.stopPropagation()
          this.clearLinkDialog()
        }
      })
    ))
  }

  getRange () {
    const range = this.editor.getSelection()

    if (range) { return range }

    this.editor.focus()
    return this.editor.getSelection()
  }

  clearLinkDialog (event) {
    this.linksController.clearLinkDialog()
  }

  imageHandler () {
    const input = document.createElement('input')

    input.setAttribute('type', 'file')
    input.setAttribute('direct_upload', true)
    input.setAttribute('multiple', true)

    input.click()

    input.onchange = () => {
      input.files.forEach((file) => {
        this.saveToServerAndInsertInEditor(file)
      })
    }
  }

  insertBase64Image (imageBase64) {
    return fetch(imageBase64)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([
          new Blob([blob])
        ], Math.random().toString())

        return this.saveToServerAndInsertInEditor(file)
      })
  }

  saveToServerAndInsertInEditor (file) {
    const range = this.getRange()
    const index = range.index

    this.deleteSelectedText(range)
    this.insertLoadingIcon(index)
    this.editor.enable(false)

    this.disableSubmitButton()

    const uploadUrl = `${this.activeStoragePathValue}/direct_uploads`
    const upload = new DirectUpload(file, uploadUrl, this)

    upload.create((error, blob) => {
      // remove loading icon
      this.removeEmbed(index)
      this.editor.enable(true)

      if (!error) {
        const showUrl = `${this.activeStoragePathValue}/blobs/${blob.signed_id}/filename`
        this.insertImageToEditor(showUrl)
        this.enableSubmitButton()
      }
    })
  }

  insertLoadingIcon (index) {
    this.editor.insertEmbed(index, 'loadingImagePlaceholder', '')
    this.editor.setSelection(index + 1, 0)
  }

  removeEmbed (index) {
    this.editor.deleteText(index, 1)
  }

  insertImageToEditor (url) {
    const range = this.getRange()

    this.editor.insertEmbed(range.index, 'image', url)
    this.editor.setSelection(range.index + 1, 0)
    this.insertNewLine(range.index)
  }

  deleteSelectedText (range) {
    const textIsSelected = range.length > 0

    if (textIsSelected) {
      this.editor.deleteText(range.index, range.length)
    }
  }

  updateField (ops) {
    const insertEvent = ops[0]?.insert || ops[1]?.insert

    // check if link is being added
    if (insertEvent === '\n' || insertEvent === ' ') {
      const range = this.getRange()
      const isFormattedLink = this.editor.getFormat()?.link
      const currentBlot = this.editor.getLeaf(range.index)[0]
      const textBeingAdded = currentBlot.text

      if (textBeingAdded) {
        const words = textBeingAdded.trim().split(' ')
        const textToAdd = words[words.length - 1]
        const currentSelectionLength = insertEvent === ' ' ? textToAdd.length + 1 : textToAdd.length

        // if plain text link is entered, unfurl it
        if (!isFormattedLink && VALID_LINK_REGEX.test(textToAdd)) {
          this.editor.setSelection(range.index - currentSelectionLength, currentSelectionLength)
          this.insertLink({ range: this.getRange(), text: textToAdd, link: textToAdd, insertUnfurled: true })
        }
      }
    }

    super.updateField()
  }

  disableSubmitButton () {
    $('.identity-post-submit-button').attr('disabled', true)
    this.formSubmissionController.disableSubmitButton()
  }

  enableSubmitButton () {
    $('.identity-post-submit-button').attr('disabled', false)
    this.formSubmissionController.onFieldValueChange()
  }

  get linksController () {
    return this.application.controllers.find(controller => controller.controllerName === 'dynamic-content-editor-links')
  }

  get formSubmissionController () {
    return this.application.controllers.find(controller => controller.controllerName === 'form-submission')
  }
}
