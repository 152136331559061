import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { get } from '@rails/request.js'

/*
 * Usage
 * =====
 *
 * add data-controller="awaiting-completion" to your page
 *
 * Either provide a URL value or a link target to be clicked that navigates
 * to the next page.
 *
 * Navigation will occur when:
 * - The pollUrl API returns { ready: true }
 * - The timeout expires
 *
 */
export default class AwaitingCompletionController extends Controller {
  static targets = ['link']
  static values = {
    minumumDisplayTime: Number,
    timeout: Number,
    url: String,
    pollUrl: String
  }

  connect () {
    setTimeout(() => {
      this.pollInterval = setInterval(() => {
        this.checkReadyState()
      }, 1000)
    }, this.minumumDisplayTimeValue)

    this.timeout = setTimeout(() => {
      this.navigate()
    }, this.timeoutValue)
  }

  disconnect () {
    this.stopTimers()
  }

  async checkReadyState () {
    const resp = await get(this.pollUrlValue, {
      responseKind: 'json'
    })

    if (resp.ok) {
      const { ready } = await resp.json
      if (ready) {
        this.stopTimers()
        this.navigate()
      }
    }
  }

  navigate () {
    if (this.hasLinkTarget) {
      Rails.fire(this.linkTarget, 'click')
    } else {
      Turbo.visit(this.urlValue)
    }
  }

  stopTimers () {
    clearInterval(this.pollInterval)
    clearTimeout(this.timeout)
  }
}
