import { Controller } from '@hotwired/stimulus'

export default class DropdownMenuHorizontalScrollController extends Controller {
  static classes = ['parentContainer', 'dropdownMenu', 'menuOpen']
  static values = {
    xPadding: Number
  }

  connect () {
    $(this.element).on('show.bs.dropdown', () => {
      this.setMenuOpenClass()
    })

    $(this.element).on('hide.bs.dropdown', () => {
      this.removeMenuOpenClass()
    })

    $(this.element).on('shown.bs.dropdown', () => {
      this.scrollDropdownIntoView()
    })
  }

  scrollDropdownIntoView () {
    const parentScrollElement = this.element.closest(`.${this.parentContainerClass}`)
    const dropdownMenu = this.element.querySelector(`.${this.dropdownMenuClass}`)

    if (parentScrollElement && dropdownMenu) {
      const dropdownMenuXPosition = dropdownMenu.getBoundingClientRect().right
      const dropdownMenuWidth = dropdownMenu.getBoundingClientRect().width
      const currentScrollPosition = parentScrollElement.scrollLeft
      const scrollToX = currentScrollPosition + dropdownMenuXPosition - (dropdownMenuWidth + this.xPaddingValue)
      parentScrollElement.scrollTo(scrollToX, 0)
    }
  }

  setMenuOpenClass () {
    const parentScrollElement = this.element.closest(`.${this.parentContainerClass}`)
    parentScrollElement.classList.add(this.menuOpenClass)
  }

  removeMenuOpenClass () {
    const parentScrollElement = this.element.closest(`.${this.parentContainerClass}`)
    parentScrollElement.classList.remove(this.menuOpenClass)
  }
}
