import { Controller } from '@hotwired/stimulus'

export default class PreventFramesReloadingController extends Controller {
  static targets = ['frame']

  connect () {
    this.onBeforeCache = this.onBeforeCache.bind(this)

    document.addEventListener('turbo:before-cache', this.onBeforeCache, false)
  }

  disconnect () {
    document.removeEventListener('turbo:before-cache', this.onBeforeCache, false)
  }

  onBeforeCache (event) {
    this.frameTargets.forEach(frameTarget => {
      if ((frameTarget.children[0] && frameTarget.children[0].dataset.js === 'loading-spinner') || frameTarget.hasAttribute('busy')) {
        // Skip this frame as it hasn't loaded content yet
      } else {
        frameTarget.loading = null
        frameTarget.src = null
      }
    })
  }
}
