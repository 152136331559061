import { Controller } from '@hotwired/stimulus'

export default class VariableInputWidthController extends Controller {
  static values = {
    minWidth: Number
  }

  get valueWidthOrMinWidth () {
    return Math.max(this.minWidthValue, this.element.value.length * 0.80)
  }

  setInputWidth () {
    this.element.style.width = `${this.valueWidthOrMinWidth}ch`
  }
}
