import { Controller } from '@hotwired/stimulus'

export default class TimezoneCookieController extends Controller {
  connect () {
    this.setTimezoneCookie()
  }

  // eslint-disable-next-line class-methods-use-this
  setTimezoneCookie () {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    if (navigator.cookieEnabled) {
      document.cookie = `tzo=${timezone};`
    }
  }
}
