import { Controller } from '@hotwired/stimulus'
import consumer from 'channels/consumer'

// Comprehensive documentation is available in `app/channels/client_events_channel.rb`.
//
export default class ClientEventsController extends Controller {
  connect () {
    this.clientEventsChannel = consumer.subscriptions.create({
      channel: 'ClientEventsChannel'
    }, {
      received: this.received.bind(this),
      connected: this.connected.bind(this)
    }
    )

    this.facebookConversionIds = {
      registration_flow_started: 'registrationFlowStarted',
      registration_flow_complete: 'registrationFlowComplete',
      subscription_upgraded_to_premium: 'subscriptionUpgradedToPremium',
      domain_purchased: 'domainPurchased'
    }

    this.linkedInConversionIds = {
      registration_flow_started: 8060618,
      registration_flow_complete: 9019642
    }

    this.twitterConversionIds = {
      registration_flow_started: 'o9jxc',
      registration_flow_complete: 'o9kd1'
    }

    this.googleAdsConversionIds = {
      registration_flow_started: 'AW-10865685512/k8WdCNuur4QYEIjolL0o',
      registration_flow_complete: 'AW-10865685512/6WMzCIyzr4QYEIjolL0o',
      subscription_upgraded_to_premium: 'AW-10865685512/mUuVCJyGzuAYEIjolL0o',
      domain_purchased: 'AW-10865685512/717HCJ-GzuAYEIjolL0o'
    }
  }

  disconnect () {
    consumer.subscriptions.forget(this.clientEventsChannel)
  }

  connected (data) {
    this.clientEventsChannel.send({
      message: 'refresh'
    })
  }

  received (data) {
    console.log('[ClientEventsController] received', data)

    if (data.message === 'new') {
      this.clientEventsChannel.send({
        message: 'refresh'
      })
    } else if (data.message === 'event') {
      this.clientEventsChannel.send({
        message: 'claim',
        payload: data.payload
      })
    } else if (data.message === 'trigger') {
      this.trigger(data.payload)
    }
  }

  trigger (event) {
    console.log('[ClientEventsController] trigger', event)

    this.triggerFacebook(event)
    this.triggerGoogle(event)
    this.triggerGoogleAds(event)
    this.triggerLinkedIn(event)
    this.triggerTwitter(event)
  }

  triggerFacebook (event) {
    const conversionId = this.facebookConversionIds[event.name]

    if (typeof conversionId !== 'undefined') {
      if (typeof window.fbq !== 'undefined') {
        console.log('[ClientEventsController] fbq', conversionId)

        window.fbq('trackCustom', conversionId, {})
      } else {
        console.log('[ClientEventsController] fbq is undefined, but would have tracked', conversionId)
      }
    }
  }

  triggerGoogle (event) {
    if (typeof window.gtag !== 'undefined') {
      console.log('[ClientEventsController] gtag', event.name, event.metadata)

      window.gtag('event', event.name, event.metadata)
    } else {
      console.log('[ClientEventsController] gtag is undefined, but would have tracked', event.name, event.metadata)
    }
  }

  triggerGoogleAds (event) {
    const conversionId = this.googleAdsConversionIds[event.name]

    if (typeof conversionId !== 'undefined') {
      if (typeof window.gtag !== 'undefined') {
        console.log('[ClientEventsController] gtag (ads)', conversionId)

        window.gtag('event', 'conversion', { send_to: conversionId })
      } else {
        console.log('[ClientEventsController] gtag (ads) is undefined, but would have tracked', conversionId)
      }
    }
  }

  triggerLinkedIn (event) {
    const conversionId = this.linkedInConversionIds[event.name]

    if (typeof conversionId !== 'undefined') {
      if (typeof window.lintrk !== 'undefined') {
        console.log('[ClientEventsController] lintrk', conversionId)

        window.lintrk('track', { conversion_id: conversionId })
      } else {
        console.log('[ClientEventsController] lintrk is undefined, but would have tracked', conversionId)
      }
    }
  }

  triggerTwitter (event) {
    const conversionId = this.twitterConversionIds[event.name]

    if (typeof conversionId !== 'undefined') {
      if (typeof window.twttr !== 'undefined') {
        console.log('[ClientEventsController] twttr', conversionId)

        window.twttr.conversion.trackPid(conversionId, { tw_sale_amount: 0, tw_order_quantity: 0 })
      } else {
        console.log('[ClientEventsController] twttr is undefined, but would have tracked', conversionId)
      }
    }
  }
}
