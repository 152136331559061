import { Controller } from '@hotwired/stimulus'

export default class ModalController extends Controller {
  // eslint-disable-next-line class-methods-use-this
  connect () {
    this.removeModalBackdrops()
    this.showModal()
    this.onBeforeVisitModalVisible = this.onBeforeVisitModalVisible.bind(this)
    document.addEventListener('turbo:before-visit', this.onBeforeVisitModalVisible, false)

    $(this.element).on(
      'hide.bs.modal',
      this.sendTurboCloseModalRequest.bind(this)
    )
  }

  // eslint-disable-next-line class-methods-use-this
  disconnect () {
    document.removeEventListener('turbo:before-visit', this.onBeforeVisitModalVisible, false)
    this.removeModalBackdrops()
    $(this.element).off('hide.bs.modal')
  }

  // eslint-disable-next-line class-methods-use-this
  showModal () {
    $(this.element).modal('show')
    document.querySelectorAll('.modal-backdrop').forEach(el => {
      el.setAttribute('data-turbo-cache', false)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  hideModal () {
    $(this.element).modal('hide')
  }

  // eslint-disable-next-line class-methods-use-this
  removeModalBackdrops () {
    document.querySelectorAll('.modal-backdrop').forEach(el => {
      el.remove()
    })

    const bodyElement = document.querySelector('body')
    bodyElement.classList.remove('modal-open')
  }

  // eslint-disable-next-line class-methods-use-this
  sendTurboCloseModalRequest () {
    document.getElementById('modal_container').src = '/modals/close'
  }

  onBeforeVisitModalVisible (event) {
    this.hideModal()
  }
}
