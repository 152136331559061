import { Controller } from '@hotwired/stimulus'

export default class HorizontalDragAndScrollController extends Controller {
  connect () {
    this.mouseDown = false
    this.startX = null
    this.scrollLeft = null

    window.addEventListener('wheel', this.scroll.bind(this))
    this.element.addEventListener('mousemove', this.move.bind(this))
    this.element.addEventListener('mousedown', this.startDragging.bind(this))
    this.element.addEventListener('mouseup', this.stopDragging.bind(this))
    this.element.addEventListener('mouseleave', this.stopDragging.bind(this))
  }

  scroll (event) {
    if (event.deltaY > 0) {
      this.element.scrollLeft += 100
    } else {
      this.element.scrollLeft -= 100
    }
  }

  move (event) {
    event.preventDefault()
    event.stopPropagation()
    if (!this.mouseDown) { return }
    const x = event.pageX - this.element.offsetLeft
    const scroll = x - this.startX
    this.element.scrollLeft = this.scrollLeft - scroll
  }

  startDragging (event) {
    this.mouseDown = true
    this.startX = event.pageX - this.element.offsetLeft
    this.scrollLeft = this.element.scrollLeft
  }

  stopDragging (event) {
    event.preventDefault()
    event.stopPropagation()
    this.mouseDown = false
  }
}
