import { Controller } from '@hotwired/stimulus'

export default class CarouselLoadedController extends Controller {
  connect () {
    const event = new CustomEvent('carousel:loaded', {
      bubbles: true,
      cancelable: true
    })

    this.element.dispatchEvent(event)
  }
}
