import { Controller } from '@hotwired/stimulus'

export default class CheckboxSelectAllController extends Controller {
  static targets = ['checkbox']

  selectAllCheckboxes () {
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = true
    })
  }
}
