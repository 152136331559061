import { Controller } from '@hotwired/stimulus'

export default class WaitForCombinedEditorActionController extends Controller {
  initialize () {
    this.boundAwaitCompletion = this.awaitCompletion.bind(this)
  }

  connect () {
    document.addEventListener('turbo:before-fetch-request', this.boundAwaitCompletion)
  }

  disconnect () {
    document.removeEventListener('turbo:before-fetch-request', this.boundAwaitCompletion)
  }

  get actionHasCompleted () {
    return this.globalButton.disabled === true
  }

  get globalButton () {
    return document.querySelector('#global-save-changes-button')
  }

  onDisabledSaveButton (event) {
    document.removeEventListener('message-to-event-handler:disable-save-changes-button', this.onDisabledSaveButton)
    event.detail.resume()
  }

  awaitCompletion (event) {
    const url = this.element.getAttribute('href')

    if (url !== event.detail.url) return

    event.preventDefault()
    event.stopImmediatePropagation()

    document.addEventListener(
      'message-to-event-handler:disable-save-changes-button',
      () => { this.onDisabledSaveButton(event) }
    )
  }
}
