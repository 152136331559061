import { Controller } from '@hotwired/stimulus'

export default class RemoveLoadingSpinnerController extends Controller {
  static values = {
    spinnerId: String
  }

  connect () {
    if (this.loadingSpinner) this.loadingSpinner.classList.add('d-none')
  }

  get loadingSpinner () {
    return document.getElementById(this.spinnerIdValue)
  }
}
