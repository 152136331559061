import { Controller } from '@hotwired/stimulus'

export default class ModalFullHeightController extends Controller {
  connect () {
    this.setModalHeight()
  }

  disconnect () {
    this.removeModalHeight()
  }

  // eslint-disable-next-line class-methods-use-this
  removeModalHeight () {
    document
      .querySelectorAll('.modal-content')
      .forEach(modalContent => modalContent.classList.remove('flex-grow-1'))
  }

  // eslint-disable-next-line class-methods-use-this
  setModalHeight () {
    document
      .querySelectorAll('.modal-content')
      .forEach(modalContent => modalContent.classList.add('flex-grow-1'))
  }
}
