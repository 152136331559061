import { Controller } from '@hotwired/stimulus'

const SPOTLIGHT_CLASS = 'spotlight'

export default class SpotlightSectionInProfilePreviewController extends Controller {
  spotlight (e) {
    let performedScroll = false

    this.correspondingSections(e).forEach((section, index) => {
      section.classList.add(SPOTLIGHT_CLASS)

      if (performedScroll || this.skipScrollForSection(section)) { return }

      const scrollYValue = this.scrollYValueForSection(section)
      this.profilePreviewiframeElement.contentWindow.scrollTo({ top: scrollYValue, behavior: 'smooth' })
      performedScroll = true
    })
  }

  scrollYValueForSection (section) {
    const rect = section.getBoundingClientRect()
    const elementHeight = rect.height
    const offsetTop = rect.top + this.profilePreviewiframeElement.contentWindow.scrollY
    const viewportBuffer = 16
    const viewportHeight = this.profilePreviewiframeElement.contentWindow.innerHeight - viewportBuffer
    const centeringOffset = Math.max((viewportHeight - elementHeight) * 0.5, 0)

    let scrollYValue
    if (offsetTop === 0 || offsetTop < viewportHeight) {
      scrollYValue = 0
    } else {
      scrollYValue = offsetTop - centeringOffset
    }
    return scrollYValue
  }

  skipScrollForSection (section) {
    const skipScroll = section.getAttribute('data-skip-section-spotlight-scroll')
    return (skipScroll !== 'undefined' && skipScroll)
  }

  removeSpotlight (e) {
    this.correspondingSections(e).forEach(section => section.classList.remove(SPOTLIGHT_CLASS))
  }

  sectionIdentifier (e) {
    return e.target.getAttribute('data-section-identifier')
  }

  get profilePreviewiframeElement () {
    return window.parent.document.getElementById('identity-template-profile-preview-container')
  }

  correspondingSections (e) {
    return this.profilePreviewiframeElement.contentWindow.document.getElementsByClassName(`identity-profile-${this.sectionIdentifier(e)}-section`)
  }
}
