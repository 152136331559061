import { Controller } from '@hotwired/stimulus'

export default class TerminalAsciiImageController extends Controller {
  static classes = ['hidden']

  connect () {
    this.setFontSizeInterval = setInterval(
      this.setFontSize.bind(this),
      100
    )
  }

  setFontSize () {
    if (!this.canResizeFont) return

    if (this.setFontSizeInterval) clearInterval(this.setFontSizeInterval)

    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.element.setAttribute('style', `font-size: ${this.calculatedFontSize()}px`)
        this.element.classList.remove(this.hiddenClass)
      })
    }, 100)
  }

  screenResize () {
    this.setFontSize()
  }

  get canResizeFont () {
    return (
      document.documentElement.classList.contains('ui-fonts') &&
      this.containerWidth > 0 &&
      this.characterPixelWidth > 0 &&
      parseFloat(this.calculatedFontSize()) > 0
    )
  }

  calculatedFontSize () {
    const sizeRatio = parseFloat(this.containerWidth) / parseFloat(this.characterPixelWidth)
    // We deduct 0.2 to handle any rounding issues which might cause an overflow
    return (Math.floor(parseFloat(this.currentFontSize) * sizeRatio * 10) / 10) - 0.3
  }

  get containerWidth () {
    return this.element.getBoundingClientRect().width
  }

  get currentFontSize () {
    return $(this.element).css('font-size')
  }

  get longestLineLength () {
    return $(this.element).text().split('\n').map(string => string.length).sort().pop()
  }

  get imageCharacterCount () {
    return this.firstLine.length
  }

  get fontFamily () {
    return 'AuxMono'
  }

  get characterPixelWidth () {
    const canvas = document.getElementById('canvas')
    const ctx = canvas.getContext('2d')
    ctx.font = `${this.currentFontSize} ${this.fontFamily}`
    const text = ctx.measureText('x'.repeat(this.longestLineLength))
    return text.width
  }
}
