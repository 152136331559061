import { Controller } from '@hotwired/stimulus'

export default class AutocompleteAvatarController extends Controller {
  static targets = ['avatar']
  static values = {
    params: String,
    iconSizeClass: String
  }

  connect () {
    if (this.avatarUrl && (this.avatarUrl.avatar || this.avatarUrl.result_icon)) { this.displayAvatar() }
  }

  get avatarUrl () {
    const initialData = this.paramsValue
    return JSON.parse(unescape(initialData))
  }

  displayAvatar () {
    const el = this.hasAvatarTarget ? this.avatarTarget : this.element

    if (this.avatarUrl.avatar) {
      el.innerHTML = `<img class='rounded' src='${this.avatarUrl.avatar}'>`
    } else if (this.avatarUrl.result_icon) {
      el.innerHTML = this.avatarUrl.result_icon

      if (this.avatarUrl.icon_color) {
        this.element.classList.add(`bg-${this.avatarUrl.icon_color}`)
        el.classList.add('text-white')
      }

      if (this.avatarUrl.text_color) {
        el.classList.add(`text-${this.avatarUrl.text_color}`)
      } else {
        el.classList.add('text-white')
      }

      if (this.iconSizeClassValue && el.firstChild) {
        el.firstChild.classList.add(this.iconSizeClassValue)
      }
    }
  }
}
