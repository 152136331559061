import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import { keyCodes } from '../utils/keyboard'

export default class TypeaheadSearchController extends Controller {
  static targets = [
    'results',
    'suggestions',
    'query',
    'form',
    'taggings',
    'display',
    'inlineForm'
  ]

  static classes = ['hiddenPanel', 'hiddenElement']
  static values = {
    searchEndpoint: String,
    keepInputValueOnReset: Boolean,
    searchWithEmptyQuery: Boolean,
    resultsPanelId: String,
    ignoreOutsideClicks: Boolean,
    withinModal: Boolean,
    scrollableResults: Boolean,
    eventIdentifier: String,
    modalBodyMaxHeightOffset: String
  }

  initialize () {
    this.conductSearch = debounce(this.conductSearch, 500)
    this.previousQuery = ''
  }

  connect () {
    document.addEventListener('click', this.handleClicks.bind(this), false)
  }

  disconnect () {
    this.reset()
  }

  get resultsPanel () {
    return this.hasResultsTarget
      ? this.resultsTarget
      : document.querySelector(this.resultsPanelIdValue)
  }

  get modalBodyMaxHeightOffset () {
    if (this.hasModalBodyMaxHeightOffsetValue) {
      return this.modalBodyMaxHeightOffsetValue
    } else {
      return 6
    }
  }

  get modalBodyMaxHeight () {
    const modalBody = document.querySelector('.modal-body')
    const modalBodyHeight = modalBody.clientHeight
    return `calc(${modalBodyHeight}px - ${this.modalBodyMaxHeightOffset}rem)`
  }

  setDropdownVisibility () {
    this.sendOpenedDropdownEvent()
    const modalBody = document.querySelector('.modal-body')
    modalBody.style.overflowY = 'hidden'

    if ((this.scrollableResultsValue === true)) {
      this.resultsPanel.style.overflowY = 'scroll'
    } else {
      this.resultsPanel.style.overflowY = 'hidden'
    }
    this.resultsPanel.style.maxHeight = this.modalBodyMaxHeight
  }

  removeDropdownVisibility () {
    this.sendClosedDropdownEvent()
    const modalBody = document.querySelector('.modal-body')
    modalBody.style.removeProperty('overflow-y')
    this.resultsPanel.style.removeProperty('overflow-y')
    this.resultsPanel.style.removeProperty('max-height')
  }

  sendOpenedDropdownEvent () {
    const event = new CustomEvent(
      `openedDropdown.${this.eventIdentifierValue}`,
      { bubbles: true, cancelable: true }
    )
    document.dispatchEvent(event)
  }

  sendClosedDropdownEvent () {
    const event = new CustomEvent(
      `closedDropdown.${this.eventIdentifierValue}`,
      { bubbles: true, cancelable: true }
    )
    document.dispatchEvent(event)
  }

  handleClicks (event) {
    const clickFromDropdown = [this.queryTarget, this.resultsPanel].includes(
      event.target
    )
    if (!clickFromDropdown && !this.ignoreOutsideClicksValue) this.hideSearchResults()
  }

  fetchResults () {
    if (this.query === this.previousQuery) return

    if (!this.searchWithEmptyQueryValue && this.query === '') {
      this.reset()
      return
    }

    this.conductSearch()
  }

  handleKeyPress (event) {
    if (event && keyCodes.ESCAPE === event.keyCode) {
      this.hideSearchResults()
    } else {
      this.fetchResults()
    }
  }

  conductSearch () {
    this.previousQuery = this.query
    this.showSearchResults()
    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit')
    } else {
      this.resultsTarget.src = this.searchEndpointValue + `&query=${this.query}`
    }
  }

  showSearchResults () {
    this.resultsPanel.classList.remove(this.hiddenPanelClass)

    if (this.hasInlineFormTarget) {
      this.inlineFormTarget.classList.remove(this.hiddenPanelClass)
      this.queryTarget.focus()
    }

    if (this.withinModalValue) {
      this.setDropdownVisibility()
    }

    if (this.hasDisplayTarget) {
      this.displayTarget.classList.add(this.hiddenPanelClass)
    }
  }

  hideSearchResults () {
    if (this.resultsPanel) {
      this.resultsPanel.classList.add(this.hiddenPanelClass)
    }

    if (this.hasInlineFormTarget) {
      this.inlineFormTarget.classList.add(this.hiddenPanelClass)
      this.resetQuery()
    }

    if (this.withinModalValue) {
      this.removeDropdownVisibility()
    }

    if (this.hasDisplayTarget) {
      this.displayTarget.classList.remove(this.hiddenPanelClass)
    }
  }

  reset () {
    if (this.resultsPanel) {
      const resultsContainer =
        this.resultsPanel.querySelector('turbo-frame') || this.resultsPanel

      resultsContainer.innerHTML = ''
    }

    if (!this.keepInputValueOnResetValue) {
      this.resetQuery()
    }

    this.hideSearchResults()
  }

  resetQuery () {
    this.queryTarget.value = ''
    this.previousQuery = null
  }

  updateInputWithValue (e) {
    const queryValue = e.target.closest('.list-item-wrapper').dataset
      .queryValue
    this.queryTarget.value = queryValue
    this.fetchResults()
  }

  removeSuggestion (e) {
    e.target.closest('a').classList.add(this.hiddenElementClass)
  }

  get query () {
    return this.queryTarget.value
  }
}
