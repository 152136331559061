import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class MultiSelectSearchController extends Controller {
  static targets = [
    'selectedItems',
    'searchForm',
    'searchInput'
  ]

  connect () {
    this.conductSearch = debounce(this.conductSearch, 500)
  }

  conductSearch (event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      return this.searchInputTarget.blur()
    }

    Rails.fire(this.searchFormTarget, 'submit')
  }
}
