import { Controller } from '@hotwired/stimulus'

export default class CommentingEnabledInputController extends Controller {
  static targets = ['enabledText']

  handleToggle (e) {
    if (e.target.checked) {
      this.enabledTextTarget.innerText = 'Comments on'
    } else {
      this.enabledTextTarget.innerText = 'Comments off'
    }
  }
}
