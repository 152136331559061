import { Controller } from '@hotwired/stimulus'

export default class ProductProfilesController extends Controller {
  static targets = [
    'pill',
    'image',
    'engineerImage',
    'designerImage',
    'advocateImage',
    'founderImage',
    'investorImage'
  ]

  click (e) {
    const allTargets = this.pillTargets.concat(this.imageTargets)

    allTargets.forEach(target => {
      target.classList.remove('active')
    })

    // activate button and corresponding image
    const buttonTarget = e.target.closest('.profile-example-buttons__button')

    buttonTarget.classList.add('active')
    this[`${buttonTarget.dataset.imageName}ImageTarget`].classList.add('active')
  }
}
