import { Controller } from '@hotwired/stimulus'

export default class ConfirmModalCloseController extends Controller {
  static targets = ['closeTrigger']
  static values = {
    confirmationText: String
  }

  showConfirmation () {
    if (window.confirm(this.confirmationText) === true) {
      this.closeTriggerTarget.click()
    }
  }

  get confirmationText () {
    return (
      this.confirmationTextValue || 'Are you sure? Any changes will be lost.'
    )
  }
}
