import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class HiddenIfNoItemsPresentController extends Controller {
  static targets = ['item']
  static classes = ['hidden']

  connect () {
    this.adjustHiddenClassAsNeeded()

    useMutation(this, { childList: true, subtree: true })
  }

  get itemsPresent () {
    return this.hasItemTarget
  }

  adjustHiddenClassAsNeeded () {
    if (this.itemsPresent) {
      this.element.classList.remove(this.hiddenClass)
    } else {
      this.element.classList.add(this.hiddenClass)
    }
  }

  mutate (entries) {
    for (const mutation of entries) {
      if (mutation.type === 'childList') {
        this.adjustHiddenClassAsNeeded()
      }
    }
  }
}
