import qs from 'qs'
import { Controller } from '@hotwired/stimulus'
import { loadStripe } from '@stripe/stripe-js'
import { get } from '@rails/request.js'
import { getMeta } from '../utils/dom'
import Rails from '@rails/ujs'

// Connects to data-controller="card-details"
export default class extends Controller {
  static targets = ['paymentMethodIdField', 'form', 'submitButton', 'promoCode']
  static values = {
    usingSavedCard: Boolean,
    clientSecret: String,
    savedAddress: String,
    toggleSubmitButtonState: Boolean,
    summaryUrl: String,
    productPricingId: String,
    defaultPromoCode: String,
    darkMode: Boolean
  }

  async connect () {
    this.promoCode = this.defaultPromoCodeValue
    this.address = {}
    this.stripe = await loadStripe(getMeta('stripe:publishable-key'))
    this.addressComplete = this.savedAddress
    this.paymentDetailsComplete = false

    if (this.usingSavedCardValue) {
      return
    }

    const theme = this.darkModeValue ? 'night' : 'stripe'

    this.elements = this.stripe.elements({
      clientSecret: this.clientSecretValue,
      loader: 'always',
      appearance: { theme }
    })

    this.paymentElement = this.elements.create('payment', {
      terms: {
        card: 'never'
      },
      fields: {
        billingDetails: {
          address: {
            state: 'auto',
            country: 'auto',
            postalCode: 'auto'
          }
        }
      }
    })
    this.addressElement = this.elements.create('address', {
      mode: 'billing',
      contacts: this.defaultContacts(),
      fields: {
        phone: 'always'
      },
      validation: {
        phone: {
          required: 'always'
        }
      }
    })

    if (this.toggleSubmitButtonStateValue && this.hasSubmitButtonTarget) {
      this.paymentElement.on(
        'change',
        this.handlePaymentElementChange.bind(this)
      )

      this.addressElement.on(
        'change',
        this.handleAddressElementChange.bind(this)
      )
    }

    this.paymentElement.mount('#payment-method')
    this.addressElement.mount('#address-element')
  }

  handlePaymentElementChange (event) {
    this.paymentDetailsComplete = event.complete
    this.configureSubmitButtonState()
  }

  handleAddressElementChange (event) {
    const addressNoLongerComplete = this.addressComplete === true && !event.complete
    this.addressComplete = event.complete
    this.configureSubmitButtonState()

    if ((this.addressComplete || addressNoLongerComplete)) {
      this.address = event.value.address
      this.updateOrderSummary()
    }
  }

  applyPromoCode (event) {
    event.preventDefault()

    this.promoCode = this.promoCodeTarget.value
    this.updateOrderSummary()
  }

  removePromoCode (event) {
    event.preventDefault()

    this.promoCode = null
    this.updateOrderSummary()
  }

  updateOrderSummary () {
    if (this.hasProductPricingIdValue && this.hasSummaryUrlValue) {
      // Re-render the order summary
      const { country, postal_code: zipCode, state } = this.addressComplete ? this.address : {}

      const query = qs.stringify(
        {
          promo_code: this.promoCode,
          country_code: country || '',
          state: state || '',
          zip_code: zipCode || '',
          basket: {
            product_pricing_ids: [this.productPricingIdValue]
          }
        },
        {
          arrayFormat: 'brackets'
        }
      )
      get(`${this.summaryUrlValue}?${query}`, {
        responseKind: 'turbo-stream'
      })
    }
  }

  configureSubmitButtonState () {
    this.submitButtonTarget.disabled = !(this.addressComplete && this.paymentDetailsComplete) && !this.paymentMethodIdFieldTarget.value
  }

  defaultContacts () {
    if (this.savedAddress) {
      const contactAddress = {
        name: 'Saved Address',
        address: this.savedAddress
      }
      return [contactAddress]
    } else {
      return []
    }
  }

  get savedAddress () {
    const savedAddress = this.savedAddressValue || null
    return JSON.parse(unescape(savedAddress))
  }

  async save (event) {
    if (this.usingSavedCardValue) return

    event.preventDefault()
    event.stopPropagation()

    try {
      // Docs: https://stripe.com/docs/js/setup_intents/confirm_setup
      const result = await this.stripe.confirmSetup({
        elements: this.elements,
        redirect: 'if_required'
      })

      if (result.error) {
        console.error('Error saving card details', result.error)
        this.toastInsertionController.insertToastWithMessage(result.error.message)
      } else {
        this.paymentMethodIdFieldTarget.value = result.setupIntent.payment_method
        Rails.fire(this.formTarget, 'submit')
      }
    } catch (err) {
      console.error('Unknown error saving card details', err)
    }
  }

  get toastInsertionController () {
    return this.application.getControllerForElementAndIdentifier(document.body, 'toast-insertion')
  }
}
