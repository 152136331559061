import { Controller } from '@hotwired/stimulus'

export default class UpdateChoicesController extends Controller {
  static values = {
    updatedResults: Array,
    instanceId: String
  }

  connect () {
    const event = new CustomEvent(
      `choices.updateChoices.${this.instanceIdValue}`,
      {
        bubbles: true,
        cancelable: true,
        detail: {
          results: this.updatedResultsValue
        }
      }
    )
    document.dispatchEvent(event)
  }
}
