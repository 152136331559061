import Rails from '@rails/ujs'
import { Turbo } from '@hotwired/turbo-rails'
import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

/*
 * Usage
 * =====
 *
 * add data-controller="navigate-when-complete" to your <form> element
 *
 * Steps can be added and once they are all completed and removed from
 * the dom, the controller will navigate.
 * data-navigate-when-complete-target="step"
 *
 */
export default class extends Controller {
  static targets = ['step', 'link']
  static values = {
    url: String
  }

  connect () {
    if (this.stepTargets.length > 0) {
      useMutation(this, { childList: true, subtree: true })
    }
  }

  mutate (entries) {
    for (const mutation of entries) {
      if (mutation.type === 'childList') {
        const stepsLeft = this.stepTargets.length
        if (stepsLeft < 1) {
          if (this.hasLinkTarget) {
            Rails.fire(this.linkTarget, 'click')
          } else {
            Turbo.visit(this.urlValue)
          }
        }
      }
    }
  }
}
