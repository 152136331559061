import { Controller } from '@hotwired/stimulus'

export default class ChartFetchController extends Controller {
  static values = { targetId: String }
  static classes = ['chartLoading']

  setLoadingState () {
    const element = document.getElementById(this.targetIdValue)
    element.classList.add(this.chartLoadingClass)
  }
}
