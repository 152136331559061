import { Controller } from '@hotwired/stimulus'

export default class AccordionExpandedController extends Controller {
  connect () {
    const event = new CustomEvent('accordion:expanded', {
      bubbles: true,
      cancelable: true
    })

    $(this.element).on('shown.bs.collapse', () => this.element.dispatchEvent(event))
  }
}
