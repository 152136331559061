import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class DropdownFilterController extends Controller {
  static targets = ['form']
  static values = {
    highlightsContainerId: String,
    resetOnConnect: Boolean,
    query: String
  }

  connect () {
    this.submitForm = debounce(this.submitForm, 1000)

    if (this.resetOnConnectValue) this.clearUrlParams()
  }

  applyFilter (e) {
    this.updateURL(e.target.value, e.target.checked)
    this.submitForm()
  }

  submitForm () {
    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit')
    }
  }

  resetFilters (e) {
    e.stopPropagation()

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)

    if (urlParams.get(this.queryValue)) {
      this.clearUrlParams()
      this.submitForm()
    }
  }

  clearUrlParams () {
    const url = new URL(window.location)
    url.searchParams.delete(this.queryValue)
    window.history.pushState(window.history.state, null, url)

    this.clearCheckedItems()
  }

  clearCheckedItems () {
    const checkedItems = this.element.querySelectorAll('input:checked')
    checkedItems.forEach(checkbox => {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = false
    })
  }

  // eslint-disable-next-line class-methods-use-this
  updateURL (queryTerm, checked) {
    const url = new URL(window.location)

    if (checked) {
      url.searchParams.append(this.queryValue, queryTerm)
    } else {
      const querySearchParams = url.searchParams.getAll(this.queryValue)
      const indexToRemove = querySearchParams.indexOf(queryTerm)
      if (indexToRemove > -1) {
        querySearchParams.splice(indexToRemove, 1)
      }
      url.searchParams.delete(this.queryValue)
      querySearchParams.forEach(itemName => {
        url.searchParams.append(this.queryValue, itemName)
      })
    }
    window.history.pushState(window.history.state, null, url)
  }
}
