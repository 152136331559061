import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

export default class PillFiltersController extends Controller {
  static targets = ['form', 'radioButton', 'pillCheckbox']

  static values = {
    paramNames: Array,
    updateUrl: Boolean
  }

  connect () {
    this.submitForm = debounce(this.submitForm, 1000)
  }

  showAllFilter () {
    if (this.updateUrlValue) {
      this.clearUrlParams()
      this.setAllFilterParam()
    }
    this.clearCheckedItems()
    this.submitForm()
  }

  forYouFilter () {
    if (this.updateUrlValue) this.clearUrlParams()
    this.clearCheckedItems()
    this.submitForm()
  }

  applyFilter (e) {
    this.clearRadioButtons()
    if (this.updateUrlValue) this.updateFilterParamWithinUrl(e.target.name, e.target.value, e.target.checked)
    this.submitForm()
  }

  submitForm () {
    if (this.hasFormTarget) {
      Rails.fire(this.formTarget, 'submit')
    }
  }

  clearUrlParams () {
    const url = new URL(window.location)
    this.paramNamesValue.forEach(paramName => {
      url.searchParams.delete(paramName)
    })
    url.searchParams.delete('filter_type')
    window.history.pushState(window.history.state, null, url)
  }

  clearRadioButtons () {
    this.radioButtonTargets.forEach(radioButton => {
      // eslint-disable-next-line no-param-reassign
      radioButton.classList.remove('active')
      const checkbox = radioButton.querySelector('input:checked')
      if (checkbox) checkbox.checked = false
    })
  }

  clearCheckedItems () {
    this.pillCheckboxTargets.forEach(checkbox => {
      // eslint-disable-next-line no-param-reassign
      checkbox.checked = false
    })
  }

  // eslint-disable-next-line class-methods-use-this
  updateFilterParamWithinUrl (name, queryTerm, checked) {
    const url = new URL(window.location)

    if (checked) {
      url.searchParams.append(name, queryTerm)
    } else {
      const querySearchParams = url.searchParams.getAll(name)
      const indexToRemove = querySearchParams.indexOf(queryTerm)
      if (indexToRemove > -1) {
        querySearchParams.splice(indexToRemove, 1)
      }
      url.searchParams.delete(name)
      querySearchParams.forEach(itemName => {
        url.searchParams.append(name, itemName)
      })
    }

    url.searchParams.delete('filter_type')
    window.history.pushState(window.history.state, null, url)
  }

  setAllFilterParam () {
    const url = new URL(window.location)
    url.searchParams.append('filter_type', 'all')
    window.history.pushState(window.history.state, null, url)
  }
}
