import { Controller } from '@hotwired/stimulus'

export default class TypewriterAnimationController extends Controller {
  connect () {
    this.text = this.element.innerText.trim()
    this.element.innerText = ''

    if (this.isVisible(this.element)) {
      this.renderText(this.element)
    } else {
      this.renderTextIfVisible(this.element)
    }
  }

  renderTextIfVisible (element) {
    this.visibleChecker = setInterval(async () => {
      if (this.isVisible(element)) {
        this.renderText(element)
        clearInterval(this.visibleChecker)
      }
    }, 100)
  }

  isVisible (element) {
    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
  }

  renderText (element) {
    let spaceFlag
    const textAsArray = this.text.split('')

    textAsArray.forEach((character, idx) => {
      setTimeout(() => {
        if (character === ' ') {
          // We cannot add a space to the end of the innerText -- it will be trimmed and removed.
          // We can add it just before we add the next character
          spaceFlag = true
          return
        }

        let characterToAdd = character
        if (spaceFlag) {
          characterToAdd = ` ${character}`
          spaceFlag = false
        }
        element.innerText += characterToAdd

        if (idx === textAsArray.length - 1) {
          setTimeout(() => {
            this.dispatchAnimationFinishedEvent(element)
          }, 1000)
        }
      }, 10 * idx)
    })
  }

  dispatchAnimationFinishedEvent (element) {
    const event = new CustomEvent('typewriter-animation-finished', {
      bubbles: true,
      cancelable: true
    })
    element.dispatchEvent(event)
  }
}
