import { Controller } from '@hotwired/stimulus'

export default class RefreshComponentIfInIframe extends Controller {
  connect () {
    // Safari has a bug where the images rendered in an iframe have a
    // width of 0. This means the object-fit css property does not work.
    // It seems that at some point the width will start working if we replace the
    // html enough time. This is absolutely mental but i can't think of another way forward here.
    if (this.withinIframe) {
      this.redrawComponentInterval = setInterval(
        this.redrawComponent.bind(this),
        100
      )
    }
  }

  disconnect () {
    if (this.redrawComponentInterval) clearInterval(this.redrawComponent)
  }

  redrawComponent () {
    const img = this.element.querySelector('img')

    if (img.width !== 0) { clearInterval(this.redrawComponentInterval) }

    const replacementHtml = this.element.innerHTML
    this.element.innerHTML = replacementHtml
  }

  get withinIframe () {
    return window.self !== window.top
  }
}
