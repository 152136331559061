import { Controller } from '@hotwired/stimulus'
import { keyCodes } from '../utils/keyboard'

export default class CommentFormController extends Controller {
  static targets = ['formContainer', 'textarea', 'submitButton']
  static classes = ['active', 'mobile', 'desktop']
  static values = {
    repositionInputBasedOnSize: Boolean,
    autofocusInput: Boolean
  }

  connect () {
    if (this.autofocusInputValue) {
      this.textareaTarget.focus()
    }
    if (this.repositionInputBasedOnSizeValue) {
      this.setContainerClassBasedOnScreenSize()
      window.addEventListener(
        'resize',
        this.setContainerClassBasedOnScreenSize.bind(this)
      )
    }
    if (this.textareaTarget.value.length > 0) {
      this.manageActiveStateOfSubmitButton()
    }
  }

  setContainerClassBasedOnScreenSize () {
    const screenWidth = document.documentElement.offsetWidth
    if (screenWidth >= 1024) {
      this.formContainerTarget.classList.add(this.desktopClass)
      this.formContainerTarget.classList.remove(this.mobileClass)
    } else {
      this.formContainerTarget.classList.add(this.mobileClass)
      this.formContainerTarget.classList.remove(this.desktopClass)
    }
  }

  checkForEnterPress (event) {
    const metaKeyPressed = event.ctrlKey || event.metaKey
    const enterKeyPressed = keyCodes.ENTER === event.keyCode

    if (metaKeyPressed && enterKeyPressed) {
      Rails.fire(this.element, 'submit')
    }
  }

  manageActiveStateOfSubmitButton () {
    if (this.textareaTarget.value.length > 0) {
      this.submitButtonTarget.classList.add(this.activeClass)
    } else {
      this.submitButtonTarget.classList.remove(this.activeClass)
    }
  }
}
