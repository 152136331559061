import { Controller } from '@hotwired/stimulus'

export default class DnsRecordsTableRowController extends Controller {
  static targets = [
    'priorityField',
    'srvField'
  ]

  remove () {
    this.element.remove()
    event.preventDefault()
  }

  toggleFields (event) {
    if (event.target.value === 'MX') {
      this.hideFields(this.srvFieldTargets)
      this.showFields(this.mxFields())
    } else if (event.target.value === 'SRV') {
      this.showFields(this.srvFields())
    } else {
      this.hideFields(this.srvFields())
    }

    event.preventDefault()
  }

  mxFields () {
    return [this.priorityFieldTarget]
  }

  srvFields () {
    const fields = this.srvFieldTargets
    fields.push(this.priorityFieldTarget)
    return fields
  }

  hideFields (fields) {
    fields.forEach(field => {
      field.style.display = 'none'
    })
  }

  showFields (fields) {
    fields.forEach(field => {
      field.style.display = 'block'
    })
  }
}
