import { Controller } from '@hotwired/stimulus'

export default class DropdownInputClearanceController extends Controller {
  static targets = ['input', 'typeahead']
  static values = {
    inputTypeaheadController: String
  }

  get inputTypeaheadController () {
    return this.application.getControllerForElementAndIdentifier(
      this.typeaheadTarget,
      this.inputTypeaheadControllerValue
    )
  }

  resetInput () {
    this.inputTarget.value = ''

    if (this.inputTypeaheadController) {
      this.inputTypeaheadController.fetchResults()
    }
  }
}
