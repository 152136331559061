import { Controller } from '@hotwired/stimulus'

export default class SettingsFieldGroupingController extends Controller {
  static targets = ['field', 'input', 'submit', 'cancel']
  static classes = ['hidden']

  initialize () {
    this.initialInputValues = {}
    this.inputTargets.forEach((input) => {
      this.initialInputValues[input.id] = input.value
    })
  }

  disable (event) {
    this.disableField(this.findParentField(event.currentTarget))
  }

  enable (event) {
    if (this.enabledInput() !== undefined) {
      this.disableField(this.findParentField(this.enabledInput()))
    };

    this.enableField(this.findParentField(event.currentTarget))
  }

  disableField (field) {
    const input = this.findFieldInput(field)
    input.value = this.initialInputValues[input.id]
    input.disabled = true
    this.findFieldToggle(field).classList.remove(this.hiddenClass)
    this.findFieldSubmit(field).classList.add(this.hiddenClass)
    this.findFieldCancel(field).classList.add(this.hiddenClass)
  }

  enableField (field) {
    this.findFieldInput(field).disabled = false
    this.findFieldToggle(field).classList.add(this.hiddenClass)
    this.findFieldSubmit(field).classList.remove(this.hiddenClass)
    this.findFieldCancel(field).classList.remove(this.hiddenClass)
  }

  enabledInput () {
    return this.inputTargets.find(input => {
      return input.disabled !== true
    })
  }

  findFieldInput (field) {
    return field.querySelector(
      "[data-settings-field-grouping-target='input']"
    )
  }

  findFieldCancel (field) {
    return field.querySelector(
      "[data-settings-field-grouping-target='cancel']"
    )
  }

  findFieldSubmit (field) {
    return field.querySelector(
      "[data-settings-field-grouping-target='submit']"
    )
  }

  findFieldToggle (field) {
    return field.querySelector(
      "[data-action='settings-field-grouping#enable']"
    )
  }

  findParentField (element) {
    return element.closest(
      "[data-settings-field-grouping-target='field']"
    )
  }
}
