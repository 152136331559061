import { Controller } from '@hotwired/stimulus'

export default class DisabledButtonCountdownController extends Controller {
  static targets = ['timer', 'countdown']

  static values = {
    seconds: String
  }

  initialize () {
    this.secondsLeft = parseInt(this.secondsValue)

    if (this.secondsLeft === 0) {
      this.countdownTarget.remove()
    } else {
      this.element.disabled = true
      this.startCountdown()
    }
  }

  startCountdown () {
    this.refreshTimer()

    this.timeout = setInterval(() => {
      this.countdown()
    }, 1000)
  }

  countdown () {
    if (this.secondsLeft === 0) {
      clearTimeout(this.timeout)
      this.enableButton()
    } else {
      this.refreshTimer()
      this.secondsLeft--
    }
  }

  timerDisplay () {
    return this.minutesDisplay() + ':' + this.secondsDisplay()
  }

  minutesDisplay () {
    return Math.floor(this.secondsLeft / 60)
  }

  secondsDisplay () {
    if (this.secondsLeft < 10) {
      return '0' + this.secondsLeft
    } else {
      return this.secondsLeft
    }
  }

  refreshTimer () {
    this.timerTarget.innerHTML = this.timerDisplay()
  }

  enableButton () {
    this.countdownTarget.remove()
    this.element.disabled = false
  }
}
