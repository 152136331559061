import { StradaController } from './strada_controller'

export default class extends StradaController {
  static component = 'message-composer'
  static values = {
    recipient: String,
    body: String
  }

  open () {
    if (!this.enabled) return

    this.send('connect', {
      recipients: [this.recipientValue],
      body: this.bodyValue
    }, () => {})
  }
}
