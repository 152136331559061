import Rails from '@rails/ujs'
import { Controller } from '@hotwired/stimulus'
import { keyCodes } from '../utils/keyboard'

export default class SubmitFromKeyboardController extends Controller {
  handleKeyPress (event) {
    if ((event.ctrlKey || event.metaKey) && keyCodes.ENTER === event.keyCode) {
      Rails.fire(this.element, 'submit')
    }
  }
}
