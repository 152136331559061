import { Controller } from '@hotwired/stimulus'
import * as FontFaceObserver from 'fontfaceobserver'

export default class FontLoaderController extends Controller {
  static values = {
    fonts: Object
  }

  connect () {
    const observers = []

    Object.keys(this.fontsValue).forEach(family => {
      this.fontsValue[family].forEach(weight => {
        observers.push(new FontFaceObserver(family, { weight }))
      })
    })

    Promise.all(observers).then(() => {
      document.documentElement.classList.add('ui-fonts')
    })
  }
}
