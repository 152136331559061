import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="animating-text-carousel"
export default class extends Controller {
  static targets = ['item']

  connect () {
    this.currentItem = 0
    this.totalItems = this.itemTargets.length

    setInterval(() => {
      requestAnimationFrame(() => {
        let nextIndex = this.currentItem + 1
        if (nextIndex >= this.totalItems) {
          nextIndex = 0
        }
        this.moveToNextItem(nextIndex, this.currentItem)
      })
    }, 4000)
  }

  moveToNextItem (index, previousIndex) {
    const previousElement = this.itemTargets[previousIndex]
    const nextElement = this.itemTargets[index]

    // Animate out the current item
    previousElement.classList.remove('animate__fadeInRight')
    previousElement.classList.add('animate__fadeOutLeft')

    // Wait for the out animation to complete
    setTimeout(() => {
      // Make sure the previous step is hidden
      previousElement.classList.add('invisible')
      previousElement.classList.remove('animate__fadeOutLeft')

      // Animate in the next item
      nextElement.classList.add('animate__fadeInRight')
      nextElement.classList.remove('invisible')
    }, 500)

    this.currentItem = index
  }
}
