import { Controller } from '@hotwired/stimulus'

export default class CachedLoadingSpinnerController extends Controller {
  static classes = ['hidden']

  connect () {
    document.addEventListener('turbo:before-cache', event => {
      this.element.classList.remove(this.hiddenClass)
    })
  }
}
