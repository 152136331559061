import { Controller } from '@hotwired/stimulus'
import { getMeta } from '../utils/dom'

export default class CloudFlareTurnstileController extends Controller {
  static targets = ['form', 'submit', 'widgetContainer']
  static values = {
    siteKey: String
  }

  connect () {
    if (this.skipTurnstile) {
      this.tokenReceived = true
      return
    }

    this.tokenReceived = false

    window.turnstile.ready(function () {
      // eslint-disable-next-line no-undef
      turnstile.render(this.widgetContainerTarget, {
        sitekey: this.siteKeyValue,
        appearance: 'interaction-only',
        callback: function (token) {
          this.tokenReceived = true
        }.bind(this)
      })
    }.bind(this))
  }

  submitForm (e) {
    e.preventDefault()

    this.fireFormIfTokenRecieved()
  }

  fireFormIfTokenRecieved () {
    if (this.tokenReceived) {
      Rails.fire(this.formTarget, 'submit')
    } else {
      setTimeout(() => { this.fireFormIfTokenRecieved() }, 200)
    }
  }

  get skipTurnstile () {
    return getMeta('skip_cloudflare_turnstile') === 'true'
  }
}
