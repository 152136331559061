import { Controller } from '@hotwired/stimulus'

export default class RemoteSelectionFormController extends Controller {
  static targets = ['submitButton']

  connect () {
    this.element.addEventListener('remote-option:selected', () => {
      this.enableSubmit()
    })
  }

  disableSubmit () {
    this.submitButtonTarget.disabled = true
  }

  enableSubmit () {
    this.submitButtonTarget.disabled = false
  }
}
