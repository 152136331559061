// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers'

const { bridgePlatform } = document.documentElement.dataset
const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
const definitions = definitionsFromContext(context)
definitions.push(
  ...definitions.map(definition => {
    const identifier = `${definition.identifier}@${bridgePlatform}`
    return { ...definition, identifier }
  })
)
application.load(definitions)
