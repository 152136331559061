import { Controller } from '@hotwired/stimulus'

export default class GroupedPillsController extends Controller {
  static targets = ['submit', 'spinner', 'searchIcon']
  static classes = ['pill', 'backgrounded', 'hiddenIcon']
  static values = {
    choicesInstanceIds: Array,
    submitResponseTarget: String
  }

  connect () {
    this.choicesInstanceIdsValue.forEach(instanceValue => {
      document.addEventListener(
        `openedDropdown.${instanceValue}`,
        this.addBackground.bind(this)
      )
      document.addEventListener(
        `closedDropdown.${instanceValue}`,
        this.hideBackground.bind(this)
      )
    })

    document.addEventListener(
      'turbo:before-fetch-response',
      this.onSubmitResponse.bind(this)
    )

    this.hideLoadingSpinner()
  }

  disconnect () {
    this.choicesInstanceIdsValue.forEach(instanceValue => {
      document.removeEventListener(
        `openedDropdown.${instanceValue}`,
        this.addBackground
      )
      document.removeEventListener(
        `closedDropdown.${instanceValue}`,
        this.hideBackground
      )
    })
  }

  addBackground () {
    setTimeout(() => {
      const dropdownPills = this.element.querySelectorAll(`.${this.pillClass}`)
      dropdownPills.forEach(dropdownPill => {
        // eslint-disable-next-line no-param-reassign
        dropdownPill.classList.add(this.backgroundedClass)
      })
    }, 0)
  }

  hideBackground () {
    const dropdownPills = this.element.querySelectorAll(`.${this.pillClass}`)
    dropdownPills.forEach(dropdownPill => {
      // eslint-disable-next-line no-param-reassign
      dropdownPill.classList.remove(this.backgroundedClass)
    })
  }

  showLoadingSpinner () {
    this.submitTargets.forEach(submitTarget => {
      // eslint-disable-next-line no-param-reassign
      submitTarget.disabled = true
    })
    this.searchIconTargets.forEach(searchIcon => {
      searchIcon.classList.add(this.hiddenIconClass)
    })
    this.spinnerTargets.forEach(spinnerTarget => {
      spinnerTarget.classList.remove(this.hiddenIconClass)
    })
  }

  hideLoadingSpinner () {
    this.submitTargets.forEach(submitTarget => {
      // eslint-disable-next-line no-param-reassign
      submitTarget.disabled = false
    })
    this.searchIconTargets.forEach(searchIcon => {
      searchIcon.classList.remove(this.hiddenIconClass)
    })
    this.spinnerTargets.forEach(spinnerTarget => {
      spinnerTarget.classList.add(this.hiddenIconClass)
    })
  }

  onSubmitResponse (event) {
    if (event.target.id === this.submitResponseTargetValue) {
      this.hideLoadingSpinner()
    }
  }
}
