import { Controller } from '@hotwired/stimulus'
import { hasVariant } from '../utils/request'

export default class EnterKeyController extends Controller {
  static values = {
    action: String
  }

  connect () {
    if (!hasVariant('ios')) return // Only run on iOS

    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.element.addEventListener('keypress', this.handleKeyPress)
  }

  disconnect () {
    if (!hasVariant('ios')) return // Only run on iOS

    this.element.removeEventListener('keypress', this.handleKeyPress)
  }

  handleKeyPress (event) {
    // listen for enter key press and depending on the action do something.
    if (event.key === 'Enter' || event.keyCode === 13) {
      const inputs = Array.from(this.formElement.querySelectorAll('input, textarea, select, [data-controller="strada--choices-selector"], .content-editor'))
      const visibleInputs = inputs.filter((item, index) => item.offsetParent !== null)

      const currentIndex = visibleInputs.indexOf(this.element)
      let nextIndex

      switch (this.actionValue) {
        case 'next':
          event.preventDefault() && event.stopPropagation()
          if (currentIndex < visibleInputs.length) {
            nextIndex = (currentIndex + 1) % visibleInputs.length
            this.selectElement(visibleInputs[nextIndex], this.element)
          }
          break
        case 'done':
          event.preventDefault() && event.stopPropagation()
          this.element.blur()
          break
        case 'send':
        case 'go':
        case 'enter':
          break
        case 'search':
          this.element.blur()
          break
        default:
          event.preventDefault() && event.stopPropagation()
          break
      }
    }
  }

  selectElement (element, previousElement) {
    const controller = String(element.getAttribute('data-controller'))
    if (controller.includes('strada--date-picker')) {
      element.click()
    } else if (controller.includes('strada--choices-selector')) {
      previousElement.blur()
      const touchEvent = new Event('touchstart')
      element.children[0].dispatchEvent(touchEvent)
    } else if (controller === 'content-editor') {
      const contentEditorController = this.application.getControllerForElementAndIdentifier(element, 'content-editor')
      contentEditorController.focus()
    } else if (controller.includes('dynamic-content-editor')) {
      const contentEditorController = this.application.getControllerForElementAndIdentifier(element, 'dynamic-content-editor')
      contentEditorController.focus()
    } else {
      element.focus()
    }
  }

  get formElement () {
    return this.element.closest('form')
  }
}
