import { Controller } from '@hotwired/stimulus'
import { useVisibility } from 'stimulus-use'

let lastRefresh = currentTimeInSeconds()
let completedConnect = false

function currentTimeInSeconds () {
  return +(new Date()) / 1000
}

export default class RefreshIfStaleController extends Controller {
  static values = {
    endpoint: String
  }

  connect () {
    useVisibility(this)

    completedConnect = true
  }

  visible () {
    if (!completedConnect) return

    const newSrc = `${this.endpointValue}?last_refreshed_at=${lastRefresh}`
    lastRefresh = currentTimeInSeconds()
    this.element.src = newSrc
  }
}
