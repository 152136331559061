import { Controller } from '@hotwired/stimulus'

export default class NativeCollaborateCarouselController extends Controller {
  static targets = ['card']

  connect () {
    const cards = this.cardTargets

    const options = {
      threshold: 1.0,
      rootMargin: '0px 0px 0px -18px'
    }

    const observer = new IntersectionObserver(this.checkIfCardAtCarouselStart.bind(this), options)
    cards.forEach(card => {
      observer.observe(card)
    })
  }

  checkIfCardAtCarouselStart (entries, observer) {
    entries.forEach(entry => {
      if (entry.boundingClientRect.x <= 32) {
        // We do not want to trigger on connect when the first card loads in
        if (this.first_trigger_complete) {
          const event = new CustomEvent('card-at-carousel-start')
          this.element.dispatchEvent(event)
        } else {
          this.first_trigger_complete = true
        }
      }
    })
  }
}
