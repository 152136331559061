import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'
import Sortable from 'sortablejs'

export default class DraggableObjectController extends Controller {
  static values = {
    url: String,
    formPrefix: String,
    turboStream: Boolean
  }

  connect () {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      handle: '.draggable-object',
      ghostClass: 'draggable-object--ghost',
      chosenClass: 'draggable-object--chosen',
      dragClass: 'draggable-object--dragging',
      animation: 150,
      onEnd: this.end.bind(this)
    })
  }

  end (event) {
    const { id } = event.item.dataset
    const displayOrder = event.newIndex + 1
    const url = this.urlValue.replace(':id', id)

    if (this.turboStreamValue) {
      const data = {
        body: {
          [this.formPrefixValue]: {
            display_order: displayOrder
          }
        },
        contentType: 'application/json',
        responseKind: 'turbo-stream'
      }
      patch(url, data)
    } else {
      const data = new FormData()
      data.append(`${this.formPrefixValue}[display_order]`, displayOrder)

      Rails.ajax({
        url: this.urlValue.replace(':id', id),
        type: 'PATCH',
        data
      })
    }
  }
}
