import { Controller } from '@hotwired/stimulus'

// TODO: We should refactor this controller name to something like OverflowListController so it is clear it can be used for things other than badges
export default class BadgesListController extends Controller {
  static targets = [
    'badgesContainer',
    'badge',
    'overflowBadge',
    'overflowBadgeCount'
  ]

  static classes = ['hiddenBadge']

  static values = {
    maxRowCount: Number,
    mobileMaxRowCount: Number,
    expanded: Boolean,
    showLess: String,
    overflowPrefix: String,
    overflowSuffix: String,
    hideOverflowCount: Boolean
  }

  initialize () {
    this.redrawInterval = setInterval(
      this.redrawBadges.bind(this),
      20
    )
  }

  get badgeRowLimit () {
    const windowWidth = window.innerWidth
    return windowWidth >= 1024
      ? this.maxRowCountValue
      : this.mobileMaxRowCountValue
  }

  redrawBadges () {
    if (!document.documentElement.classList.contains('ui-fonts')) return

    clearInterval(this.redrawInterval)

    if (this.badgeRowLimit >= 0) {
      this.hideBadgeTargets()
      this.processBadgesList()
    }
  }

  screenResize () {
    if (!this.expandedValue) this.redrawBadges()
  }

  processBadgesList () {
    let rowCount = 0
    let previousElementHeight = 0

    window.requestAnimationFrame(() => {
      if (this.hasOverflowBadgeTarget) this.hideOverflowBadge()

      // eslint-disable-next-line array-callback-return, consistent-return
      this.badgeTargets.some((badgeTarget, idx) => {
        this.showBadge(badgeTarget)

        if (this.badgesContainerTarget.clientHeight !== previousElementHeight) {
          rowCount += 1
          previousElementHeight = this.badgesContainerTarget.clientHeight
        }

        if (rowCount > this.badgeRowLimit) {
          this.hideBadge(badgeTarget)

          if (this.hasOverflowBadgeTarget) this.showOverflowBadge(idx, this.badgesContainerTarget.clientHeight)
          return true
        }
      })
    })
  }

  hideBadgeTargets () {
    this.badgeTargets.forEach(badgeTarget => this.hideBadge(badgeTarget))
  }

  showBadgeTargets () {
    this.badgeTargets.forEach(badgeTarget => this.showBadge(badgeTarget))
  }

  hideBadge (badgeTarget) {
    badgeTarget.classList.add(this.hiddenBadgeClass)
  }

  showBadge (badgeTarget) {
    badgeTarget.classList.remove(this.hiddenBadgeClass)
  }

  toggleDisplayedBadges () {
    if (this.expandedValue) {
      this.redrawBadges()
    } else {
      this.showBadgeTargets()
      if (this.hasOverflowBadgeTarget) {
        this.showBadge(this.overflowBadgeTarget)
        this.overflowBadgeCountTarget.textContent = this.showLessValue
      }
    }
    this.expandedValue = !this.expandedValue
  }

  hideOverflowBadge () {
    this.hideBadge(this.overflowBadgeTarget)
  }

  showOverflowBadge (displayedBadgesCount, previousElementHeight) {
    const hiddenBadgeCount = this.badgeTargets.length - displayedBadgesCount

    if (hiddenBadgeCount > 0) {
      const prefix = this.overflowPrefixValue || '+'
      const suffix = this.overflowSuffixValue || ''
      let countText = this.hideOverflowCountValue ? ' ' : ` ${hiddenBadgeCount} `

      this.overflowBadgeCountTarget.textContent = `${prefix}${countText}${suffix}`.trim()
      this.showBadge(this.overflowBadgeTarget)

      if (this.badgesContainerTarget.clientHeight !== previousElementHeight) {
        const lastNamedBadgeIndex = displayedBadgesCount - 1
        // Hide last two badges and re-add the badge counter again
        this.hideBadge(this.badgeTargets[lastNamedBadgeIndex])
        countText = this.hideOverflowCountValue ? ' ' : ` ${hiddenBadgeCount + 1} `
        this.overflowBadgeCountTarget.textContent = `${prefix}${countText}${suffix}`.trim()
      }
    }
  }
}
