import { Controller } from '@hotwired/stimulus'

export default class MessageToEventHandlerController extends Controller {
  connect () {
    window.addEventListener('message', this.handleMessage.bind(this))
  }

  // Message examples:

  // 1.) 'polywork:message-to-event:*' --> Translates a message to an event
  //  Use cases: When we want to dispatch an event from an incoming message

  //  We send a message to the window, which then dispatches the message as an event to any listeners.
  //  We drop all data except for the "name", which we use as the event name.
  //  Example:
  //    JS:   window.postMessage('polywork:message-to-event:profile-template-reveal-complete', '*') <--- This is in the current window
  //    HTML: data-action="message-to-event-handler:profile-template-reveal-complete@window->action-on-event#addClass" <--- This listener is within the current/desired window

  // 2.) 'polywork:message-to-parent:*' --> Relays a message to the current parent window
  //  Use cases: When we want to create an event from an iframe specifically for the parent window
  //  For example, sending a message from the profile template preview iframe to the main window, or sending a message from the sidebar iframe to the main window

  //  We send a message to the parent window, which then re-posts the message.
  //  This is done by targeting the parent window and translating the type from 'message-to-parent' to 'message-to-event'. The name remains the same.
  //  Once it is re-posted as a 'message-to-event', the steps described in item 1 are executed.

  //  Example:
  //    JS:   window.postMessage('polywork:message-to-parent:profile-template-reveal-complete', '*') <--- This is triggered within a child iframe
  //    HTML: data-action="message-to-event-handler:profile-template-reveal-complete@window->action-on-event#addClass" <--- This listener is within the parent iframe

  // 3.) 'polywork:message-to-top:*' --> Relays a message to the topmost window in the window hierarchy.
  //  Use cases: When we want to create an event from an iframe specifically for the highest parent parent window.
  //  *** It removes the need for knowing the current position in the iframe hierarchy. ***
  //  Generally speaking, it's likely better to use 'message-to-top' instead of 'message-to-parent' -- it allows the developer to be indifferent to the origin iframe of the message

  //  We send a message to the topmost window, which then re-posts the message.
  //  This is done by targeting the topmost window and translating the type from 'message-to-parent' to 'message-to-event'. The name remains the same.
  //  Once it is re-posted as a 'message-to-event', the steps described in item 1 are executed.

  //  Example:
  //    JS:   window.postMessage('polywork:message-to-top:profile-template-reveal-complete', '*') <--- This is triggered within any child iframe, which includes nested iframes
  //    HTML: data-action="message-to-event-handler:profile-template-reveal-complete@window->action-on-event#addClass" <--- This listener is within the topmost iframe

  // 4.) 'polywork:message-to-sidebar:*' --> Relays a message to the combined editor sidebar
  //  Use cases: When we want to create an event from an iframe or the main window specifically for the combined editor sidebar

  //  We send a message to the global window, which then re-posts the message to the combined editor sidebar iframe.
  //  This is done by targeting the sidebar iframe and translating the type from 'message-to-sidebar' to 'message-to-event'. The name remains the same.
  //  Once it is re-posted as a 'message-to-event', the steps described in item 1 are executed.

  //  Example:
  //    JS:   window.postMessage('polywork:message-to-sidebar:switch-to-templates-tab', '*') <--- This is triggered outside of the sidebar
  //    HTML: data-action="message-to-event-handler:switch-to-templates-tab@window->action-on-event#click" <--- This listener is within sidebar

  // 5.) 'polywork:message-to-preview:*' --> Relays a message to the profile template preview window
  //  Use cases: When we want to create an event from an iframe or the main window specifically for the profile template preview

  //  We send a message to the global window, which then re-posts the message to the profile template preview iframe.
  //  This is done by targeting the profile template preview iframe and translating the type from 'message-to-preview' to 'message-to-event'. The name remains the same.
  //  Once it is re-posted as a 'message-to-event', the steps described in item 1 are executed.

  //  Example:
  //    JS:   window.postMessage('polywork:message-to-preview:tooltip-tour-completed', '*') <--- This is triggered outside of the profile template preview iframe
  //    HTML: data-action="message-to-event-handler:tooltip-tour-completed@window->action-on-event#removeClass" <--- This listener is within profile template preview iframe

  handleMessage (event) {
    if (typeof event.data !== 'string' || !event.data.startsWith('polywork:')) return
    const parsedEventData = event.data.split(':')
    const type = parsedEventData[1]
    const name = parsedEventData[2]

    switch (type) {
      case 'message-to-event': {
        this.dispatch(name)
        break
      }
      case 'message-to-parent': {
        window.parent.postMessage(`polywork:message-to-event:${name}`, '*')
        break
      }
      case 'message-to-top': {
        window.top.postMessage(`polywork:message-to-event:${name}`, '*')
        break
      }
      case 'message-to-sidebar': {
        const sidebarEditorIframe = window.top.document.querySelector('#identity-sidebar-editor-iframe')
        sidebarEditorIframe.contentWindow.postMessage(`polywork:message-to-event:${name}`, '*')
        break
      }
      case 'message-to-preview': {
        const previewIframe = window.top.document.querySelector('#identity-template-profile-preview-container')
        previewIframe.contentWindow.postMessage(`polywork:message-to-event:${name}`, '*')
        break
      }
    }
  }
}
