import BlockEmbed from 'quill/blots/embed'

class UrlEmbed extends BlockEmbed {
  static create (value) {
    const node = super.create(value)

    node.setAttribute('data-unfurler-url-value', value)
    node.setAttribute('data-controller', 'unfurler close-embed')
    node.insertAdjacentHTML('beforeend', '<p><br/></p>')

    return node
  }

  static value (node) {
    return node.getAttribute('data-unfurler-url-value')
  }
}

UrlEmbed.blotName = 'urlEmbed'
UrlEmbed.className = 'url-embed'
UrlEmbed.tagName = 'div'

export default UrlEmbed
