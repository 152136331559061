import { Controller } from '@hotwired/stimulus'

export default class IFrameLoaderController extends Controller {
  static targets = ['loadingIcon']
  static values = { srcs: [String], iframeWrapperId: String, iframeClasses: String, newIframeId: String, forceRefresh: Boolean, bodyBackgroundColor: String }
  static classes = ['loading']

  connect () {
    if (this.newIframePath || this.forceRefreshValue) {
      this.srcsValue.map((src, idx) => (
        setTimeout(() => {
          this.loadNewIframe(src)
          this.setLoadingState()
        }, 2000 * idx)
      ))
    }
  }

  get newIframePath () {
    return this.srcsValue[0] !== this.existingIframeSrc
  }

  get existingIframeSrc () {
    const firstIframe = $(`#${this.iframeWrapperIdValue} > iframe:last`).first()
    return firstIframe !== null ? firstIframe.attr('src') : ''
  }

  get hasTwoOrMoreIframes () {
    const iframeCount = $(`#${this.iframeWrapperIdValue} > iframe`).length
    return iframeCount >= 2
  }

  setLoadingState () {
    const iframeWrapper = $(`#${this.iframeWrapperIdValue}`)
    iframeWrapper.addClass(this.loadingClass)
  }

  removeRedundantPreviews () {
    const previewsToRemove = $(`#${this.iframeWrapperIdValue} > iframe.--animated-profile-preview-iframe`)
    previewsToRemove.each((index, preview) => preview.remove())
  }

  removeRedundantIframes () {
    const iframesToRemove = $(`#${this.iframeWrapperIdValue} > iframe.d-none`)
    iframesToRemove.each((index, iframe) => iframe.remove())
  }

  loadNewIframe (src) {
    // If multiple iframes are loading due to multiple clicks. Remove the
    // now redundant iframe and only load the latest
    if (this.hasTwoOrMoreIframes) { this.removeRedundantIframes() }

    const newIframe = $('<iframe>', {
      src,
      class: `d-none ${this.iframeClassesValue}`,
      id: this.newIframeIdValue
    })

    const iframeWrapper = $(`#${this.iframeWrapperIdValue}`)
    iframeWrapper.append(newIframe)
    newIframe.on('load', () => {
      iframeWrapper.find('>:first-child').remove()
      newIframe.removeClass('d-none')
      if (this.hasLoadingIconTarget) { this.loadingIconTarget.classList.add('d-none') }
      iframeWrapper.removeClass(this.loadingClass)
      newIframe.attr('style', `background-color: ${this.bodyBackgroundColorValue}`)
      document.body.style.backgroundColor = this.bodyBackgroundColorValue
      this.removeRedundantPreviews()
    })
  }
}
