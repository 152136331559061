import { Controller } from '@hotwired/stimulus'
import compact from 'lodash/compact'
import { useMutation } from 'stimulus-use'

export default class OnboardingHeaderController extends Controller {
  static targets = [
    'secondaryText',
    'positionTitleInput',
    'positionOrganisationInput',
    'selectedBadgeNames'
  ]

  static values = {
    positionTitle: String,
    positionOrganisation: String
  }

  connect () {
    useMutation(this, {
      element: this.selectedBadgeNamesTarget,
      childList: true
    })
  }

  positionTitleUpdate () {
    this.positionTitleValue = this.positionTitleInputTarget.value
    this.updateSecondaryTextForPosition()
  }

  positionOrganisationUpdate () {
    this.positionOrganisationValue = this.positionOrganisationInputTarget.children[0].innerHTML
    this.updateSecondaryTextForPosition()
  }

  updateSecondaryTextForPosition () {
    this.secondaryTextTarget.innerHTML = compact([this.positionTitleValue, this.positionOrganisationValue]).join(' at ')
  }

  get maxBadgesToDisplay () {
    if (this.positionTitleValue.length === 0) {
      return 3
    } else {
      return 2
    }
  }

  get badgeNamesFromSelectedBadgeNamesTarget () {
    const limit = this.maxBadgesToDisplay
    const badgeNames = Array.from(this.selectedBadgeNamesTarget.children).map(child => child.innerHTML)
    if (badgeNames.length > limit) {
      return (badgeNames.slice(0, limit).join(', ') + `, +${badgeNames.length - limit}`)
    } else {
      return badgeNames.join(', ')
    }
  }

  updateSecondaryTextForBadges () {
    this.secondaryTextTarget.innerHTML = compact([this.positionTitleValue, this.badgeNamesFromSelectedBadgeNamesTarget]).join(', ')
  }

  mutate (entries) {
    this.updateSecondaryTextForBadges()
  }
}
