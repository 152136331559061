import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import { get } from '@rails/request.js'
import { keyCodes } from '../utils/keyboard'

export default class InlineSearchController extends Controller {
  static targets = ['input', 'results']

  static values = {
    currentQuery: String,
    searchPath: String,
    queryTerm: String
  }

  handleKeyPress (event) {
    if ([keyCodes.ESCAPE, keyCodes.ENTER].includes(event.keyCode)) {
      event.stopPropagation()
      event.preventDefault()
      this.inputTarget.blur()
    } else {
      this.fetchResultsWithDebounce()
    }
  }

  fetchResultsWithDebounce = debounce(this.fetchResults, 150)

  async fetchResults () {
    const query = this.inputTarget.value
    if (query.length >= 2) {
      if (this.currentQueryValue !== query) {
        this.currentQueryValue = query
        this.performAPIRequest()
      }
    } else if (this.currentQueryValue.length > query.length) {
      this.currentQueryValue = ''
      this.performAPIRequest()
    }
  }

  async performAPIRequest () {
    const url = this.buildUrl()
    await get(url, { responseKind: 'turbo-stream' })
  }

  buildUrl () {
    return `${this.searchPathValue}?${this.queryTermValue}=${this.currentQueryValue}`
  }
}
