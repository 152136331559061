import { Controller } from '@hotwired/stimulus'

export default class NewRowController extends Controller {
  static targets = [
    'container',
    'template'
  ]

  add (event) {
    const row = this.templateTarget.content.cloneNode(true)
    this.containerTarget.appendChild(row)
    event.preventDefault()
  }
}
