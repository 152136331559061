import { Controller } from '@hotwired/stimulus'

export default class recaptchaController extends Controller {
  static targets = ['form', 'submit']
  static values = {
    siteKey: String,
    action: String
  }

  submitForm (e) {
    e.preventDefault()

    const recaptchaTokenPromise = new Promise((resolve) => {
      /* eslint-disable no-undef */
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute(this.siteKeyValue, { action: this.actionValue })
        resolve(token)
      /* eslint-enable no-undef */
      })
    })

    recaptchaTokenPromise.then((token) => {
      Rails.fire(this.formTarget, 'submit')
      document.addEventListener('turbo:submit-start', (event) => {
        event.detail.formSubmission.fetchRequest.headers['recaptcha-token'] = token
      })
    })
  }
}
