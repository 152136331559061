import { StradaController } from './strada_controller'

export default class extends StradaController {
  static component = 'remote-image-viewer'
  static targets = ['image']

  show (event) {
    if (!this.enabled) return

    event.stopImmediatePropagation()
    event.preventDefault()

    const index = parseInt(event.target.getAttribute('data-slide-to')) || 0
    const urls = this.urls

    this.send('connect', { index, urls })
  }

  get urls () {
    return this.imageTargets.map(target => target.getAttribute('data-image-url'))
  }
}
