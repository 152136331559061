import { Controller } from '@hotwired/stimulus'

export default class ToggleProfileSectionController extends Controller {
  static targets = ['toggleContent', 'hiddenState', 'previewButton']

  static classes = ['show', 'hide']

  initialize () {
    if (this.hasToggleContentTarget) {
      if (this.toggleContentTarget.dataset.contentVisible === 'true') {
        this.showContent()
      } else {
        this.hideContent()
      }
    }
  }

  showContent () {
    if (this.contentBeingPreviewed()) {
      this.collapseContent()
    }

    this.toggleContentTarget.classList.add(this.showClass)
    this.hiddenStateTarget.classList.add(this.hideClass)
    this.hiddenStateTarget.hidden = true
  }

  hideContent () {
    this.toggleContentTarget.classList.remove(this.showClass)
    this.hiddenStateTarget.classList.remove(this.hideClass)
    this.hiddenStateTarget.hidden = false
  }

  collapseContent () {
    this.previewButtonTarget.setAttribute('aria-expanded', false)
    $(this.toggleContentTarget).collapse({ toggle: false })
  }

  contentBeingPreviewed () {
    return this.previewButtonTarget.getAttribute('aria-expanded') === 'true'
  }
}
