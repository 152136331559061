import { Component } from '../../vendors/strada'

export default class extends Component {
  static component = 'boost'
  static classes = ['boosted']

  toggle () {
    if (!this.enabled) { return }
    this.send('toggle', { activated: this.isBoosted })
  }

  get isBoosted () {
    return this.element.classList.contains(this.boostedClass)
  }
}
