import { Controller } from '@hotwired/stimulus'

export default class ActivitiesCollapsedListController extends Controller {
  static targets = ['activity', 'overflowTile', 'overflowTileValue']
  static classes = ['hiddenActivity']
  static values = {
    maxActivityCount: Number
  }

  connect () {
    this.redrawActivities()
  }

  get overflowTileVisible () {
    return (
      this.hasOverflowTileTarget &&
      !this.overflowTileTarget.classList.contains(this.hiddenActivityClass)
    )
  }

  redrawActivities () {
    if (this.maxActivityCountValue > 0) {
      this.hideActivityTargets()
    }
  }

  hideActivityTargets () {
    const activitiesToHide = this.activityTargets.slice(this.maxActivityCountValue)
    activitiesToHide.forEach(activityTarget => this.hideActivity(activityTarget))
  }

  showActivityTargets () {
    this.activityTargets.forEach(activityTarget => activityTarget.classList.remove(this.hiddenActivityClass))
  }

  hideActivity (activityTarget) {
    activityTarget.classList.add(this.hiddenActivityClass)
  }

  dismissActivity (e) {
    const activityTarget = e.target.closest(
      "[data-activities-collapsed-list-target='activity']"
    )
    activityTarget.remove()
    this.showActivityTargets()

    if (this.overflowTileVisible) this.redrawActivities()

    if (this.activityTargets.length > 0) {
      this.updateOverFlowTile()
    } else {
      this.hidePanel()
    }
  }

  updateOverFlowTile () {
    if (!this.hasOverflowTileValueTarget) return

    const hiddenTiles =
      this.activityTargets.length - this.maxActivityCountValue
    if (hiddenTiles > 0) {
      this.overflowTileValueTarget.innerHTML = `+ ${hiddenTiles}`
    } else {
      this.hideActivity(this.overflowTileTarget)
    }
  }

  hidePanel () {
    this.element.remove()
  }

  displayActivities () {
    this.showActivityTargets()
    this.hideActivity(this.overflowTileTarget)
  }
}
