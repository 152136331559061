import { Controller } from '@hotwired/stimulus'

export default class SwitchContentController extends Controller {
  static targets = ['initialContent', 'hiddenContent', 'toggle']

  toggleContent () {
    if (this.toggleTarget.checked) {
      this.initialContentTarget.classList.add('d-none')
      this.hiddenContentTarget.classList.remove('d-none')
    } else {
      this.hiddenContentTarget.classList.add('d-none')
      this.initialContentTarget.classList.remove('d-none')
    }
  }
}
