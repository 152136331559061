import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class LinkedinAccountGeneratorController extends Controller {
  static targets = ['textContainer']
  static values = {
    steps: Array,
    statusUrl: String
  }

  connect () {
    this.stepsLength = this.stepsValue.length
    this.stepsValue.forEach(function (textContent, index) {
      setTimeout(this.updateLoadingMessage.bind(this), 4000 * (index + 1), textContent)
      if (this.stepsLength === index + 1) {
        setTimeout(this.fetchSiteStatus.bind(this), 4000 * (index + 2))
      }
    }, this)
  }

  updateLoadingMessage (textContent) {
    this.textContainerTarget.textContent = textContent
  }

  fetchSiteStatus () {
    post(this.statusUrlValue, { responseKind: 'turbo-stream' })
  }
}
