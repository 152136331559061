import { ImageDrop as BaseImageDrop } from 'quill-image-drop-module'

class ImageDrop extends BaseImageDrop {
  constructor (quill, options) {
    super(quill, options)
    this.quill.root.removeEventListener('drop', this.handleDrop, false)
  }

  insert (imageBase64) {
    this.quill.options.dynamicContentEditorController.insertBase64Image(imageBase64)
  }
}

export default ImageDrop
