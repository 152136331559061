import { Controller } from '@hotwired/stimulus'

export default class UserProfileListItemController extends Controller {
  static classes = ['transparentHover']

  initialize () {
    this.element.querySelectorAll('a').forEach(anchor => {
      anchor.addEventListener('mouseenter', () => {
        this.element.classList.remove(this.transparentHoverClass)
      })

      anchor.addEventListener('mouseleave', () => {
        this.element.classList.add(this.transparentHoverClass)
      })
    })
  }
}
