import { Controller } from '@hotwired/stimulus'
import { every } from 'lodash'

export default class FormRequiredFieldsController extends Controller {
  static targets = ['field', 'submitButton']

  initialize () {
    this.setSubmitButtonState()
  }

  setSubmitButtonState () {
    if (every(this.fieldTargets, this.fieldHasValue)) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  fieldHasValue (field) {
    return field.value.length > 0
  }

  enableSubmitButton () {
    this.submitButtonTarget.disabled = false
  }

  disableSubmitButton () {
    this.submitButtonTarget.disabled = true
  }
}
