import { Controller } from '@hotwired/stimulus'

const POSITIONS_KEY = 'polywork_new_education_position_form_values'
export default class EducationPositionController extends Controller {
  static targets = ['endDate', 'startDate', 'degree', 'fieldOfStudy']

  static values = {
    restoreForm: Boolean
  }

  connect () {
    if (this.restoreFormValue) {
      this.setValuesFromLocalStorage()
    }
  }

  setValuesFromLocalStorage () {
    const existingFormValues = JSON.parse(window.localStorage.getItem(POSITIONS_KEY)) || {}

    if (Object.keys(existingFormValues).length === 0) return

    this.degreeTarget.value = existingFormValues.degree
    this.fieldOfStudyTarget.value = existingFormValues.fieldOfStudy
    this.startDateTarget.value = existingFormValues.startDate
    this.endDateTarget.value = existingFormValues.endDate
  }

  saveParamsToLocalStorage (e) {
    const formValues = {
      degree: this.degreeTarget.value,
      fieldOfStudy: this.fieldOfStudyTarget.value,
      startDate: this.startDateTarget.value,
      endDate: this.endDateTarget.value
    }

    window.localStorage.setItem(POSITIONS_KEY, JSON.stringify(formValues))
  }
}
