import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'
import remove from 'lodash/remove'

export default class DialogPlacementController extends Controller {
  static values = {
    iframeWindow: String,
    insertWithinIframe: Boolean,
    anchorPoint: String,
    position: String
  }

  connect () {
    if (this.hasAnchorPointValue) {
      if (this.hasIframeWindowValue) {
        this.displayPopoverWithIframe()
      } else {
        const anchorElement = document.querySelector(this.anchorPointValue)
        this.displayPopover(anchorElement)
      }
    }
  }

  displayPopoverWithIframe () {
    const desiredDocument = window.top.document.querySelector(this.iframeWindowValue).contentDocument
    const anchorElement = desiredDocument.querySelector(this.anchorPointValue)

    if (this.insertWithinIframeValue) {
      this.insertElementAndScrollToIt(anchorElement)
    } else {
      // Nudge the scroll so the iframe window becomes active
      setTimeout(() => { anchorElement.ownerDocument.defaultView.scrollBy(0, 1) }, 200)
    }

    this.displayPopover(anchorElement)
  }

  displayPopover (target) {
    createPopper(target, this.element, {
      placement: this.positionValue,
      modifiers: [
        {
          name: 'offset',
          options: { offset: [this.offsetY(), 24] }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['right', 'left', 'top', 'bottom']
          }
        },
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'document',
            boundary: 'document'
          }
        }
      ]
    })
  }

  insertElementAndScrollToIt (anchorElement) {
    const controllers = this.element.dataset.controller.split(' ')
    remove(controllers, function (controller) {
      return controller === 'dialog-placement'
    })
    this.element.dataset.controller = controllers.join(' ')
    anchorElement.insertAdjacentElement('afterend', this.element)
    anchorElement.ownerDocument.defaultView.scrollTo({ top: anchorElement, behavior: 'smooth' })
  }

  offsetY () {
    if (this.hasIframeWindowValue && !this.insertWithinIframeValue) {
      return 60
    } else {
      return 0
    }
  }
}
