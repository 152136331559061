import { Turbo } from '@hotwired/turbo-rails'
import debounce from 'lodash/debounce'
import { get } from '@rails/request.js'
import { originatesFrom } from '../utils/events'
import { keyCodes } from '../utils/keyboard'
import { StradaController } from './strada/strada_controller'

const originatesFromDesktopInput = originatesFrom('.search-input')

export default class SearchBarController extends StradaController {
  static component = 'search-bar'
  static targets = ['input', 'results']
  static classes = ['showResults', 'showSearch']

  static values = {
    currentQuery: String,
    isFullPageSearch: Boolean,
    showWebSearchBar: Boolean
  }

  connect () {
    if (this.hasInputTarget) {
      if (this.showWebSearchBarValue) { this.connectComponent() }

      if (!this.isFullPageSearchValue) {
        document.addEventListener('click', this.handleDesktopClicks, false)
      }
    }
  }

  connectComponent () {
    super.connect()
    if (!this.enabled) return

    this.hideWebSearchBar()

    this.send('connect', {}, message => {
      this.inputTarget.value = message.data.query
      this.fetchResultsWithDebounce()
    })
  }

  handleKeyPress (event) {
    if (
      ![keyCodes.ENTER, keyCodes.UP_ARROW, keyCodes.DOWN_ARROW].includes(
        event.keyCode
      )
    ) {
      if (keyCodes.ESCAPE === event.keyCode && !this.isFullPageSearchValue) {
        this.hideResults()
      } else {
        this.fetchResultsWithDebounce()
      }
    } else if (keyCodes.ENTER === event.keyCode) {
      this.inputTarget.blur()
      if (!this.isFullPageSearchValue) {
        this.navigateToSearch(event)
      }
    }
  }

  fetchResultsWithDebounce = debounce(this.fetchResults, 500)

  async fetchResults () {
    const query = this.inputTarget.value
    if (query.length >= 2) {
      if (this.currentQueryValue !== query) {
        this.currentQueryValue = query
        this.performAPIRequest()
      }
    } else if (!this.isFullPageSearchValue || (this.currentQueryValue.length > query.length)) {
      // Clear the search page and rerender the search template
      this.currentQueryValue = ''
      this.performAPIRequest()
    } else {
      this.hideResults()
    }
  }

  async performAPIRequest () {
    const url = this.buildUrl()
    const response = await get(url, { responseKind: 'turbo-stream' })
    if (response.ok) {
      this.showResults()
    }
  }

  focus (event) {
    event.preventDefault()
    this.showResults()
    this.inputTarget.focus()
  }

  buildUrl () {
    let url = ''
    if (this.isFullPageSearchValue) {
      url = `/search?query=${this.currentQueryValue}`
    } else {
      url = `/search/search_bar?query=${this.currentQueryValue}`
    }
    return url
  }

  navigateToSearch (event) {
    event.preventDefault()
    const url = `/search?query=${this.inputTarget.value}`

    Turbo.visit(url)
  }

  showResults () {
    if (this.hasResultsTarget) {
      this.resultsTarget.classList.add(this.showResultsClass)
    }
  }

  hideResults () {
    if (this.hasResultsTarget) {
      this.resultsTarget.classList.remove(this.showResultsClass)
    }
  }

  handleDesktopClicks = event => {
    if (
      (this.resultsTarget.contains(event.target) || (originatesFromDesktopInput(event) && this.inputTarget.value.length > 2)) || (document.activeElement === this.inputTarget)
    ) {
      this.showResults()
    } else {
      this.hideResults()
    }
  }

  hideWebSearchBar () {
    this.element.classList.add('d-none')
  }
}
