import { Controller } from '@hotwired/stimulus'
import 'bootstrap-datepicker'
import dayJS from 'dayjs'
import { originatesFrom } from '../utils/events'

const originatesFromDatePicker = originatesFrom('.dropdown-highlight-date-picker')
const originatesFromDropDown = originatesFrom('.dropdown-item')

const HIDDEN_CLASS = 'd-none'

export default class HighlightTypeController extends Controller {
  static targets = ['datePicker', 'endDate', 'submitForm', 'leftChevronSvgTag', 'rightChevronSvgTag']

  connect () {
    this.initializeDatePicker()
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  // eslint-disable-next-line class-methods-use-this
  initializeDatePicker () {
    $('#highlight-date').datepicker({
      format: 'M d, yyyy',
      endDate: '0d',
      startDate: '1 1, 1900',
      startView: 'year',
      minViewMode: 0,
      maxViewMode: 2,
      orientation: 'bottom left',
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => {
        this.setEndDateValue(e)
        this.submitForm()
      })
  }

  // eslint-disable-next-line class-methods-use-this
  preventModalPropagation (e) {
    e.stopPropagation()
  }

  showDatePicker () {
    this.datePickerTarget.classList.remove(HIDDEN_CLASS)
  }

  hideDatePicker () {
    this.datePickerTarget.classList.add(HIDDEN_CLASS)
  }

  datePickerShown () {
    return !this.datePickerTarget.classList.contains(HIDDEN_CLASS)
  }

  handleOutsideClick = event => {
    if (
      this.datePickerShown() &&
      !originatesFromDatePicker(event) &&
      !originatesFromDropDown(event)) {
      this.hideDatePicker()
    }
  }

  setEndDateValue (e) {
    this.endDateTarget.value = dayJS(e.date).format('DD-MM-YYYY')
  }

  clearEndDateValue () {
    this.endDateTarget.value = ''
  }

  submitForm () {
    this.submitFormTarget.click()
  }

  setTypeToRealTimeHighlight () {
    this.clearEndDateValue()
  }
}
