import { Controller } from '@hotwired/stimulus'

export default class IframeEventEmitter extends Controller {
  static values = {
    event: String,
    destination: String
  }

  fireEvent (event) {
    event.preventDefault()
    event.stopPropagation()

    if (this.destinationValue) {
      window.parent.postMessage(`polywork-iframe:${this.eventValue}`, this.destinationValue)
    } else {
      window.parent.postMessage(`polywork-iframe:${this.eventValue}`, '*')
    }
  }
}
