import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class CombinedEditorPreviewController extends Controller {
  static targets = [
    'activePresetProfileTemplate',
    'selectedPresetProfileTemplate',
    'activeTemplate',
    'selectedTemplate',
    'activeTypography',
    'selectedTypography',
    'activeColor',
    'selectedColor',
    'activeParams'
  ]

  static values = {
    previewUrl: String,
    updateUrl: String,
    combinedEditorModalUrl: String,
    unsavedChangesUrl: String,
    randomizeUrl: String
  }

  static classes = ['dirtyForm']

  initialize () {
    this.boundCheckForUnsavedChanges = this.checkForUnsavedChanges.bind(this)
  }

  connect () {
    useMutation(this, { element: this.activeParamsTarget, childList: true })
    document.addEventListener('turbo:before-fetch-request', this.boundCheckForUnsavedChanges)
  }

  disconnect () {
    document.removeEventListener('turbo:before-fetch-request', this.boundCheckForUnsavedChanges)
  }

  mutate (entries) {
    this.toggleDirtyClass()
  }

  launchUnsavedChangesModal (url) {
    const modalUrl = new URL(this.combinedEditorModalUrlValue)
    const unsavedChangesUrl = new URL(this.unsavedChangesUrlValue)
    if (url) {
      unsavedChangesUrl.searchParams.append('redirect_endpoint', url)
    }

    modalUrl.searchParams.append('modal_path', unsavedChangesUrl)
    this.element.setAttribute('action', modalUrl)
    Rails.fire(this.element, 'submit')
    this.element.setAttribute('action', this.updateUrlValue)
  }

  submit () {
    Rails.fire(this.element, 'submit')
  }

  viewPreview () {
    this.element.setAttribute('action', this.previewUrlValue)
    this.element.setAttribute('method', 'patch')
    Rails.fire(this.element, 'submit')
    this.element.setAttribute('action', this.updateUrlValue)
    this.element.setAttribute('method', 'post')

    this.toggleDirtyClass()
  }

  checkForUnsavedChanges (event) {
    let fetchUrl
    if (event.detail.url) {
      const fetchFullUrl = new URL(event.detail.url)
      fetchUrl = `${fetchFullUrl.origin}${fetchFullUrl.pathname}`
      const excludedUrls = [this.previewUrlValue, this.updateUrlValue, this.combinedEditorModalUrlValue, this.randomizeUrlValue]

      if (excludedUrls.includes(fetchUrl)) return
    }

    if (this.formIsDirty) {
      event.stopPropagation()
      event.preventDefault()
      this.launchUnsavedChangesModal(fetchUrl)
    }
  }

  get selectedCheckedTemplate () {
    return this.selectedTemplateTargets.find(selectedTemplateTarget => {
      return selectedTemplateTarget.checked === true
    })
  }

  get initialActiveTemplate () {
    return this.selectedTemplateTargets.find(selectedTemplateTarget => {
      return selectedTemplateTarget.value === this.activeTemplateTarget.value
    })
  }

  get hasDirtySelectedTemplate () {
    return this.activeTemplateTarget.value !== this.selectedCheckedTemplate.value
  }

  get selectedCheckedTypography () {
    return this.selectedTypographyTargets.find(selectedTypographyTarget => {
      return selectedTypographyTarget.checked === true
    })
  }

  get initialActiveTypography () {
    return this.selectedTypographyTargets.find(selectedTypographyTarget => {
      return selectedTypographyTarget.value === this.activeTypographyTarget.value
    })
  }

  get hasDirtySelectedTypography () {
    return this.activeTypographyTarget.value !== this.selectedCheckedTypography.value
  }

  get selectedCheckedPresetProfileTemplate () {
    return this.selectedPresetProfileTemplateTargets.find(selectedPresetProfileTemplateTarget => {
      return selectedPresetProfileTemplateTarget.checked === true
    })
  }

  get intialActivePresetProfileTemplate () {
    return this.selectedPresetProfileTemplateTargets.find(selectedPresetProfileTemplateTarget => {
      return selectedPresetProfileTemplateTarget.value === this.activeStylePresetTarget.value
    })
  }

  get hasDirtySelectedPresetProfileTemplate () {
    if (this.hasActiveStylePresetTarget && this.selectedCheckedPresetProfileTemplate) {
      return this.activeStylePresetTarget.value !== this.selectedCheckedPresetProfileTemplate.value
    } else {
      return false
    }
  }

  get selectedCheckedColor () {
    return this.selectedColorTargets.find(selectedColorTarget => {
      return selectedColorTarget.checked === true
    })
  }

  get initialActiveColor () {
    return this.selectedColorTargets.find(selectedColorTarget => {
      return selectedColorTarget.value === this.activeColorTarget.value
    })
  }

  get hasDirtySelectedColor () {
    return this.activeColorTarget.value !== this.selectedCheckedColor.value
  }

  get formIsDirty () {
    return this.hasDirtySelectedPresetProfileTemplate || this.hasDirtySelectedTemplate || this.hasDirtySelectedTypography || this.hasDirtySelectedColor
  }

  reset () {
    // Reset all form values
    if (this.hasDirtySelectedPresetProfileTemplate) {
      this.intialActivePresetProfileTemplate.checked = true
    }

    if (this.hasDirtySelectedTemplate) {
      this.initialActiveTemplate.checked = true
    }

    if (this.hasDirtySelectedTypography) {
      this.initialActiveTypography.checked = true
    }

    if (this.hasDirtySelectedColor) {
      this.initialActiveColor.checked = true
    }

    this.viewPreview()
  }

  toggleDirtyClass () {
    if (this.formIsDirty) {
      window.postMessage('polywork:message-to-top:enable-save-changes-button', '*')
      window.postMessage('polywork:message-to-event:enable-save-changes-button', '*')
    } else {
      window.postMessage('polywork:message-to-top:disable-save-changes-button', '*')
      window.postMessage('polywork:message-to-event:disable-save-changes-button', '*')
    }
  }
}
