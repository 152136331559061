import { Controller } from '@hotwired/stimulus'

export default class SidebarMobileDevicesController extends Controller {
  static classes = ['hidden']
  static values = {
    breakPoint: Number,
    visibleOnMobile: Boolean,
    visibleOnDesktop: Boolean
  }

  connect () {
    this.setVisibility()
    window.addEventListener('resize', this.setVisibility.bind(this))
  }

  setVisibility () {
    const screenWidth = window.parent.document.body.clientWidth

    if (screenWidth >= this.breakPointValue) {
      if (this.visibleOnDesktopValue) {
        this.element.classList.remove(this.hiddenClass)
      } else {
        this.element.classList.add(this.hiddenClass)
      }
    } else {
      if (this.visibleOnMobileValue) {
        this.element.classList.remove(this.hiddenClass)
      } else {
        this.element.classList.add(this.hiddenClass)
      }
    }
  }
}
