import { Controller } from '@hotwired/stimulus'

export default class CarouselUpdateController extends Controller {
  static values = {
    slidesToRemove: Array,
    identifier: String
  }

  connect () {
    this.slickCarousel = $(`#${this.identifierValue}`)
    this.removeSlides()
    this.sendCarouselUpdatedEvent()
  }

  removeSlides () {
    this.slidesToRemoveValue.forEach(cardId => {
      const parentSlide = $(`#${cardId}`).closest('.slick-slide')
      const slideIndex = parentSlide.data('slickIndex')
      this.slickCarousel.slick('slickRemove', slideIndex)
      this.reindexSlides()
    })
  }

  reindexSlides () {
    this.slickCarousel.slick('getSlick').$slides.each((index, slide) => {
      slide.setAttribute('data-slick-index', index)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  sendCarouselUpdatedEvent () {
    const event = new CustomEvent('carousel:updated', {
      bubbles: true,
      cancelable: true
    })
    document.dispatchEvent(event)
  }
}
