import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

const VISIBLE_CLASS = 'd-block'
const HIDDEN_CLASS = 'd-none'

export default class EmploymentPositionToggleFullFormController extends Controller {
  static targets = ['employmentTypeDropdown', 'dateInputs', 'submitButton', 'nextButton', 'studentCheckbox']
  static values = {
    newPath: String
  }

  // TODO: Make showFullForm & toggleForm 2 different controllers
  showFullForm () {
    this.employmentTypeDropdownTarget.classList.remove(HIDDEN_CLASS)
    this.employmentTypeDropdownTarget.classList.add(VISIBLE_CLASS)
    this.dateInputsTarget.classList.add(VISIBLE_CLASS)
    this.submitButtonTarget.classList.add(VISIBLE_CLASS)
    this.studentCheckboxTarget.classList.remove(VISIBLE_CLASS)
    this.studentCheckboxTarget.classList.add(HIDDEN_CLASS)
    this.nextButtonTarget.classList.remove(VISIBLE_CLASS)
    this.nextButtonTarget.classList.add(HIDDEN_CLASS)
  }

  toggleForm () {
    get(this.newPathValue, { responseKind: 'turbo-stream', headers: { 'Turbo-Frame': 'modal_content' } })
  }
}
