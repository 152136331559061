import { Controller } from '@hotwired/stimulus'

export default class PostTypeController extends Controller {
  static targets = [
    'gigFormTemplate',
    'highlightFormTemplate',
    'collaborationStatusFormTemplate',
    'subjectFormContainer',
    'removeSubjectFormButton',
    'confirmationForm',
    'form',
    'titleInput',
    'titleInputWrapper',
    'descriptionInput',
    'typeSwitcherContainer'
  ]

  static classes = [
    'hidden',
    'flex',
    'formWithoutSubjectForm',
    'formWithGigForm',
    'formWithHighlightForm',
    'formWithCollaborationStatusForm'
  ]

  static values = {
    desiredSubjectForm: String,
    postDescriptionPlaceholder: String,
    gigDescriptionPlaceholder: String,
    highlightDescriptionPlaceholder: String,
    collaborationStatusDescriptionPlaceholder: String,
    titleRequiredPlaceholder: String,
    titleNotRequiredPlaceholder: String,
    showConfirmationWhenSwitchingTypes: Boolean,
    changeClassesWhenSwitchingTypes: Boolean,
    changeDescriptionPlaceholderWhenSwitchingTypes: Boolean
  }

  hideConfirmation () {
    this.confirmationFormTarget.classList.add(this.hiddenClass)
    this.confirmationFormTarget.classList.remove(this.flexClass)
    this.removeSubjectFormButtonTarget.classList.remove(this.hiddenClass)
    this.resizeSubjectFormContainerBackToOriginal()
  }

  showConfirmation (subjectName) {
    this.desiredSubjectFormValue = subjectName
    this.confirmationFormTarget.classList.remove(this.hiddenClass)
    this.confirmationFormTarget.classList.add(this.flexClass)
    this.removeSubjectFormButtonTarget.classList.add(this.hiddenClass)
    this.resizeSubjectFormContainerToConfirmationForm()
  }

  continueToNewType () {
    const formToShow = this.desiredSubjectFormValue
    this.desiredSubjectFormValue = null
    switch (formToShow) {
      case 'gig': {
        this.showGigForm()
        break
      }
      case 'highlight': {
        this.showHighlightForm()
        break
      }
      case 'collaboration_status': {
        this.showCollaborationStatusForm()
        break
      }
      default: {
        this.revertToPost()
        break
      }
    }
  }

  get shouldShowConfirmation () {
    return this.showConfirmationWhenSwitchingTypesValue && this.subjectFormPresent && this.confirmationModalHidden
  }

  get confirmationModalHidden () {
    return this.confirmationFormTarget.classList.contains(this.hiddenClass)
  }

  get subjectFormPresent () {
    return !this.formTarget.classList.contains(
      this.formWithoutSubjectFormClass
    )
  }

  revertToPost () {
    this.clearErrorMessages()
    this.updateDescriptionPlaceholderText(this.postDescriptionPlaceholderValue)
    this.updateTitlePlaceholderText(this.titleNotRequiredPlaceholderValue)
    this.toggleOnWithoutSubjectFormClass()
    this.resizeSubjectFormContainerBackToOriginal()
    this.removeSubjectForm()
    this.showTypeSwitcherContainer()
  }

  showGigForm () {
    if (this.shouldShowConfirmation) {
      this.showConfirmation('gig')
    } else {
      this.clearErrorMessages()
      this.updateDescriptionPlaceholderText(this.gigDescriptionPlaceholderValue)
      this.updateTitlePlaceholderText(this.titleRequiredPlaceholderValue)
      this.toggleOnGigFormClass()
      this.resizeSubjectFormContainerBackToOriginal()
      this.replaceSubjectFormWithGigForm()
      this.hideTypeSwitcherContainer()
    }
  }

  showHighlightForm () {
    if (this.shouldShowConfirmation) {
      this.showConfirmation('highlight')
    } else {
      this.clearErrorMessages()
      this.updateDescriptionPlaceholderText(this.highlightDescriptionPlaceholderValue)
      this.updateTitlePlaceholderText(this.titleNotRequiredPlaceholderValue)
      this.toggleOnHighlightFormClass()
      this.resizeSubjectFormContainerBackToOriginal()
      this.replaceSubjectFormWithHighlightForm()
      this.hideTypeSwitcherContainer()
    }
  }

  showCollaborationStatusForm () {
    if (this.shouldShowConfirmation) {
      this.showConfirmation('collaboration_status')
    } else {
      this.clearErrorMessages()
      this.updateDescriptionPlaceholderText(this.collaborationStatusDescriptionPlaceholderValue)
      this.updateTitlePlaceholderText(this.titleRequiredPlaceholderValue)
      this.toggleOnCollaborationStatusFormClass()
      this.resizeSubjectFormContainerBackToOriginal()
      this.replaceSubjectFormWithCollaborationStatusForm()
      this.hideTypeSwitcherContainer()
    }
  }

  removeSubjectForm () {
    this.subjectFormContainerTarget.innerHTML = ''
  }

  replaceSubjectFormWithGigForm () {
    this.subjectFormContainerTarget.innerHTML = this.gigFormTemplateTarget.innerHTML
  }

  replaceSubjectFormWithHighlightForm () {
    this.subjectFormContainerTarget.innerHTML = this.highlightFormTemplateTarget.innerHTML
  }

  replaceSubjectFormWithCollaborationStatusForm () {
    this.subjectFormContainerTarget.innerHTML = this.collaborationStatusFormTemplateTarget.innerHTML
  }

  removeTypeSpecificFormClasses () {
    this.formTarget.classList.remove(this.formWithoutSubjectFormClass)
    this.formTarget.classList.remove(this.formWithGigFormClass)
    this.formTarget.classList.remove(this.formWithHighlightFormClass)
    this.formTarget.classList.remove(this.formWithCollaborationStatusFormClass)
  }

  resizeSubjectFormContainerBackToOriginal () {
    this.subjectFormContainerTarget.style.removeProperty('height')
  }

  resizeSubjectFormContainerToConfirmationForm () {
    const height = Math.max(this.confirmationFormTarget.firstElementChild.offsetHeight, this.subjectFormContainerTarget.offsetHeight)
    this.subjectFormContainerTarget.style.height = `${height}px`
  }

  updateTitlePlaceholderText (newPlaceholderText) {
    this.titleInputTarget.setAttribute('placeholder', newPlaceholderText)
  }

  showTitleInput () {
    this.titleInputWrapperTarget.classList.remove(this.hiddenClass)
  }

  toggleOnWithoutSubjectFormClass () {
    if (this.changeClassesWhenSwitchingTypesValue) {
      this.removeTypeSpecificFormClasses()
      this.formTarget.classList.add(this.formWithoutSubjectFormClass)
    }
  }

  toggleOnGigFormClass () {
    if (this.changeClassesWhenSwitchingTypesValue) {
      this.removeTypeSpecificFormClasses()
      this.formTarget.classList.add(this.formWithGigFormClass)
    }
  }

  toggleOnHighlightFormClass () {
    if (this.changeClassesWhenSwitchingTypesValue) {
      this.removeTypeSpecificFormClasses()
      this.formTarget.classList.add(this.formWithHighlightFormClass)
    }
  }

  toggleOnCollaborationStatusFormClass () {
    if (this.changeClassesWhenSwitchingTypesValue) {
      this.removeTypeSpecificFormClasses()
      this.formTarget.classList.add(this.formWithCollaborationStatusFormClass)
    }
  }

  updateDescriptionPlaceholderText (newPlaceholderText) {
    if (this.changeDescriptionPlaceholderWhenSwitchingTypesValue) {
      this.descriptionInputTarget.setAttribute('placeholder', newPlaceholderText)
    }
  }

  clearErrorMessages () {
    this.formTarget.querySelectorAll('.invalid-feedback').forEach(element => element.remove())
  }

  hideTypeSwitcherContainer () {
    if (this.hasTypeSwitcherContainerTarget) {
      this.typeSwitcherContainerTarget.classList.add(this.hiddenClass)
    }
  }

  showTypeSwitcherContainer () {
    if (this.hasTypeSwitcherContainerTarget) {
      this.typeSwitcherContainerTarget.classList.remove(this.hiddenClass)
    }
  }
}
