import { Controller } from '@hotwired/stimulus'

export default class DomainSettingsController extends Controller {
  static targets = [
    'providerInput',
    'domainNameInput',
    'submitButton'
  ]

  connect () {
    this.checkDomainNameInput()
  }

  checkDomainNameInput () {
    const disabledValue = this.providerInputTarget.value.length === 0
    this.domainNameInputTarget.disabled = disabledValue
    this.submitButtonTarget.disabled = disabledValue
  }
}
