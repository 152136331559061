import { Controller } from '@hotwired/stimulus'

export default class NavbarController extends Controller {
  // eslint-disable-next-line class-methods-use-this
  static values = { navbarId: String }

  toggleNavbar () {
    $(this.navbarIdValue).collapse('hide')
  }
}
