import { Controller } from '@hotwired/stimulus'

export default class IFramelyController extends Controller {
  static targets = ['link']

  connect () {
    this.loadLink()
  }

  loadLink () {
    if (window.iframely) {
      if (this.hasLinkTarget) {
        window.iframely.load(this.linkTarget)
      }
    } else {
      return this.delay(500).then(() => this.loadLink())
    }
  }

  delay (milliseconds, callback) {
    return new Promise(resolve => setTimeout(resolve, milliseconds, callback))
  }
}
