import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'
import { originatesFrom } from '../utils/events'

const originatesFromNavBar = originatesFrom('.navbar-search')

export default class StickyNavbarController extends Controller {
  static classes = ['stickyHeader', 'visibleDropdown']

  static targets = ['wrapper', 'navbar']

  static values = {
    initialNavBarSize: Number
  }

  connect () {
    this.initialNavBarSizeValue = this.wrapperTarget.clientHeight
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
    document.addEventListener('click', this.handleClickOutsideNavBar, false)
  }

  _handleScroll = () => {
    if (this.navbarInViewport) {
      this.wrapperTarget.style = ''
      this.navbarTarget.classList.remove(this.stickyHeaderClass)
      this.wrapperTarget.classList.remove(this.visibleDropdownClass)
    } else {
      this.wrapperTarget.style = `margin-top: ${
        this.initialNavBarSizeValue
      }px;`
      this.navbarTarget.classList.add(this.stickyHeaderClass)
    }
  }

  get navbarInViewport () {
    return this.initialNavBarSizeValue >= window.scrollY
  }

  handleScroll = debounce(this._handleScroll) // eslint-disable-line no-underscore-dangle

  handleClickOutsideNavBar = event => {
    if (!originatesFromNavBar(event)) {
      this.wrapperTarget.classList.remove(this.visibleDropdownClass)
    }
  }
}
