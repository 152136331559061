import { Controller } from '@hotwired/stimulus'

export default class TwitterExtensionController extends Controller {
  static values = {
    redirectUrl: String
  }

  connect () {
    this.boundinterceptClickEvent = this.interceptClickEvent.bind(this)
    document.addEventListener('click', this.boundinterceptClickEvent)
  }

  disconnect () {
    document.removeEventListener('click', this.boundinterceptClickEvent)
  }

  interceptClickEvent (event) {
    event.preventDefault()
    window.open(this.redirectUrlValue, '_blank')
  }
}
