import { Controller } from '@hotwired/stimulus'
import 'chartkick/chart.js'

export default class InsightsVisitorAndPageViewCountsChartController extends Controller {
  connect () { this.buildChart() }

  buildChart () {
    const chart = this.chart
    const chartObject = chart.getChartObject()

    if (!chartObject) { return }

    this.chartData = chart.getData()

    const yAxisTicksConfig = chartObject.scales.y.ticks

    this.yAxisMinMaxConfig = {
      min: yAxisTicksConfig[0].value,
      max: yAxisTicksConfig[yAxisTicksConfig.length - 1].value
    }

    this.updateOptions()
  }

  updateOptions () {
    const chart = this.chart

    const { ctx, chartArea } = chart.getChartObject()

    chart.updateData(
      chart.getData().map((data) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, chartArea.height + 25)
        gradient.addColorStop(0, data.gradient)
        gradient.addColorStop(1, '#FFFFFF')

        return {
          ...data,
          library: {
            fill: true,
            backgroundColor: gradient
          }
        }
      })
    )

    chart.setOptions({
      ...chart.getOptions(),
      library: {
        scales: {
          y: {
            ...this.yAxisMinMaxConfig,
            border: {
              dash: (ctx) => {
                if (ctx.index) { return [5, 4] }
              },
              width: 0
            },
            ticks: {
              padding: 16
            },
            grid: {
              drawTicks: false,
              color: '#E4E8F1',
              width: 1
            }
          },
          x: {
            grid: {
              display: false
            },
            border: {
              display: false
            },
            type: 'time',
            time: {
              tooltipFormat: 'EEE, dd MMM',
              displayFormats: {
                day: 'dd MMM'
              }
            }
          }
        },
        plugins: {
          tooltip: {
            titleMarginBottom: 2,
            titleFont: { weight: 'normal' },
            callbacks: {
              label: ({ formattedValue, dataset }) => `${formattedValue} ${dataset.label}`
            }
          }
        }
      }
    })

    chart.redraw()
  }

  toggleVisitorsCount () {
    this.toggleData({ dataId: 'visitors' })
  }

  togglePageViewsCount () {
    this.toggleData({ dataId: 'page-views' })
  }

  toggleData ({ dataId }) {
    const chart = this.chart
    const data = this.chart.getData()

    if (data.length > 1 && data.find((data) => data.id === dataId)) {
      chart.updateData(
        this.chartData.filter((data) => data.id !== dataId)
      )
      this.resetActiveClasses()
      $(`#insights-visitor-and-page-view-counts-${dataId}-toggle`)[0].classList.add('inactive')
      $(`#insights-visitor-and-page-view-counts-${dataId}-toggle`)[0].classList.remove('active')
      this.updateOptions()
    } else {
      this.resetActiveClasses()
      chart.updateData(this.chartData)
      this.updateOptions()
    }
  }

  resetActiveClasses () {
    $('#insights-visitor-and-page-view-counts-visitors-toggle')[0].classList.add('active')
    $('#insights-visitor-and-page-view-counts-visitors-toggle')[0].classList.remove('inactive')
    $('#insights-visitor-and-page-view-counts-page-views-toggle')[0].classList.add('active')
    $('#insights-visitor-and-page-view-counts-page-views-toggle')[0].classList.remove('inactive')
  }

  get chart () {
    /* eslint-disable no-undef */
    return Chartkick.charts['visitor-and-page-view-counts-chart']
    /* eslint-enable no-undef */
  }
}
