import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class RefetchOnWakeController extends Controller {
  static values = {
    refetchUrl: String,
    wakeTimeout: Number
  }

  connect () {
    this.lastKnownTime = (new Date()).getTime()

    this.fetchInterval = setInterval(
      this.refetchCheck.bind(this),
      this.wakeTimeoutValue
    )
  }

  refetchCheck () {
    const currentTime = (new Date()).getTime()
    if (currentTime > (this.lastKnownTime + this.wakeTimeoutValue + 1000)) {
      get(this.refetchUrlValue, { responseKind: 'turbo-stream' })
      clearInterval(this.fetchInterval)
    }
    this.lastKnownTime = currentTime
  }
}
