// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//= require jquery
//= require bootstrap-sprockets
import Rails from '@rails/ujs'
import LocalTime from 'local-time'
import { Turbo } from '@hotwired/turbo-rails'
import { Application } from '@hotwired/stimulus'
import * as ActiveStorage from '@rails/activestorage'
import { iframely } from '@iframely/embed.js' // eslint-disable-line no-unused-vars

import $ from 'jquery'

import 'bootstrap'
import 'channels'
import 'controllers'
import 'chartkick/chart.js'
import { hasVariant } from '../utils/request'

// Imported Stimulus components from https://www.stimulus-components.com/
import Reveal from 'stimulus-reveal-controller'

const application = Application.start()
application.register('reveal', Reveal)

require('@rails/actiontext')
require('slick-carousel')
require('@lottiefiles/lottie-player')

const initializeTooltip = () => {
  if (!hasVariant('mobile_web')) {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: 'hover',
      delay: { show: 300, hide: 300 }
    })
  }
}

$(function () {
  initializeTooltip()
})

// Client side local time display
LocalTime.start()
LocalTime.config.i18n.en.date.on = '{date}'
LocalTime.config.i18n.en.datetime.at = '{date}'

Rails.start()
ActiveStorage.start()

window.initializeTooltip = initializeTooltip
window.Rails = Rails
window.Turbo = Turbo
