import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class MessageListController extends Controller {
  static targets = ['messages']

  connect () {
    useMutation(this, { childList: true, subtree: true })

    this.scrollToBottom()
  }

  scrollToBottom () {
    window.requestAnimationFrame(() => {
      const overflowY = window.getComputedStyle(this.messagesTarget).getPropertyValue('overflow-y')

      if (this.hasMessagesTarget && overflowY !== 'visible') {
        this.messagesTarget.scrollTop = this.messagesTarget.scrollHeight
      } else {
        window.scrollTo(0, document.body.scrollHeight)
      }
    })
  }

  mutate (entries) {
    for (const mutation of entries) {
      if (mutation.type === 'childList') {
        this.scrollToBottom()
      }
    }
  }
}
