import { Controller } from '@hotwired/stimulus'

export default class ToastInsertionController extends Controller {
  static targets = ['template', 'container']
  static classes = ['toastText']

  insertToast (e) {
    const { toastMessage } = e.target.dataset
    this.insertToastWithMessage(toastMessage)
  }

  insertToastWithMessage (toastMessage) {
    this.clearToasts()
    const toast = this.createToast(toastMessage)
    this.containerTarget.appendChild(toast)
    $('.toast').toast('show')
  }

  clearToasts () {
    this.containerTarget.innerHTML = ''
  }

  createToast (message) {
    const toastElement = this.templateTarget.cloneNode(true)
    const toastText = toastElement.querySelector(`.${this.toastTextClass}`)
    toastText.innerHTML = message
    return toastElement
  }
}
