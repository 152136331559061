import { Controller } from '@hotwired/stimulus'

/*
 * Usage
 * =====
 *
 * add the following params to a common ancestor
 * data-controller="prefixed-input"
 * data-prefixed-input-prefix-value="prefix--"
 *
 * This is the hidden input that gets submitted with the combined values
 * data-prefixed-input-target="hiddenField"
 *
 * Add this to your form field:
 * data-prefixed-input-target="inputField" data-action="keyUp->prefixed-input#keyUp"
 *
 */
export default class extends Controller {
  static targets = ['hiddenField', 'inputField']

  static values = {
    prefix: String
  }

  keyUp (event) {
    if (event.target.value.length > 0) {
      this.hiddenFieldTarget.value = `${this.prefixValue}${event.target.value}`
    } else {
      this.hiddenFieldTarget.value = ''
    }
  }
}
