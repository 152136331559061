import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class UnsavedChangesDuringNavigationController extends Controller {
  static values = {
    combinedEditorModalUrl: String,
    unsavedChangesUrl: String
  }

  initialize () {
    this.hasUnsavedChanges = false
    this.boundCheckForUnsavedChanges = this.checkForUnsavedChanges.bind(this)
  }

  connect () {
    document.addEventListener('turbo:before-visit', this.boundCheckForUnsavedChanges)
  }

  disconnect () {
    document.removeEventListener('turbo:before-visit', this.boundCheckForUnsavedChanges)
  }

  setFormIsPristine () {
    this.hasUnsavedChanges = false
  }

  setFormIsDirty () {
    this.hasUnsavedChanges = true
  }

  checkForUnsavedChanges (event) {
    if (this.hasUnsavedChanges === false) return

    event.preventDefault()

    const visitUrl = event.detail.url
    const modalUrl = new URL(this.combinedEditorModalUrlValue)
    const unsavedChangesUrl = new URL(this.unsavedChangesUrlValue)
    unsavedChangesUrl.searchParams.append('redirect_endpoint', visitUrl)
    unsavedChangesUrl.searchParams.append('navigation', '_top')
    modalUrl.searchParams.append('modal_path', unsavedChangesUrl)

    post(modalUrl, { responseKind: 'turbo-stream' })
  }
}
