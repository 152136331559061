import { Controller } from '@hotwired/stimulus'

export default class ResizeInputWithContentController extends Controller {
  static targets = ['input']

  connect () {
    this.inputTarget.addEventListener('input', (e) => {
      this.resizeHeight()
    })
  }

  resizeHeight () {
    if (this.inputTarget.value.length > 0) {
      const lineHeightOfText = parseFloat(
        window.getComputedStyle(this.inputTarget).lineHeight.slice(0, -2)
      )
      const paddingY =
        parseFloat(
          window.getComputedStyle(this.inputTarget).paddingTop.slice(0, -2)
        ) * 2
      const baseHeightOfInput = Math.floor(lineHeightOfText + paddingY)

      const maxNumberOfRows = 3
      const remainingHeight =
        this.inputTarget.scrollHeight - baseHeightOfInput
      const numberOfRows = Math.floor(remainingHeight / lineHeightOfText) + 1

      this.inputTarget.setAttribute('rows', Math.min(numberOfRows, maxNumberOfRows))
    } else {
      // When there is no text, e.g. everything removed
      this.inputTarget.rows = 1
    }
  }
}
