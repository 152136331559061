import { Controller } from '@hotwired/stimulus'

export default class DynamicContentEditorRemoveEmbedsController extends Controller {
  static targets = ['urlEmbed']

  removeEmbed (event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    const editorController = this.editorController()
    editorController.removeElement(this.urlEmbedTarget)
  }

  editorController () {
    return this.application.controllers.find(controller => controller.controllerName === 'content-editor')
  }
}
