import { Controller } from '@hotwired/stimulus'

export default class ConversationsController extends Controller {
  static values = { initWithConversation: Boolean }
  static classes = ['hiddenSection', 'visibleSection', 'unreadConversation']
  static targets = ['conversationList', 'viewedConversation']

  // eslint-disable-next-line class-methods-use-this
  connect () {
    document.addEventListener('conversation:connected', () => {
      this.displayConversationPanel()
    })

    if (this.initWithConversationValue) {
      this.displayConversationPanel()
    }
  }

  displayConversationPanel () {
    if (!this.hasViewedConversationTarget) return
    this.viewedConversationTarget.classList.remove(this.hiddenSectionClass)
    this.conversationListTarget.classList.remove(this.visibleSectionClass)
    this.conversationListTarget.classList.add(this.hiddenSectionClass)
  }

  displayConversationListPanel () {
    if (!this.hasViewedConversationTarget) return
    this.viewedConversationTarget.classList.add(this.hiddenSectionClass)
    this.conversationListTarget.classList.add(this.visibleSectionClass)
    this.conversationListTarget.classList.remove(this.hiddenSectionClass)
  }

  markAsRead (e) {
    const conversationUnreadIcon = e.target
      .closest('a')
      .querySelector(`.${this.unreadConversationClass}`)
    if (conversationUnreadIcon) conversationUnreadIcon.remove()
  }
}
