import { Controller } from '@hotwired/stimulus'
import { some, every } from 'lodash'

export default class FormSubmissionController extends Controller {
  static targets = ['submit', 'form', 'field', 'nativeSubmit']

  initialize () {
    this.onFieldValueChange = this.onFieldValueChange.bind(this)
    this.fieldHasValue = this.fieldHasValue.bind(this)
    this.controllerName = 'form-submission'
  }

  connect () {
    this.submissionForm.addEventListener(
      'turbo:submit-start',
      this.submitStart.bind(this)
    )
    this.submissionForm.addEventListener(
      'turbo:submit-end',
      this.submitEnd.bind(this)
    )

    if (this.hasNativeSubmitTarget) {
      requestAnimationFrame(() => {
        this.setupFieldListeners()
        this.onFieldValueChange()
      })
    }
  }

  disconnect () {
    this.submissionForm.removeEventListener(
      'turbo:submit-start',
      this.submitStart
    )
    this.submissionForm.removeEventListener('turbo:submit-end', this.submitEnd)

    if (this.hasNativeSubmitTarget) {
      this.fieldTargets.forEach(field => {
        field.removeEventListener('change', this.onFieldValueChange)
      })
    }
  }

  setupFieldListeners () {
    // Monitor fields for content changes and enable / disable the native submit button
    this.fieldTargets.forEach(field => {
      field.addEventListener('change', this.onFieldValueChange)
    })
  }

  onFieldValueChange () {
    if (every(this.fieldTargets, this.fieldHasValue)) {
      this.enableSubmitButton()
    } else {
      this.disableSubmitButton()
    }
  }

  enableSubmitButton () {
    this.submitButtonController?.enableButton()
  }

  disableSubmitButton () {
    this.submitButtonController?.disableButton()
  }

  // Disables the discard confirmation, if no content was entered into the form
  disableConfirmationToDiscard (event) {
    // Check if any field has a value entered
    if (some(this.fieldTargets, this.fieldHasValue)) {
      event.currentTarget.dataset.skipConfirm = null
      return
    }

    // If all fields are empty, then we can skip confirmation dialog
    event.currentTarget.dataset.skipConfirm = 'true'
  }

  fieldHasValue (field) {
    if (field.dataset.controller.includes('dynamic-content-editor')) {
      const controller = this.application.getControllerForElementAndIdentifier(field, 'dynamic-content-editor')
      return controller.contentLength > 1
    } else if (field.dataset.controller.includes('content-editor')) {
      const controller = this.application.getControllerForElementAndIdentifier(field, 'content-editor')
      return controller.contentLength > 1
    } else {
      return field.value.length > 0
    }
  }

  submit () {
    return Turbo.navigator.submitForm(this.formTarget)
  }

  get submissionForm () {
    return this.hasFormTarget ? this.formTarget : this.element
  }

  submitStart () {
    const form = this.submissionForm
    if (form) {
      this.submitTargets.forEach(submitTarget => {
        submitTarget.disabled = true
      })
    }
  }

  submitEnd () {
    const form = this.submissionForm
    if (form) {
      this.submitTargets.forEach(submitTarget => {
        submitTarget.disabled = false
      })
    }
  }

  get submitButtonController () {
    if (this.hasNativeSubmitTarget) {
      return this.application.getControllerForElementAndIdentifier(this.nativeSubmitTarget, 'strada--nav-button')
    }
  }
}
