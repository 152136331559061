import { Controller } from '@hotwired/stimulus'

export default class BackButtonController extends Controller {
  static values = {
    fallbackUrl: String
  }

  connect () {
    this.currentUrl = window.location.href
  }

  navigateBack (event) {
    event.preventDefault()

    if (history.length > 2) {
      history.back()
    }

    setTimeout(() => {
      if (this.currentUrl === window.location.href) {
        window.location.href = this.fallbackUrlValue
      }
    }, 250)
  }
}
