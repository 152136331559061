import { Controller } from '@hotwired/stimulus'

export default class RequiresTextController extends Controller {
  static targets = [
    'textField',
    'submitButton'
  ]

  connect () {
    this.checkSubmitButton()
  }

  get hasFilledInAllFields () {
    let targetsFilledIn = true
    this.textFieldTargets.forEach(target => {
      const fieldValue = target.innerText ? target.innerText : target.value
      if (fieldValue === undefined || !fieldValue.trim().length) {
        targetsFilledIn = false
      }
    })
    return targetsFilledIn
  }

  checkSubmitButton () {
    if (this.hasFilledInAllFields) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-enabled')
    } else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-disabled')
    }
  }
}
