import { Controller } from '@hotwired/stimulus'
import { getClosestElementFor } from '../utils/dom'

export default class PreviewModeController extends Controller {
  connect () {
    this.boundinterceptClickEvent = this.interceptClickEvent.bind(this)
    document.addEventListener('click', this.boundinterceptClickEvent)
  }

  disconnect () {
    document.removeEventListener('click', this.boundinterceptClickEvent)
  }

  interceptClickEvent (event) {
    if (this.element.contains(event.target)) return

    if (
      window.getSelection().toString().length !== 0 ||
      $(event.target).is('a') ||
      getClosestElementFor('a')(event.target) ||
      getClosestElementFor('button')(event.target)
    ) {
      event.preventDefault()
    }
  }
}
