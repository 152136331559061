import { Controller } from '@hotwired/stimulus'

export default class ProfilesController extends Controller {
  // eslint-disable-next-line class-methods-use-this
  static targets = [
    'profileSetupPanel',
    'profileShortUrlInformationPanel',
    'profileConnectDomainPanel'
  ]

  static classes = ['hiddenPanel']

  connect () {
    window.initializeTooltip()
  }

  showPane (e) {
    e.preventDefault()
    $(e.target.closest('a')).tab('show')
  }

  dismissVisibleToasts () {
    $('.toast').remove()
  }

  dismissProfileSetup () {
    if (this.hasProfileSetupPanelTarget) {
      this.profileSetupPanelTarget.classList.add(this.hiddenPanelClass)
    }
  }

  dismissShortUrlInformationPanel () {
    if (this.hasProfileShortUrlInformationPanelTarget) {
      this.profileShortUrlInformationPanelTarget.classList.add(
        this.hiddenPanelClass
      )
    }
  }

  dismissConnectDomainPanel () {
    if (this.hasProfileConnectDomainPanelTarget) {
      this.profileConnectDomainPanelTarget.classList.add(this.hiddenPanelClass)
    }
  }
}
