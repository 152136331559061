import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class UpdateCheckboxController extends Controller {
  static values = { url: String, attribute: String, name: String }

  async toggle (event) {
    event.preventDefault()
    const jsonBody = {}

    if (!this.nameValue) {
      jsonBody.attribute = this.attributeValue
    } else {
      jsonBody[`${this.nameValue}`] = this.attributeValue
    }
    const request = new FetchRequest('patch', this.urlValue, {
      body: JSON.stringify(jsonBody),
      responseKind: 'turbo-stream'
    })
    await request.perform()
  }

  async propagateClick (event) {
    event.preventDefault()
    const request = new FetchRequest('patch', this.urlValue, {
      body: JSON.stringify({ value: this.element.checked }),
      responseKind: 'turbo-stream'
    })
    await request.perform()
  }
}
