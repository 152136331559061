import { StradaController } from './strada_controller'
import { turboVisitPostRedirect } from '../../utils/turbo_requests'

export default class extends StradaController {
  static component = 'apple-auth'
  static values = { postUrl: String }

  sign_in (event) {
    if (!this.enabled) return

    event.stopImmediatePropagation()
    event.preventDefault()

    this.send('sign_in', {}, message => {
      turboVisitPostRedirect(
        this.postUrlValue,
        message.data
      )
    })
  }
}
