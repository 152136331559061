// Use most of the same configs as en-US
import formatLong from 'date-fns/locale/en-US/_lib/formatLong/index'
import formatRelative from 'date-fns/locale/en-US/_lib/formatRelative/index'
import localize from 'date-fns/locale/en-US/_lib/localize/index'
import match from 'date-fns/locale/en-US/_lib/match/index'

// Load our own formatDistance config
import formatDistance from './formatDistance'

const enPolyworkLong = {
  code: 'en-PolyworkLong',
  formatDistance,
  formatLong,
  formatRelative,
  localize,
  match,
  options: {
    weekStartsOn: 0 /* Sunday */,
    firstWeekContainsDate: 1
  }
}

export default enPolyworkLong
