import { Controller } from '@hotwired/stimulus'

export default class ToolbarController extends Controller {
  static targets = ['linkDialog', 'linkField', 'linkError']

  connect () {
    this.dispatch('ready', { detail: { toolbar: this.element } })
  }

  registerEditor (controller) {
    this.editorController = controller
    this.editor = controller.editor
  }

  linkHandler (activate, selectedText) {
    if (activate && this.linkDialogActive !== true) {
      this.displayLinkDialog()
    } else {
      this.cancelLink()
    }
  }

  displayLinkDialog () {
    this.clearLinkDialog()
    this.linkDialogActive = true

    const input = this.linkDialogTarget.querySelector('.link-fields__input')

    input.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        this.insertLink()
      } else if (event.key === 'Escape') {
        event.preventDefault()
        event.stopPropagation()
        this.cancelLink()
      }
    })

    this.linkDialogTarget.classList.add('active')
    input.focus()
  }

  displayDialogError (message) {
    if (this.hasLinkErrorTarget) {
      this.linkErrorTarget.textContent = message
      this.linkErrorTarget.classList.add('active')
    }
  }

  clearLinkDialog () {
    this.linkDialogActive = false

    if (this.hasLinkDialogTarget) {
      this.linkDialogTarget.classList.remove('active')
      this.linkFieldTarget.value = ''
      this.linkErrorTarget.classList.remove('active')
    }
  }

  insertLink (event) {
    if (event) event.preventDefault()

    const linkUrl = this.linkFieldTarget.value

    if (!/^(https?:\/\/|mailto:)/.test(linkUrl)) {
      this.displayDialogError('This link is invalid')
      return
    }

    this.editor.format('link', linkUrl)
    this.clearLinkDialog()
  }

  cancelLink (event) {
    if (event) event.preventDefault()

    this.editor.format('link', false)
    this.clearLinkDialog()
  }
}
