import { Controller } from '@hotwired/stimulus'

export default class UpdateParentCssClassesController extends Controller {
  static values = {
    newClasses: Array
  }

  connect () {
    const parentElement = this.element.parentElement
    parentElement.className = ''
    this.newClassesValue.forEach(cssClass => {
      parentElement.classList.add(cssClass)
    })
  }
}
