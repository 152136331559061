import { Controller } from '@hotwired/stimulus'
import { VALID_LINK_REGEX } from './dynamic_content_editor_controller'

export default class DynamicContentEditorLinksController extends Controller {
  static targets = ['linkTextField', 'linkUrlField', 'linkError']

  connect () {
    this.controllerName = 'dynamic-content-editor-links'
  }

  handleLinkInsertEvent (event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    const linkUrl = this.linkUrlFieldTarget.value

    if (linkUrl && !VALID_LINK_REGEX.test(linkUrl)) {
      this.linkErrorTarget.classList.add('active')
      return false
    } else {
      const editorController = this.editorController()
      const linkText = this.linkTextFieldTarget.value
      const range = editorController.getRange()

      editorController.insertLink({ range, text: linkText, link: linkUrl })
      $('#close-link-form').click()
      return true
    }
  }

  clearLinkDialog (event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    this.linkErrorTarget.classList.remove('active')
    this.linkTextFieldTarget.value = ''
    this.linkUrlFieldTarget.value = ''
  }

  editorController () {
    return this.application.controllers.find(controller => controller.controllerName === 'content-editor')
  }
}
