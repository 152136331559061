import { Controller } from '@hotwired/stimulus'
import capitalize from 'lodash/capitalize'

export default class ProductCustomPageController extends Controller {
  static targets = [
    'domainButton',
    'darkButton',
    'coverButton',
    'lightDomainStandardImage',
    'lightDomainCoverImage',
    'lightNavbarStandardImage',
    'lightNavbarCoverImage',
    'darkDomainStandardImage',
    'darkDomainCoverImage',
    'darkNavbarStandardImage',
    'darkNavbarCoverImage',
    'image'
  ]

  click (e) {
    const targetButton = e.target.closest('.custom-page-buttons__button')
    targetButton.classList.toggle('active')
    this.displayImage()
  }

  displayImage () {
    const dark = this.darkButtonTarget.classList.contains('active')
    const domain = this.domainButtonTarget.classList.contains('active')
    const cover = this.coverButtonTarget.classList.contains('active')
    const darkValue = dark ? 'dark' : 'light'
    const domainValue = domain ? 'Domain' : 'Navbar'
    const coverValue = cover ? 'Cover' : 'Standard'

    this.hideAllImages()
    const target = this.generateTargetName(darkValue, domainValue, coverValue)
    this[target].classList.remove('hide')
  }

  hideAllImages () {
    this.imageTargets.forEach(imageTarget => {
      imageTarget.classList.add('hide')
    })
  }

  // eslint-disable-next-line class-methods-use-this
  generateTargetName (darkValue, domainValue, coverValue) {
    return `${darkValue}${capitalize(domainValue)}${capitalize(
      coverValue
    )}ImageTarget`
  }
}
