import { Controller } from '@hotwired/stimulus'

export default class ScrollIntoView extends Controller {
  static values = {
    onlyIfOutOfView: Boolean
  }

  connect () {
    if (this.onlyIfOutOfViewValue && this.elementOutOfView) {
      this.element.scrollIntoView({ behavior: 'smooth' })
    } else {
      this.element.scrollIntoView({ inline: 'start', behavior: 'smooth' })
    }
  }

  get elementOutOfView () {
    const elementBounds = this.element.getBoundingClientRect()
    const windowHeight = window.innerHeight
    return elementBounds.top < 0 || elementBounds.top > windowHeight
  }
}
