import { StradaController } from './strada_controller'

export default class extends StradaController {
  static component = 'mail-composer'
  static values = {
    recipient: String,
    subject: String,
    body: String
  }

  open () {
    if (!this.enabled) return

    this.send('connect', {
      recipients: [this.recipientValue],
      subject: this.subjectValue,
      body: this.bodyValue
    }, () => {})
  }
}
