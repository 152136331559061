import { Controller } from '@hotwired/stimulus'
import { getClosestElementFor } from '../utils/dom'

const LIST_ITEM_CLASS = 'list-item'
const LIST_ITEM_SELECTED_CLASS = 'list-item-selected'
const UNSURE_LIST_ITEM_ID = 'unsure-list-item'

function removeListItemFromOtherElements (elements) {
  elements.forEach((element) => {
    if (element.dataset.id !== UNSURE_LIST_ITEM_ID) {
      removeClasses(element)
    }
  })
}

function removeClasses (element) {
  element.classList.remove(LIST_ITEM_SELECTED_CLASS)
  element.getElementsByClassName('custom-control-input')[0].checked = false
}

function addClasses (element) {
  element.classList.add(LIST_ITEM_SELECTED_CLASS)
  element.getElementsByClassName('custom-control-input')[0].checked = true
}

function fallbackToUnsure (unsureListItem) {
  const previouslySelectedListItem = document.querySelector(`.${LIST_ITEM_SELECTED_CLASS}`)

  if (!previouslySelectedListItem) {
    addClasses(unsureListItem)
  }
}

function UnsureListItemToggle (newlySelectedListItem, unsureListItem) {
  if (newlySelectedListItem.dataset.id === UNSURE_LIST_ITEM_ID) {
    const selectedItems = document.querySelectorAll(`.${LIST_ITEM_SELECTED_CLASS}`)
    removeListItemFromOtherElements(selectedItems)
    addClasses(unsureListItem)
  } else {
    removeClasses(unsureListItem)
  }
}

export default class ListItemSelectedController extends Controller {
  static targets = 'selectedListItem'

  static values = {
    selectionMode: String
  }

  connect () {
    this.selectionMode = this.hasSelectionModeValue ? this.selectionModeValue : 'multiple'
  }

  listItemSelected (event) {
    event.preventDefault()
    const newlySelectedListItem = getClosestElementFor(`.${LIST_ITEM_CLASS}`)(event.target)
    const unsureListItem = document.querySelector(`[data-id='${UNSURE_LIST_ITEM_ID}']`)

    if (newlySelectedListItem.classList.contains(LIST_ITEM_SELECTED_CLASS)) {
      removeClasses(newlySelectedListItem)

      if (unsureListItem) { fallbackToUnsure(unsureListItem) }
    } else {
      if (unsureListItem) { UnsureListItemToggle(newlySelectedListItem, unsureListItem) }

      if (this.selectionMode === 'single') {
        const selectedItems = document.querySelectorAll(`.${LIST_ITEM_SELECTED_CLASS}`)
        removeListItemFromOtherElements(selectedItems)
      }

      addClasses(newlySelectedListItem)
    }
  }
}
