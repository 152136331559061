import { Turbo } from '@hotwired/turbo-rails'
import { StradaController } from './strada_controller'

// Connects to data-controller="strada--choices-selector"
export default class extends StradaController {
  static component = 'choices-selector'
  static targets = ['field', 'title', 'image', 'imageContainer']
  static values = {
    id: String,
    title: String,
    searchable: Boolean,
    searchPlaceholder: String,
    noSearchResultsText: String,
    multiSelectionEnabled: Boolean,
    canAddItems: Boolean,
    addItemConfiguration: String,
    addItemCustomType: String,
    addItemUrl: String,
    addItemParamName: String,
    addItemAdditionalParams: String,
    choicesPath: String,
    choices: Array,
    initialSelections: Array,
    modalStyle: String
  }

  open (event) {
    if (!this.enabled) return
    event.preventDefault()

    const data = {
      id: this.idValue || 'choices-dropdown',
      title: this.titleValue,
      modalStyle: this.modalStyleValue,
      isMultiSelectionEnabled: this.multiSelectionEnabledValue,
      isSearchable: this.hasSearchableValue ? this.searchableValue : false,
      searchPlaceholder: this.searchPlaceholderValue,
      noSearchResultsText: this.noSearchResultsTextValue,
      canAddItems: this.canAddItemsValue,
      addItemConfiguration: this.addItemConfigurationValue,
      addItemCustomType: this.addItemCustomTypeValue,
      choicesPath: this.hasChoicesPathValue ? this.choicesPathValue : undefined,
      choices: this.hasChoicesPathValue ? undefined : this.choicesValue,
      initialSelections: this.initialSelectionsValue
    }

    this.send('connect', data, ({ data }) => {
      if (data.selectedItems) {
        data.selectedItems.forEach(item => {
          // TODO handle selecting multiple items...
          this.initialSelectionsValue = [item]
          this.fieldTarget.value = item.id
          this.titleTarget.textContent = item.title

          if (item.image_url) {
            this.imageContainerTarget.classList.remove('d-none')
            this.imageTarget.src = item.image_url
          } else {
            this.imageContainerTarget.classList.add('d-none')
          }
        })
      } else if (data.selectedAddItemValue) {
        // Adding a new item
        const { addItemUrlValue, addItemParamNameValue, addItemAdditionalParamsValue } = this
        let newURL = `${addItemUrlValue}?${addItemParamNameValue}=${encodeURIComponent(data.selectedAddItemValue)}`

        if (this.hasAddItemAdditionalParamsValue) {
          newURL = `${newURL}&${addItemAdditionalParamsValue}`
        }

        Turbo.visit(newURL)
      }
    })
  }
}
