import { Controller } from '@hotwired/stimulus'
import debounce from 'lodash/debounce'

const HIDDEN_CLASS = 'd-none'

export default class BadgeSelectionController extends Controller {
  static targets = [
    'badge',
    'search',
    'addBadge',
    'addBadgePlaceholder',
    'addBadgeUrl',
    'searchForm',
    'selectedBadges'
  ]

  static values = {
    submitButtonId: String
  }

  connect () {
    this.checkSubmitButton()
    this.conductSearch = debounce(this.conductSearch, 500)
    const observer = new MutationObserver(this.checkSubmitButton.bind(this))
    observer.observe(this.selectedBadgesTarget, {
      childList: true
    })
  }

  get hasSelectedAtLeastOneBadge () {
    return (
      this.selectedBadgesTarget.querySelector("input[type='hidden']") !== null
    )
  }

  get submitButton () {
    return document.getElementById(this.submitButtonIdValue)
  }

  updateSelectableOptions (event) {
    if (event.key === 'Enter' || event.keyCode === 13) {
      return this.searchTarget.blur()
    }

    const searchQuery = this.searchTarget.value

    this.conductSearch()

    if (!this.hasAddBadgePlaceholderTarget) return

    if (searchQuery.length > 0) {
      this.showAddBadgePlaceholder()
      this.updateBadgePlaceholder(searchQuery)
      this.updateAddBadgeQuery(searchQuery)
    } else {
      this.hideAddBadgePlaceholder()
      this.updateAddBadgeQuery('')
    }
  }

  conductSearch () {
    Rails.fire(this.searchFormTarget, 'submit')
  }

  updateBadgePlaceholder (searchQuery) {
    this.addBadgePlaceholderTarget.innerHTML = searchQuery
  }

  updateAddBadgeQuery (searchQuery) {
    const url = new URL(this.addBadgeUrlTarget.href)
    const { searchParams } = url
    searchParams.set('badge_name', searchQuery)
    url.search = searchParams.toString()
    const updatedUrl = url.toString()
    this.addBadgeUrlTarget.href = updatedUrl
  }

  hideAddBadgePlaceholder () {
    if (this.hasAddBadgeTarget) {
      this.addBadgeTarget.classList.add(HIDDEN_CLASS)
    }
  }

  showAddBadgePlaceholder () {
    if (this.hasAddBadgeTarget) {
      this.addBadgeTarget.classList.remove(HIDDEN_CLASS)
    }
  }

  checkSubmitButton () {
    if (this.hasSelectedAtLeastOneBadge) {
      this.submitButton.disabled = false
      this.submitButton.setAttribute('data-test', 'submit-button-enabled')
    } else {
      this.submitButton.disabled = true
      this.submitButton.setAttribute('data-test', 'submit-button-disabled')
    }
  }
}
