import { Controller } from '@hotwired/stimulus'

export default class InvalidRichTextController extends Controller {
  static targets = ['toolbar', 'input']

  connect () {
    this.checkForInvalidStatus()
  }

  checkForInvalidStatus () {
    if (this.inputTarget.classList.contains('is-invalid')) {
      this.toolbarTarget.classList.add('is-invalid')
    }
  }
}
