import { Controller } from '@hotwired/stimulus'

export default class FocusElementController extends Controller {
  static values = {
    selector: String
  }

  focusOnElement () {
    const focusElement = document.querySelector(this.selectorValue)
    focusElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
    focusElement.focus({ preventScroll: true })
  }
}
