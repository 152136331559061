import { Controller } from '@hotwired/stimulus'

export default class FacebookShareController extends Controller {
  connect () {
    this.overrideWindowOpen()
  }

  // eslint-disable-next-line class-methods-use-this
  openFacebookPopUp (event) {
    event.preventDefault()
    const link = event.target.closest('a')
    FB.ui({
      display: 'popup',
      method: 'share',
      href: link.dataset.shareUrl
    })
  }

  // eslint-disable-next-line class-methods-use-this
  overrideWindowOpen () {
    window.open = (open => (url, name, features) => {
      const w = 475
      const h = 183
      const { documentElement: clientWidth, clientHeight } = document
      const { innerWidth, innerHeight, screenLeft, screenTop } = window
      const { height, left, top, width } = window.screen

      // Fixes dual-screen position for chrome/firefox
      const dualScreenLeft = screenLeft !== undefined ? screenLeft : left
      const dualScreenTop = screenTop !== undefined ? screenTop : top

      let popupWidth
      let popupHeight

      if (innerWidth) {
        popupWidth = innerWidth
      } else if (clientWidth) {
        popupWidth = clientWidth
      } else {
        popupWidth = width
      }

      if (innerHeight) {
        popupHeight = innerHeight
      } else if (clientHeight) {
        popupHeight = clientHeight
      } else {
        popupHeight = height
      }

      const popupLeft = popupWidth / 2 - w / 2 + dualScreenLeft
      const popupTop = popupHeight / 2 - h / 2 + dualScreenTop
      const overrideFeatures = `width=475,height=183,left=${popupLeft},top=${popupTop},scrollbars=1,location=1,toolbar=0`

      const windowName = name || 'default_window_name'
      return open.call(window, url, windowName, overrideFeatures)
    })(window.open)
  }
}
