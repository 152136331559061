// For more configurability, see:
// https://gist.github.com/lazaronixon/dca1b48c241422d6347f4b0c93bec739
// https://web-crunch.com/posts/rails-drag-drop-active-storage-stimulus-dropzone
// https://github.com/polyworkhq/lotus/blob/master/app/frontend/src/controllers/dropzone-controller.js

import { Controller } from '@hotwired/stimulus'
import { DirectUpload } from '@rails/activestorage'
import Dropzone from 'dropzone'
import {
  getMetaValue,
  findElement,
  removeElement,
  insertAfter
} from '../utils/dropzone'

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = [
    'input',
    'previewInput',
    'messages',
    'template',
    'thumbnail',
    'imageFilters',
    'loading',
    'thumbnailContainer',
    'mediaAttachedIcon',
    'noMediaAttachedIcon',
    'submitButton',
    'noExistingMediaButtons',
    'existingMediaButtons'
  ]

  static values = {
    existingMedia: String,
    submitAfterUpload: Boolean,
    resizeThumbnails: Boolean,
    removePreviousFileOnUpload: Boolean
  }

  static classes = ['hiddenThumbnails', 'visibleThumbnails', 'hiddenMessages', 'visibleMessages']

  connect () {
    this.dropZone = createDropZone(this)
    this.uploadingFiles = []
    this.bindEvents()
    this.hideFileInput()
    this.hideMessages()
    if (this.resizeThumnails) this.createThumbnailObserver()

    this.loadExistingMedia()
  }

  get formSubmissionController () {
    return this.application.controllers.find(controller => controller.controllerName === 'form-submission')
  }

  updatePreviewImageWithFilters (event) {
    event.preventDefault()

    if (this.hasPreviewInputTarget) { this.previewInputTarget.value = 'true' }

    this.formSubmissionController.submit()

    if (this.hasPreviewInputTarget) { this.previewInputTarget.value = 'false' }
  }

  createThumbnailObserver () {
    const observer = new MutationObserver(
      this.resyncThumbnailHeights.bind(this)
    )
    observer.observe(this.thumbnailContainerTarget, { childList: true })
  }

  hideFileInput () {
    this.inputTarget.disabled = true
    this.inputTarget.style.display = 'none'
  }

  // Private
  loadExistingMedia () {
    if (!this.existingMediaValue) return

    const mediaMapping = JSON.parse(this.existingMediaValue)

    mediaMapping.forEach(media => {
      const mockFile = {
        accepted: true,
        preLoaded: true,
        kind: 'image',
        signed_id: media.signed_id
      }
      this.dropZone.files.push(mockFile)
      this.dropZone.emit('addedfile', mockFile)
      this.dropZone.emit('thumbnail', mockFile, media.url)
      this.dropZone.emit('complete', mockFile)
    })
  }

  showMediaAttachedIcon () {
    this.mediaAttachedIconTarget.classList.remove(this.hiddenThumbnailsClass)
    this.noMediaAttachedIconTarget.classList.add(this.hiddenThumbnailsClass)
  }

  showNoMediaAttachedIcon () {
    this.mediaAttachedIconTarget.classList.add(this.hiddenThumbnailsClass)
    this.noMediaAttachedIconTarget.classList.remove(this.hiddenThumbnailsClass)
  }

  disableSubmitButton () {
    this.submitButtonTargets.forEach(button => {
      button.disabled = true
    })
  }

  enableSubmitButton () {
    this.submitButtonTargets.forEach(button => {
      button.disabled = false
    })
  }

  showMessages () {
    this.messagesTarget.classList.remove(this.hiddenMessagesClass)
    this.messagesTarget.classList.add(this.visibleMessagesClass)
  }

  hideMessages () {
    this.messagesTarget.classList.remove(this.visibleMessagesClass)
    this.messagesTarget.classList.add(this.hiddenMessagesClass)
  }

  bindEvents () {
    this.dropZone.on('addedfile', file => {
      if (this.uploadingFiles.length < this.maxFiles) {
        this.uploadingFiles.push(file)
        this.hideMessages()
        this.disableSubmitButton()
        setTimeout(() => {
          if (file.accepted) {
            createDirectUploadController(this, file).start()
            // Toggle Media Icon if it is present
            if (this.hasMediaIcons) {
              this.showMediaAttachedIcon()
            }
          }
        }, 200)
      }
    })

    this.dropZone.on('queuecomplete', () => {
      // Ensure that all files have their signed_ids
      // appended to the form before re-enabling
      setTimeout(() => {
        this.enableSubmitButton()
        this.toggleAddMediaButtons(true)
        this.uploadingFiles = []
      }, 200)
    })

    this.dropZone.on('removedfile', file => {
      if (file.status !== 'error') this.hideMessages()

      if (file.controller) {
        removeElement(file.controller.hiddenInput)
        // Toggle Media Icon if it is present
        const remainingThumbnails = this.thumbnailTargets.length - 1
        if (remainingThumbnails <= 0 && this.hasMediaIcons) {
          this.showNoMediaAttachedIcon()
        }
      }

      this.toggleAddMediaButtons(false)
    })

    this.dropZone.on('error', (file, errorMessage, xhr) => {
      this.messagesTarget.innerText = errorMessage
      this.showMessages()
      this.dropZone.removeFile(file)
    })

    if (this.removePreviousFileOnUpload) {
      this.dropZone.on('maxfilesexceeded', function (files) {
        this.removeAllFiles()
        this.addFile(files)
      })
    }
  }

  removeCurrentFiles () {
    this.dropZone.removeAllFiles()
    if (this.hasImageFiltersTarget) {
      this.imageFiltersTarget.classList.add('d-none')
    }
  }

  toggleAddMediaButtons (existingMediaPresent) {
    if (this.hasNoExistingMediaButtonsTarget && this.hasExistingMediaButtonsTarget) {
      if (existingMediaPresent) {
        this.noExistingMediaButtonsTarget.classList.add('d-none')
        this.existingMediaButtonsTarget.classList.remove('d-none')
      } else {
        this.noExistingMediaButtonsTarget.classList.remove('d-none')
        this.existingMediaButtonsTarget.classList.add('d-none')
      }
    }
  }

  resyncThumbnailHeights () {
    // There will be an additional thumbnail target due to
    // the thumbnail target that is present in the template
    const count = this.thumbnailTargets.length - 1
    let height = '100'
    if (count >= 5) {
      height = 30
    } else if (count >= 3) {
      height = 46
    }

    this.thumbnailTargets.forEach(target => {
      target.style = `min-height: ${height}%`
    })

    if (count > 0) {
      this.thumbnailContainerTarget.classList.remove(this.hiddenThumbnailsClass)
      this.thumbnailContainerTarget.classList.add(this.visibleThumbnailsClass)
    } else {
      this.thumbnailContainerTarget.classList.add(this.hiddenThumbnailsClass)
      this.thumbnailContainerTarget.classList.remove(this.visibleThumbnailsClass)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get headers () {
    return { 'X-CSRF-Token': getMetaValue('csrf-token') }
  }

  get hasMediaIcons () {
    return this.hasMediaAttachedIconTarget && this.hasNoMediaAttachedIconTarget
  }

  get url () {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }

  get maxFiles () {
    return this.data.get('maxFiles') || 1
  }

  get maxFileSize () {
    return this.data.get('maxFileSize') || 256
  }

  get thumbnailHeight () {
    return this.data.get('thumbnailHeight') || 750
  }

  get thumbnailWidth () {
    return this.data.get('thumbnailWidth') || 750
  }

  get acceptedFiles () {
    return this.data.get('acceptedFiles')
  }

  get resizeThumnails () {
    return this.resizeThumbnailsValue || false
  }

  get removePreviousFileOnUpload () {
    return this.removePreviousFileOnUploadValue || false
  }

  get addRemoveLinks () {
    return this.data.get('addRemoveLinks') || false
  }

  get previewContainerName () {
    return this.data.get('previewContainerName') || '#thumbnailContainer'
  }

  get form () {
    return this.element.closest('form')
  }

  get submitButton () {
    return findElement(this.form, 'input[type=submit], button[type=submit]')
  }

  get template () {
    return this.templateTarget.innerHTML
  }
}

class DirectUploadController {
  constructor (source, file) {
    this.directUpload = createDirectUpload(file, source.url, this)
    this.source = source
    this.file = file
  }

  start () {
    this.file.controller = this
    this.hiddenInput = this.createHiddenInput()

    if (this.file.preLoaded) {
      this.hiddenInput.value = this.file.signed_id
      return
    }

    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput)
        this.emitDropzoneError(error)
      } else {
        this.hiddenInput.value = attributes.signed_id

        this.emitDropzoneSuccess()

        if (this.source.submitAfterUploadValue) {
          if (this.source.hasImageFiltersTarget) {
            this.source.imageFiltersTarget.classList.add('d-none')
            this.source.loadingTarget.classList.remove('d-none')
          }
          if (this.source.hasPreviewInputTarget) { this.source.previewInputTarget.value = 'true' }

          this.source.formSubmissionController.submit()

          if (this.source.hasPreviewInputTarget) { this.source.previewInputTarget.value = 'false' }
        }
      }
    })
  }

  createHiddenInput () {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = this.source.inputTarget.name
    insertAfter(input, this.source.inputTarget)
    return input
  }

  directUploadWillStoreFileWithXHR () {
    this.emitDropzoneUploading()
  }

  emitDropzoneUploading () {
    this.file.status = Dropzone.UPLOADING
    this.source.dropZone.emit('processing', this.file)
  }

  emitDropzoneError (error) {
    this.file.status = Dropzone.ERROR
    this.source.dropZone.emit('error', this.file, error)
    this.source.dropZone.emit('complete', this.file)
  }

  emitDropzoneSuccess () {
    this.file.status = Dropzone.SUCCESS
    this.source.dropZone.emit('success', this.file)
    this.source.dropZone.emit('complete', this.file)
  }
}

// Top level...
function createDirectUploadController (source, file) {
  return new DirectUploadController(source, file)
}

function createDirectUpload (file, url, controller) {
  return new DirectUpload(file, url, controller)
}

function createDropZone (controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false,
    previewTemplate: controller.template,
    thumbnailWidth: controller.thumbnailWidth,
    thumbnailHeight: controller.thumbnailHeight,
    previewsContainer: controller.previewContainerName,
    clickable: '.dz-clickable',
    thumbnailMethod: 'contain'
  })
}
