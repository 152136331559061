import { Controller } from '@hotwired/stimulus'
import { useHotkeys } from 'stimulus-use'

export default class HighlightModalFormController extends Controller {
  static targets = ['form', 'closeButton']
  static values = {
    closeUrl: String
  }

  connect () {
    useHotkeys(this, {
      esc: [this.handleEscapeKeypress]
    })

    this.modalElement.addEventListener(
      'mousedown',
      this.handleModalBackdropClick.bind(this)
    )

    window.initializeTooltip()
  }

  disconnect () {
    this.modalElement.removeEventListener(
      'mousedown',
      this.handleModalBackdropClick.bind(this)
    )
  }

  handleEscapeKeypress (e) {
    e.preventDefault()
    this.showDismissalConfirmationModal()
  }

  handleModalBackdropClick (mouseDownEv) {
    const initialTarget = mouseDownEv.target
    this.modalElement.addEventListener('mouseup', mouseUpEv => {
      const endTarget = mouseUpEv.target
      if (endTarget === initialTarget && endTarget === this.modalElement) {
        mouseUpEv.preventDefault()
        mouseUpEv.stopPropagation()
        this.showDismissalConfirmationModal()
      }
    })
  }

  showDismissalConfirmationModal () {
    if (this.closeUrlValue) {
      this.modalContainer.src = this.closeUrlValue
    } else {
      this.closeButtonTarget.click()
    }
  }

  saveDraft (e) {
    $('[data-toggle="tooltip"]').tooltip('hide')
    const submitButton = e.target.closest('button[type="submit"]')
    const overrideAction = submitButton.getAttribute('formaction')
    const overrideMethod = submitButton.getAttribute('formmethod')
    this.formTarget.setAttribute('action', overrideAction)
    this.formTarget.setAttribute('method', overrideMethod)
  }

  publishHighlight () {
    this.formTarget.setAttribute('data-turbo-frame', '_top')
  }

  get modalElement () {
    return this.element.closest('.modal')
  }

  get modalContainer () {
    return this.element.closest('#modal_container')
  }
}
