import { StradaController } from '../strada_controller'

export default class extends StradaController {
  static targets = ['item', 'menuButton']

  displayNativeMenu (menuData) {
    if (!this.enabled) return

    this.send('connect', menuData, message => {
      const selectedItem = menuData.actions.find(action => action.tag === message.data.selectedTag)
      if (selectedItem) this.onActionSelected(selectedItem)
    })
  }

  buildActionsArray () {
    return this.itemTargets.map((itemTarget, index) => {
      return {
        tag: index,
        title: itemTarget.textContent.length > 1 ? itemTarget.textContent.trim() : itemTarget.value.trim(),
        icon_name: itemTarget.getAttribute('data-icon-name'),
        style: itemTarget.getAttribute('data-item-style') || 'none' // destructive | cancel | none
      }
    })
  }
}
