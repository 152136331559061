import { Controller } from '@hotwired/stimulus'

export default class NewBadgeDisplayController extends Controller {
  static targets = [
    'badgeName',
    'badgeIcon',
    'badgeColor',
    'inputBadgeIconId',
    'inputName',
    'selectBadgeIconButton',
    'colorSwatchContainer'
  ]

  static values = {
    badgeClass: String,
    badgeSubmitId: String
  }

  connect () {
    this.updateBadgeIconURL()
    this.setSubmitButtonState()
  }

  get selectedColorSwatch () {
    return this.colorSwatchContainerTarget.querySelector(
      'input[type="radio"]:checked'
    )
  }

  get formCanBeSubmitted () {
    return (
      this.inputNameTarget.value &&
      this.inputBadgeIconIdTarget.value &&
      this.selectedColorSwatch !== null
    )
  }

  get submitButton () {
    return document.querySelector(this.badgeSubmitIdValue)
  }

  setSubmitButtonState () {
    this.submitButton.disabled = !this.formCanBeSubmitted
  }

  updateBadgeName () {
    this.badgeNameTarget.innerHTML = this.inputNameTarget.value
    this.updateBadgeIconURL()
    this.setSubmitButtonState()
  }

  updateBadgeColor (e) {
    const cssClass = `${this.badgeClassValue}-${e.target.value}`
    this.removeBadgeColor()
    this.addBadgeColor(cssClass)
    if (this.hasBadgeIconTarget) this.updateBadgeIconColor()
    this.updateBadgeIconURL()
    this.setSubmitButtonState()
  }

  removeBadgeColor () {
    $(this.badgeColorTarget).removeClass(
      function (index, css) {
        const regex = new RegExp(
          '(^|\\s)' + this.badgeClassValue + '\\S+',
          'g'
        )
        return (css.match(regex) || []).join(' ')
      }.bind(this)
    )
    this.badgeColorTarget.classList.remove('active')
  }

  addBadgeColor (badgeClass) {
    this.badgeColorTarget.classList.add(badgeClass)
    this.badgeColorTarget.classList.add('active')
  }

  updateBadgeIconColor () {
    this.badgeIconTarget.classList.remove('fill-inverse-of-base')
    this.badgeIconTarget.classList.add('fill-white')
  }

  updateBadgeIconURL () {
    const url = new URL(this.selectBadgeIconButtonTarget.href)
    const { searchParams } = url

    // Set badge icon color
    const checkedBadgeIcon = this.selectedColorSwatch

    if (checkedBadgeIcon) {
      searchParams.set('badge_color', checkedBadgeIcon.value)
    }

    // Set badge icon name
    searchParams.set('badge_name', this.inputNameTarget.value)

    url.search = searchParams.toString()
    const updatedUrl = url.toString()
    this.selectBadgeIconButtonTarget.href = updatedUrl
  }
}
