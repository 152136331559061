import { Controller } from '@hotwired/stimulus'
import { keyCodes } from '../utils/keyboard'

export default class PostFieldNavigationController extends Controller {
  static targets = ['title', 'description']

  handleTitleKeyPress (event) {
    if ([keyCodes.DOWN_ARROW, keyCodes.ENTER].includes(event.keyCode)) {
      event.preventDefault()
      this.descriptionTarget.__quill.focus()
    }
  }

  handleDescriptionKeyPress (event) {
    if (!this.hasTitleTarget) {
      return
    }

    const range = this.descriptionTarget.__quill.getSelection()

    if (
      event.keyCode === keyCodes.UP_ARROW &&
      range && range.length === 0 && range.index === 0
    ) {
      event.preventDefault()
      this.titleTarget.focus()
    }
  }
}
