import { Controller } from '@hotwired/stimulus'

export default class CopyToClipboardController extends Controller {
  static targets = ['field']

  copyToClipboard () {
    let text

    if (this.fieldTarget.tagName === 'INPUT') {
      text = this.fieldTarget.value
    } else {
      text = this.fieldTarget.innerText
    }

    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData('Text', text)
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported('copy')
    ) {
      const textarea = document.createElement('textarea')
      textarea.textContent = text
      // Prevent scrolling to bottom of page in Microsoft Edge.
      textarea.style.position = 'fixed'
      this.fieldTarget.parentNode.insertBefore(textarea, this.fieldTarget)
      textarea.select()
      try {
        // Security exception may be thrown by some browsers.
        return document.execCommand('copy')
      } catch (ex) {
        return false
      } finally {
        this.fieldTarget.parentNode.removeChild(textarea)
      }
    }
  }
}
