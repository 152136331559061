import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class EmploymentTypeSelectController extends Controller {
  static targets = ['organisationDropdown']
  static values = {
    employmentTypeUrl: String
  }

  change (event) {
    const employmentType = event.target.value
    const url = this.employmentTypeUrlValue

    get(url, {
      query: { employment_type: employmentType },
      responseKind: 'turbo-stream'
    })
  }
}
