import { Controller } from '@hotwired/stimulus'

export default class PaginateWithFormDataWhenVisibleController extends Controller {
  static values = {
    page: Number,
    endpoint: String
  }

  connect () {
    const form = this.element.closest('form')

    this.whenVisible(this.element, () => {
      const formData = new FormData(form)
      const asString = new URLSearchParams(formData).toString()
      const newURL = this.endpointValue + `?page=${this.pageValue}` + '&' + asString

      this.element.src = newURL
    })
  }

  whenVisible (element, callback) {
    new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          callback(element)
          observer.disconnect()
        }
      })
    }).observe(element)
  }
}
