import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class RequestOnModalCloseController extends Controller {
  static values = {
    requestPath: String
  }

  connect () {
    $('.modal').on(
      'hide.bs.modal',
      this.sendRequest.bind(this)
    )
  }

  sendRequest () {
    patch(
      this.requestPathValue,
      { body: { dismissed_modal: true } }
    )
  }
}
