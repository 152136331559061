import { Controller } from '@hotwired/stimulus'
import consumer from 'channels/consumer'

export default class NavbarNotificationsController extends Controller {
  static targets = [
    'unreadConversationsIcon',
    'allConversationsReadIcon',
    'unreadConversationCounter'
  ]

  static values = {
    profileId: String
  }

  static classes = ['hideIcon']

  connect () {
    if (!this.profileIdValue) return

    this.unreadConversationsSubscription = consumer.subscriptions.create(
      {
        channel: 'UnreadConversationsChannel',
        id: this.profileIdValue
      },
      {
        // eslint-disable-next-line no-underscore-dangle
        connected: this._connected.bind(this),
        // eslint-disable-next-line no-underscore-dangle
        disconnected: this._disconnected.bind(this),
        // eslint-disable-next-line no-underscore-dangle
        received: this._received.bind(this)
      }
    )
  }

  // eslint-disable-next-line class-methods-use-this
  _connected () {}

  // eslint-disable-next-line class-methods-use-this
  _disconnected () {}

  _received (unreadConversationsCount) {
    if (this.hasUnreadConversationCounterTarget) {
      this.unreadConversationCounterTarget.innerHTML = unreadConversationsCount
    }

    if (unreadConversationsCount > 0) {
      this.unreadConversationsIconTarget.classList.remove(this.hideIconClass)
      this.allConversationsReadIconTarget.classList.add(this.hideIconClass)
    } else {
      this.unreadConversationsIconTarget.classList.add(this.hideIconClass)
      this.allConversationsReadIconTarget.classList.remove(this.hideIconClass)
    }
  }
}
