import { Controller } from '@hotwired/stimulus'

export default class HighlightFormActivitiesController extends Controller {
  static targets = [
    'selectedActivitiesSection',
    'selectedActivitiesContainer',
    'suggestedActivitiesSection',
    'suggestionsToggle',
    'selectedActivity'
  ]

  static values = {
    hasSelectedActivities: Boolean
  }

  static classes = ['hiddenSection']

  // eslint-disable-next-line class-methods-use-this
  connect () {
    this.initializeSections()
    this.createSelectedActivitiesObserver()
  }

  createSelectedActivitiesObserver () {
    const observer = new MutationObserver(this.resyncSections.bind(this))
    observer.observe(this.selectedActivitiesContainerTarget, {
      childList: true
    })
  }

  resyncSections () {
    if (this.selectedActivityTargets.length) {
      this.displaySelectedActivities()
    } else {
      this.displaySuggestedActivities()
    }
  }

  initializeSections () {
    if (this.hasSelectedActivitiesValue) {
      this.displaySelectedActivities()
    } else {
      this.displaySuggestedActivities()
    }
  }

  displaySelectedActivities () {
    this.selectedActivitiesSectionTargets.forEach(el => {
      el.classList.remove(this.hiddenSectionClass)
    })
    this.suggestionsToggleTarget.classList.add(this.hiddenSectionClass)
    this.suggestedActivitiesSectionTargets.forEach(el => {
      el.classList.add(this.hiddenSectionClass)
    })
  }

  displaySuggestedActivities () {
    this.suggestedActivitiesSectionTargets.forEach(el => {
      el.classList.remove(this.hiddenSectionClass)
    })
    this.suggestionsToggleTarget.classList.remove(this.hiddenSectionClass)
    this.selectedActivitiesSectionTargets.forEach(el => {
      el.classList.add(this.hiddenSectionClass)
    })
  }
}
