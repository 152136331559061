import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { getClosestElementFor } from '../utils/dom'

export default class CollectionCardNavigationController extends Controller {
  static values = {
    navigationUrl: String
  }

  cardClick (e) {
    if (
      window.getSelection().toString().length !== 0 ||
      $(e.target).is('a') ||
      getClosestElementFor('.dropdown')(e.target)
    ) {
      return
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(this.navigationUrlValue, '_blank')
    } else {
      Turbo.visit(this.navigationUrlValue)
    }
  }
}
