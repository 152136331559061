import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class ModalOverflowController extends Controller {
  static targets = [
    'modalBody',
    'modalBodySpacerContainer',
    'modalFooter',
    'modalContent'
  ]

  static classes = ['hidden', 'footerOverflow']

  connect () {
    this.applyOverflowStyling()
    useMutation(this, { childList: true, subtree: true })
  }

  mutate (entries) {
    entries.forEach(mutation => {
      if (mutation.target.id === 'modal_content') {
        return this.applyOverflowStyling()
      }
    })
  }

  get bodyOverflowing () {
    return (
      this.modalBodyTarget.clientHeight < this.modalBodyTarget.scrollHeight
    )
  }

  get spacerVisible () {
    return (
      this.hasModalFooterTarget &&
      this.modalBodySpacerContainerTarget.childElementCount > 0
    )
  }

  addBodySpacer () {
    if (!this.spacerVisible) {
      const spacer = document.createElement('div')
      const spacerHeight = this.modalFooterTarget.offsetHeight
      spacer.setAttribute('class', 'modal-body-spacer')
      spacer.setAttribute('style', `height: ${spacerHeight}px`)
      this.modalBodyTarget.appendChild(spacer)
    }
  }

  removeBodySpacer () {
    this.modalBodySpacerContainerTarget.innerHTML = ''
  }

  addOverflowStyling () {
    if (
      !this.hasModalFooterTarget ||
      this.modalFooterTarget.classList.contains(this.footerOverflowClass)
    ) {
      return
    }

    this.addBodySpacer()
    this.modalFooterTarget.classList.add(this.footerOverflowClass)
  }

  removeOverflowStyling () {
    this.removeBodySpacer()
    this.modalFooterTarget.classList.remove(this.footerOverflowClass)
  }

  applyOverflowStyling () {
    if (!this.hasModalBodyTarget || !this.hasModalFooterTarget) return

    if (this.bodyOverflowing) {
      this.addOverflowStyling()
    } else {
      this.removeOverflowStyling()
    }
  }
}
