import { Controller } from '@hotwired/stimulus'

export default class TogglePanelController extends Controller {
  static targets = ['visiblePanel', 'hiddenPanel']
  static classes = ['visible']

  showPanel () {
    this.hiddenPanelTarget.classList.add(this.visibleClass)
    this.visiblePanelTarget.classList.remove(this.visibleClass)
  }

  hidePanel () {
    this.hiddenPanelTarget.classList.remove(this.visibleClass)
    this.visiblePanelTarget.classList.add(this.visibleClass)
  }
}
