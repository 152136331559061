import { Controller } from '@hotwired/stimulus'

export default class RequiresElementsController extends Controller {
  static targets = [
    'container',
    'submitButton'
  ]

  static values = {
    elementIdentifier: String
  }

  connect () {
    this.checkSubmitButton()
    const observer = new MutationObserver(this.checkSubmitButton.bind(this))
    observer.observe(this.containerTarget, {
      subtree: true,
      childList: true,
      attributes: true
    })
  }

  get hasElements () {
    return this.containerTarget.querySelectorAll(this.elementIdentifierValue).length > 0
  }

  checkSubmitButton () {
    if (this.hasElements) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-enabled')
    } else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-disabled')
    }
  }
}
