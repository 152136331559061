import { Controller } from '@hotwired/stimulus'

export default class PostMessageOnActionController extends Controller {
  static values = {
    message: String
  }

  post () {
    window.postMessage(this.messageValue, '*')
  }
}
