import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['spinner', 'replace']

  show () {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.remove('d-none')
    }

    if (this.hasReplaceTarget) {
      this.replaceTarget.classList.add('d-none')
    }
  }

  hide () {
    if (this.hasSpinnerTarget) {
      this.spinnerTarget.classList.add('d-none')
    }

    if (this.hasReplaceTarget) {
      this.replaceTarget.classList.remove('d-none')
    }
  }
}
