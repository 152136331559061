import { Controller } from '@hotwired/stimulus'

const intersectionObserverAvailable = 'IntersectionObserver' in window
const globalLazyComponents = []
let globalObserver

let invokeLazyLoadingTimeout
function invokeLazyLoading () {
  if (invokeLazyLoadingTimeout) {
    clearTimeout(invokeLazyLoadingTimeout)
  }

  invokeLazyLoadingTimeout = setTimeout(() => {
    globalLazyComponents.forEach(component => {
      if (component.isViewable()) {
        component.view()
      }
    })
  }, 20)
}

if (intersectionObserverAvailable) {
  globalObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const image = entry.target
        image.component.view()
        observer.unobserve(image)
      }
    })
  })
} else {
  document.addEventListener('scroll', invokeLazyLoading)
  window.addEventListener('resize', invokeLazyLoading)
  window.addEventListener('orientationChange', invokeLazyLoading)
}

export default class LazyImageLoaderController extends Controller {
  static values = {
    src: String
  }

  connect () {
    this.viewed = false

    if (intersectionObserverAvailable) {
      this.element.component = this
      globalObserver.observe(this.element)
    } else {
      if (this.isViewable()) {
        this.view()
      } else {
        globalLazyComponents.push(this)
      }
    }
  }

  disconnect () {
    const index = globalLazyComponents.indexOf(this)

    if (index > -1) {
      globalLazyComponents.splice(index, 1)
    }
  }

  isViewable () {
    return !this.viewed && this.element.offsetTop < (window.innerHeight + window.pageYOffset)
  }

  view () {
    this.element.src = this.srcValue
    this.viewed = true
  }
}
