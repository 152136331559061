import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['previewInput']

  updatePreview (event) {
    event.preventDefault()

    if (this.hasPreviewInputTarget) { this.previewInputTarget.value = 'true' }

    this.formSubmissionController.submit()

    if (this.hasPreviewInputTarget) { this.previewInputTarget.value = 'false' }
  }

  get formSubmissionController () {
    return this.application.controllers.find(controller => controller.controllerName === 'form-submission')
  }
}
