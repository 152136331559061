import { Controller } from '@hotwired/stimulus'

export default class SidebarTrayController extends Controller {
  static classes = ['expanded']
  static targets = ['previousSearches', 'userListicle']

  connect () {
    if (this.hasNotBeenPreviouslyLoaded) {
      setTimeout(() => {
        this.setPreviousSearchContainerHeight()
      }, 0)

      if (window.screen.width >= 1024) {
        this.element.classList.add('--no-transition')
        this.expandTray()
        setTimeout(() => {
          this.element.classList.remove('--no-transition')
        }, 200)
      }
    }

    document.addEventListener('turbo:load', this.updateSelectedLink)
    this.updateSelectedLink()
    this.element.classList.add('--loaded')
  }

  get hasNotBeenPreviouslyLoaded () {
    return !this.element.classList.contains('--loaded')
  }

  expandTray () {
    this.element.classList.add(this.expandedClass)
    document.body.classList.add('tw-overflow-hidden', 'md:tw-overflow-auto')
  }

  collapseTray () {
    this.element.classList.remove(this.expandedClass)
    document.body.classList.remove('tw-overflow-hidden', 'md:tw-overflow-auto')
  }

  setPreviousSearchContainerHeight () {
    if (!this.hasUserListicleTarget) return

    const maxHeight = this.userListicleTarget.offsetTop - this.previousSearchesTarget.offsetTop - 16
    this.previousSearchesTarget.style.maxHeight = `${maxHeight}px`
  }

  updateSelectedLink () {
    const currentPath = window.location.pathname
    const selectedLinks = document.querySelectorAll('.--selected')
    const linkElement = document.querySelector(`a[href="${currentPath}"]`)

    selectedLinks.forEach(function (selectedLink) {
      selectedLink.classList.remove('--selected')
    })

    if (linkElement) linkElement.classList.add('--selected')
  }
}
