import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class OnboardingBadgesTitleController extends Controller {
  static targets = [
    'title',
    'subtitle',
    'selectedBadgeNames'
  ]

  static values = {
    title: String,
    overflowBadgesTitle: String,
    subtitle: String,
    overflowBadgesSubtitle: String
  }

  connect () {
    useMutation(this, {
      element: this.selectedBadgeNamesTarget,
      childList: true
    })
  }

  get badgeCountFromSelectedBadgeNamesTarget () {
    return Array.from(this.selectedBadgeNamesTarget.children).map(child => child.innerHTML).length
  }

  changeTitle () {
    if (this.badgeCountFromSelectedBadgeNamesTarget >= 3) {
      this.titleTarget.innerHTML = this.overflowBadgesTitleValue
      this.subtitleTarget.innerHTML = this.overflowBadgesSubtitleValue
    } else {
      this.titleTarget.innerHTML = this.titleValue
      this.subtitleTarget.innerHTML = this.subtitleValue
    }
  }

  mutate (entries) {
    this.changeTitle()
  }
}
