import { Controller } from '@hotwired/stimulus'
import { keyCodes } from '../utils/keyboard'

export default class KeyboardNavigationForList extends Controller {
  static targets = ['list', 'listItem', 'input']
  static classes = ['selected']
  static values = {
    selectLastElementOnEnter: Boolean
  }

  connect () {
    this.inputTarget.addEventListener('keydown', this.navigateViaKeyboard)
  }

  selectListItem (e) {
    this.removeSelectedClassFromTarget(this.currentlySelectedItem)
    this.addSelectedClassToTarget(e.target)
  }

  deselectListItem (e) {
    this.removeSelectedClassFromTarget(e.target)
  }

  get currentlySelectedItem () {
    return this.listItemTargets.find(target =>
      target.classList.contains(this.selectedClass)
    )
  }

  get indexOfCurrentlySelectedItem () {
    return this.listItemTargets.indexOf(this.currentlySelectedItem)
  }

  get itemAboveCurrentlySelectedItem () {
    return this.listItemTargets[this.indexOfCurrentlySelectedItem - 1]
  }

  get itemBelowCurrentlySelectedItem () {
    return this.listItemTargets[this.indexOfCurrentlySelectedItem + 1]
  }

  moveSelectedItemUp () {
    const currentItem = this.currentlySelectedItem
    this.addSelectedClassToTarget(this.itemAboveCurrentlySelectedItem)
    this.removeSelectedClassFromTarget(currentItem)
  }

  moveSelectedItemDown () {
    const currentItem = this.currentlySelectedItem
    if (currentItem) {
      this.addSelectedClassToTarget(this.itemBelowCurrentlySelectedItem)
      this.removeSelectedClassFromTarget(currentItem)
    } else {
      this.addSelectedClassToTarget(this.listItemTargets[0])
    }
  }

  addSelectedClassToTarget (target) {
    if (target) {
      target.classList.add(this.selectedClass)
    }
  }

  removeSelectedClassFromTarget (target) {
    if (target) {
      target.classList.remove(this.selectedClass)
    }
  }

  navigateViaKeyboard = event => {
    switch (event.keyCode) {
      case keyCodes.ENTER: {
        const currentItem = this.currentlySelectedItem
        if (currentItem) {
          currentItem.click()
        } else if (
          this.selectLastElementOnEnterValue &&
          this.listItemTargets[this.listItemTargets.length - 1]
        ) {
          this.listItemTargets[this.listItemTargets.length - 1].click()
        }
        break
      }
      case keyCodes.UP_ARROW: {
        event.preventDefault()
        this.moveSelectedItemUp()
        break
      }
      case keyCodes.DOWN_ARROW: {
        event.preventDefault()
        this.moveSelectedItemDown()
        break
      }
      default: {
        break
      }
    }
  }
}
