import BaseImage from 'quill/formats/image'

class Image extends BaseImage {
  static create (value) {
    const node = super.create(value)

    return node
  }
}

Image.className = 'custom-quill-image'

export default Image
