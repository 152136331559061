import { Controller } from '@hotwired/stimulus'

export default class RequiresSelectionController extends Controller {
  static targets = [
    'toggle',
    'submitButton'
  ]

  connect () {
    this.checkSubmitButton()
  }

  get hasSelectedAtLeastOneOption () {
    return Array.from(this.toggleTargets).some(toggle => toggle.checked)
  }

  checkSubmitButton () {
    if (this.hasSelectedAtLeastOneOption) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-enabled')
    } else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-disabled')
    }
  }
}
