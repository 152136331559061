import { Controller } from '@hotwired/stimulus'

export default class DisableButtonController extends Controller {
  static targets = ['textField']
  static values = {
    buttonId: String,
    minLength: Number
  }

  connect () {
    this.disableButton()
  }

  get submitButton () {
    return document.querySelector(this.buttonIdValue)
  }

  disableButton () {
    const disabledValue =
      this.textFieldTarget.value.length < this.minLengthValue
    this.submitButton.disabled = disabledValue
  }
}
