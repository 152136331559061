import { StradaController } from './strada_controller'
import { Turbo } from '@hotwired/turbo-rails'
import { post } from '@rails/request.js'
import { isEmpty } from 'lodash'

export default class extends StradaController {
  static component = 'contacts-list'
  static values = {
    navigateOnConnect: Boolean,
    navigateUrl: String,
    permissionUrl: String,
    importUrl: String,
    fetchOnConnect: Boolean
  }

  static targets = [
    'openSettingsPromptButton'
  ]

  connect () {
    super.connect()
    if (!this.enabled) return

    if (this.navigateOnConnectValue) {
      this.checkPermission().then(data => {
        if (data.status === 'authorized') {
          this.navigateToContactsScreen()
        }
      })
    }

    if (this.fetchOnConnectValue) {
      this.fetchContacts()
    }
  }

  requestPermission () {
    console.log('requesting permission...')

    this.send('request_permission', {}, ({ data }) => {
      console.log('request permission response', data)
      if (data.success) {
        this.navigateToContactsScreen()
      } else {
        this.promptToOpenSettings()
      }
    })
  }

  async checkPermission () {
    console.log('checking permission...')
    return new Promise((resolve) => {
      this.send('check_permission', {}, ({ data }) => {
        console.log('check permission status', data)
        resolve(data)
      })
    })
  }

  fetchContacts () {
    this.send('fetch_contacts', {}, ({ data }) => {
      console.log('fetch contacts response', data)
      if (data.success && this.hasImportUrlValue) {
        post(this.importUrlValue, {
          body: JSON.stringify({ contacts: this.filterContacts(data.contacts) }),
          contentType: 'application/json',
          responseKind: 'turbo-stream'
        })
      }

      if (!data.success) {
        this.navigateToRequestPermissionScreen()
      }
    })
  }

  navigateToContactsScreen () {
    if (this.hasNavigateUrlValue) {
      console.log('contacts-list: performing navigation', this.navigateUrlValue)
      Turbo.visit(this.navigateUrlValue, { action: 'advance' })
    }
  }

  navigateToRequestPermissionScreen () {
    if (this.hasPermissionUrl) {
      console.log('contacts-list: performing navigation', this.permissionUrl)
      Turbo.visit(this.permissionUrl, { action: 'advance' })
    }
  }

  filterContacts (contacts) {
    const filtered = contacts.filter(contact => {
      if (isEmpty(contact.given_name) && isEmpty(contact.family_name) && isEmpty(contact.company)) {
        return false
      }
      return true
    })

    console.log('filtered contacts', filtered)

    return filtered
  }

  promptToOpenSettings () {
    if (this.hasOpenSettingsPromptButtonTarget) {
      this.openSettingsPromptButtonTarget.click()
    }
  }
}
