import $ from 'jquery'
import { Controller } from '@hotwired/stimulus'
import { hasVariant } from '../utils/request'

const firstHashRegex = /^#/

export default class TabsController extends Controller {
  static values = {
    prefix: String,
    disableNavigation: Boolean
  }

  static targets = ['tabButton']

  initialize () {
    this.onHashChange = this.onHashChange.bind(this)
  }

  connect () {
    window.addEventListener('hashchange', this.onHashChange)
    this.onHashChange()
  }

  disconnect () {
    window.removeEventListener('hashchange', this.onHashChange)
  }

  // eslint-disable-next-line class-methods-use-this
  showTab (e) {
    e.preventDefault()

    this.removeActiveTabClasses()

    $(e.currentTarget).tab('show')

    if (!hasVariant('ios') && !this.disableNavigationValue) {
      const newHash = e.currentTarget.href
        .split('#')[1]
        .replace(this.prefixValue, '')

      window.location.hash = newHash
    }
  }

  navigateToTab (e) {
    e.preventDefault()

    const targetTabHref = e.currentTarget.getAttribute('href')
    const target = this.tabButtonTargets.find(target =>
      target.getAttribute('href') === targetTabHref
    )
    target.click()
  }

  removeActiveTabClasses () {
    this.tabButtonTargets.forEach(tabTarget =>
      tabTarget.classList.remove('active')
    )
  }

  onHashChange (e) {
    const hashArray = window.location.hash
      .replace(firstHashRegex, '')
      .split(':') // ^ means starting, meaning only match the first hash

    // Show appropriate tab
    if (hashArray[0].length > 0) {
      const match = this.prefixValue + hashArray[0]
      this.tabButtonTargets.forEach(tabTarget => {
        const name = tabTarget.href.split('#')[1]
        if (name === match) {
          this.removeActiveTabClasses()
          $(tabTarget).tab('show')
        }
      })
    }
  }
}
