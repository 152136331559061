import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class AssistantPopoverCardController extends Controller {
  static targets = ['hover', 'popover']

  static classes = ['hidden']

  connect () {
    this.popperInstance = createPopper(this.hoverTarget, this.popoverTarget, {
      placement: 'right-start',
      modifiers: [
        {
          name: 'offset',
          options: { offset: [0, 24] }
        }
      ]
    })
  }

  showPopover () {
    this.popoverTarget.classList.remove(this.hiddenClass)
    this.popperInstance.update()
  }

  hidePopover () {
    this.popoverTarget.classList.add(this.hiddenClass)
  }
}
