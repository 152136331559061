import { Controller } from '@hotwired/stimulus'

export default class ClearUrlQueryController extends Controller {
  static values = {
    paramNames: Array
  }

  clearParams () {
    const url = new URL(window.location)
    this.paramNamesValue.forEach(paramName => {
      url.searchParams.delete(paramName)
    })
    window.history.pushState(window.history.state, null, url)
  }
}
