import { Controller } from '@hotwired/stimulus'
import 'bootstrap-datepicker'
import dayJS from 'dayjs'
import { originatesFrom } from '../utils/events'

const originatesFromDatePicker = originatesFrom(
  '.dropdown-highlight-date-picker'
)
const originatesFromDropDown = originatesFrom('.dropdown-item')

export default class PostHighlightTypeController extends Controller {
  static targets = [
    'datePicker',
    'futureDatePicker',
    'endDate',
    'planned',
    'retroButtonTextContainer',
    'retroEndDateText',
    'plannedDateText',
    'plannedDateIcon',
    'realTimeButtonTextContainer',
    'form',
    'leftChevronSvgTag',
    'rightChevronSvgTag'
  ]

  static classes = ['hidden', 'retro', 'realTime', 'planned']

  static values = {
    plannedTextPrefix: String
  }

  connect () {
    this.initializeDatePicker()
    this.initializePlannedDatePicker()
    document.addEventListener('click', this.handleOutsideClick, false)
  }

  // eslint-disable-next-line class-methods-use-this
  initializeDatePicker () {
    $('#highlight-date')
      .datepicker({
        ...this.defaultDatePickerConfiguration,
        endDate: '0d',
        startDate: '1 1, 1900'
      })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => {
        this.setEndDateValue(e)
        this.setButtonTextToRetroText(e)
        this.clearPlannedValue()
        this.hideDatePicker()
        this.addRetroClassToForm()
      })
  }

  // eslint-disable-next-line class-methods-use-this
  initializePlannedDatePicker () {
    $('#highlight-future-date')
      .datepicker({
        ...this.defaultDatePickerConfiguration,
        endDate: '1 1, 3000',
        startDate: this.tomorrowsDate
      })
      .on('hide', e => this.preventModalPropagation(e))
      .on('changeDate', e => {
        this.setEndDateValue(e)
        this.setButtonTextToPlannedText(e)
        this.setPlannedValue()
        this.hideFutureDatePicker()
        this.addPlannedClassToForm()
      })
  }

  get defaultDatePickerConfiguration () {
    return {
      format: 'M d, yyyy',
      startView: 'year',
      minViewMode: 0,
      maxViewMode: 2,
      orientation: 'bottom left',
      autoclose: true,
      templates: {
        leftArrow: this.leftChevronSvgTagTarget.innerHTML,
        rightArrow: this.rightChevronSvgTagTarget.innerHTML
      }
    }
  }

  get tomorrowsDate () {
    const date = new Date()
    date.setDate(date.getDate() + 1)
    return date
  }

  // eslint-disable-next-line class-methods-use-this
  preventModalPropagation (e) {
    e.stopPropagation()
  }

  showDatePicker () {
    this.datePickerTarget.classList.remove(this.hiddenClass)
  }

  hideDatePicker () {
    this.datePickerTarget.classList.add(this.hiddenClass)
  }

  get isDatePickerShown () {
    return !this.datePickerTarget.classList.contains(this.hiddenClass)
  }

  showFutureDatePicker () {
    this.futureDatePickerTarget.classList.remove(this.hiddenClass)
  }

  hideFutureDatePicker () {
    this.futureDatePickerTarget.classList.add(this.hiddenClass)
  }

  get isFutureDatePickerShown () {
    return !this.futureDatePickerTarget.classList.contains(this.hiddenClass)
  }

  hideAllDatePickers () {
    this.hideDatePicker()
    this.hideFutureDatePicker()
  }

  anyDatePickerShown () {
    return this.isDatePickerShown || this.isFutureDatePickerShown
  }

  // eslint-disable-next-line class-methods-use-this
  isOutsideClick (event) {
    return !originatesFromDatePicker(event) && !originatesFromDropDown(event)
  }

  handleOutsideClick = event => {
    if (this.anyDatePickerShown() && this.isOutsideClick(event)) {
      this.hideAllDatePickers()
    }
  }

  setButtonTextToRetroText (e) {
    this.retroEndDateTextTarget.innerHTML = dayJS(e.date).format('MMM D, YYYY')
  }

  setButtonTextToPlannedText (e) {
    const dateText = dayJS(e.date).format('MMM D, YYYY')
    if (this.hasPlannedDateIcon) {
      this.plannedDateIconTarget.classList.replace('fill-muted', 'fill-green')
    }
    this.plannedDateTextTarget.classList.replace('text-muted', 'text-green')
    this.plannedDateTextTarget.innerHTML = [this.plannedTextPrefixValue, dateText].join(' ')
  }

  setPlannedValue () {
    this.plannedTarget.value = true
  }

  setEndDateValue (e) {
    this.endDateTarget.value = dayJS(e.date).format('DD-MM-YYYY')
  }

  clearEndDateValue () {
    this.endDateTarget.value = ''
  }

  clearPlannedValue () {
    this.plannedTarget.value = ''
  }

  setTypeToRealTimeHighlight () {
    this.clearEndDateValue()
    this.clearPlannedValue()
    this.addRealTimeClassToForm()
  }

  addRetroClassToForm () {
    this.formTarget.classList.remove(this.realTimeClass)
    this.formTarget.classList.remove(this.plannedClass)
    this.formTarget.classList.add(this.retroClass)
  }

  addRealTimeClassToForm () {
    this.formTarget.classList.remove(this.retroClass)
    this.formTarget.classList.remove(this.plannedClass)
    this.formTarget.classList.add(this.realTimeClass)
  }

  addPlannedClassToForm () {
    this.formTarget.classList.remove(this.retroClass)
    this.formTarget.classList.remove(this.realTimeClass)
    this.formTarget.classList.add(this.plannedClass)
  }
}
