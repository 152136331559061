import { Controller } from '@hotwired/stimulus'
import { useHotkeys } from 'stimulus-use'

export default class MultiPageModalFormController extends Controller {
  static targets = [
    'form',
    'closeButton'
  ]

  static values = {
    closeUrl: String
  }

  connect () {
    useHotkeys(this, {
      esc: [this.handleEscapeKeypress]
    })

    this.modalElement.addEventListener(
      'mousedown',
      this.handleModalBackdropClick.bind(this)
    )

    window.initializeTooltip()
  }

  disconnect () {
    if (this.modalElement) {
      this.modalElement.removeEventListener(
        'mousedown',
        this.handleModalBackdropClick.bind(this)
      )
    }
  }

  handleEscapeKeypress (e) {
    e.preventDefault()
    this.clickCloseButton()
  }

  handleModalBackdropClick (mouseDownEv) {
    const initialTarget = mouseDownEv.target
    this.modalElement.addEventListener('mouseup', mouseUpEv => {
      const endTarget = mouseUpEv.target
      if (endTarget === initialTarget && endTarget === this.modalElement) {
        mouseUpEv.preventDefault()
        mouseUpEv.stopPropagation()
        this.clickCloseButton()
      }
    })
  }

  clickCloseButton () {
    $('[data-toggle="tooltip"]').tooltip('hide')
    if (this.closeUrlValue) {
      this.modalContainer.src = this.closeUrlValue
    } else {
      this.closeButtonTarget.click()
    }
  }

  showModal (event) {
    const showId = event.target.closest('[data-show-id]').getAttribute('data-show-id')

    $(`#${showId}`)[0].classList.remove('d-none')
  }

  hideModal (event) {
    const hideId = event.target.closest('[data-hide-id]').getAttribute('data-hide-id')

    $(`#${hideId}`)[0].classList.add('d-none')
  }

  saveAndRedirect (e) {
    $('[data-toggle="tooltip"]').tooltip('hide')
    const submitButton = e.target.closest('button')
    const overrideAction = submitButton.getAttribute('formaction')
    const overrideMethod = submitButton.getAttribute('formmethod')
    this.formTarget.setAttribute('action', overrideAction)
    this.formTarget.setAttribute('method', overrideMethod)
    submitButton.setAttribute('type', 'submit')
  }

  setTurboFrameTop () {
    this.formTarget.setAttribute('data-turbo-frame', '_top')
  }

  get modalElement () {
    return this.element.closest('.modal')
  }

  get modalContainer () {
    return this.element.closest('#modal_container')
  }
}
