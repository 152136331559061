import { StradaController } from './strada_controller'

export default class extends StradaController {
  static component = 'apple-purchase'
  static values = {
    title: String,
    subtitle: String,
    buttonCta: String,
    annualProduct: Object,
    monthlyProduct: Object
  }

  manageSubscription (event) {
    event.preventDefault() && event.stopImmediatePropagation()
    this.send('manage', {})
  }

  initiatePurchaseFlow (event) {
    event.preventDefault() && event.stopImmediatePropagation()
    this.send('purchase', this.paymentFlowJSONData)
  }

  get paymentFlowJSONData () {
    return {
      title: this.titleValue,
      subtitle: this.subtitleValue,
      ctaText: this.buttonCtaValue,
      products: [this.monthlyProductValue, this.annualProductValue]
    }
  }
}
