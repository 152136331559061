import { Controller } from '@hotwired/stimulus'

export default class TurboFrameModalController extends Controller {
  connect () {
    this.element.addEventListener('turbo:before-fetch-response', (event) => {
      if (typeof (event.detail.fetchResponse) !== 'undefined') {
        const transition = event.detail.fetchResponse.response.headers.get('X-Turbo-Transition')

        if (transition) {
          event.preventDefault()
          Turbo.visit(transition)
        }
      }
    })
  }
}
