import { Controller } from '@hotwired/stimulus'

export default class ProfileTemplatePreviewRevealOnScrollController extends Controller {
  connect () {
    window.addEventListener('message-to-event-handler:last-profile-template-preview-loaded', this.initializeController.bind(this))
  }

  initializeController (event) {
    setTimeout(() => {
      this.initializeScrollThresholdListener()
      this.determineIfScrollListenerNeeded()
    }, 750)
  }

  listenForBottomOfPage () {
    const scrollHeight = document.body.scrollHeight
    const currentScroll = window.scrollY + window.innerHeight
    const buffer = 40
    if (currentScroll + buffer > scrollHeight) {
      this.addRevealTrigger()
      this.removeBottomOfPageScrollListener()
      this.postRevealCompleteMessage()
    }
  }

  determineIfScrollListenerNeeded () {
    if (this.isScrollNeeded) {
      window.addEventListener('scroll', this.listenForBottomOfPage.bind(this))
    } else {
      this.addRevealTrigger()
      setTimeout(() => {
        this.postRevealCompleteMessage()
      }, 3000)
    }
  }

  get isScrollNeeded () {
    const clientHeight = document.documentElement.clientHeight
    const scrollHeight = document.documentElement.scrollHeight
    return scrollHeight > clientHeight
  }

  initializeScrollThresholdListener () {
    const options = {
      root: window.document,
      rootMargin: '0px 0px -50% 0px'
    }

    const observer = new IntersectionObserver(this.checkIfElementCrossedThreshold.bind(this), options)
    observer.observe(this.element)
  }

  checkIfElementCrossedThreshold (entries, observer) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        this.addRevealTrigger()
        observer.unobserve(this.element)
        this.removeBottomOfPageScrollListener()
      }
    })
  }

  addRevealTrigger () {
    this.element.classList.add('trigger-animation')
  }

  removeBottomOfPageScrollListener () {
    window.removeEventListener('scroll', this.listenForBottomOfPage)
  }

  postRevealCompleteMessage () {
    window.postMessage('polywork:message-to-parent:profile-template-reveal-complete', '*')
    window.postMessage('polywork:message-to-event:profile-template-reveal-complete', '*')
  }
}
