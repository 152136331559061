import { StradaController } from './strada_controller'

export default class extends StradaController {
  static component = 'open-settings'

  open (event) {
    if (!this.enabled) return

    if (event) {
      event.stopImmediatePropagation()
      event.preventDefault()
    }

    this.send('connect')
  }
}
