import { StradaController } from './strada_controller'
import { hideElement } from '../../utils/dom'

export default class extends StradaController {
  static component = 'nav-button'
  static targets = ['click', 'hide']

  connect () {
    super.connect()
    if (!this.enabled) return

    this.hide()

    this.send('connect', this.buttonItem, () => {
      this.elementToClick.click()
    })
  }

  enableButton () {
    if (!this.enabled) return

    const props = { ...this.buttonItem, isEnabled: true }
    this.send('connect', props, () => {
      this.elementToClick.click()
    })
  }

  disableButton () {
    if (!this.enabled) return

    const props = { ...this.buttonItem, isEnabled: false }
    this.send('connect', props, () => {
      this.elementToClick.click()
    })
  }

  hide () {
    if (this.hasHideTarget) {
      hideElement(this.hideTarget)
    }
  }

  get elementToClick () {
    if (this.hasClickTarget) {
      return this.clickTarget
    } else {
      return this.element
    }
  }

  get buttonItem () {
    const title = this.bridgeElement.title
    const iconName = this.bridgeElement.bridgeAttribute('icon-name')
    const style = this.bridgeElement.style
    const position = this.bridgeElement.bridgeAttribute('position') || 'right'
    const isEnabled = this.bridgeElement.bridgeAttribute('enabled') !== 'false'
    return { title, iconName, style, isEnabled, position }
  }
}
