export const keyCodes = {
  SPACEBAR: 32,
  ENTER: 13,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  ESCAPE: 27,
  BACK_SPACE: 8,
  TAB: 9
}
export { keyCodes as default }
