import Timeago from 'stimulus-timeago'
import { parseISO, format, isToday, isThisYear } from 'date-fns'

// custom formats based on en-US
import enPolyworkShort from '../utils/timeago_locales/en-PolyworkShort/index'
import enPolyworkLong from '../utils/timeago_locales/en-PolyworkLong/index'

export default class extends Timeago {
  // Override to control whether timestamp is formatted as a date vs "X time ago"
  load () {
    const datetime = parseISO(this.datetimeValue)

    if (this.formatAsDate(datetime)) {
      if (isThisYear(datetime)) {
        this.element.textContent = format(datetime, 'LLL d') // e.g. Jan 1
      } else {
        this.element.textContent = format(datetime, 'LLL d, yyyy') // e.g. Jan 1, 2021
      }

      return
    }

    // let Timeago display the "X time ago" format
    super.load()
  }

  // Return true if we should display the date instead the "X time ago" format
  formatAsDate (datetime) {
    if (datetime === 'Invalid Date') {
      return false
    }

    if (isToday(datetime)) {
      return false
    }

    return this.element.dataset.timeagoTodayOnly === 'true'
  }

  // Override getter to use our custom short/long locale
  get locale () {
    if (this.element.dataset.timeagoAbbreviated === 'true') {
      return enPolyworkShort
    } else {
      return enPolyworkLong
    }
  }
}
