import { Controller } from '@hotwired/stimulus'
import { useMutation } from 'stimulus-use'

export default class TextExpanderController extends Controller {
  static targets = ['expander', 'body']
  static values = {
    maxHeight: Number,
    constrainedClass: String,
    carouselId: String
  }

  connect () {
    if (this.carouselIdValue) this.setCarouselEventListener()
    this.initializeTextExpander()
    useMutation(this, { attributes: false, childList: true, subtree: true })
  }

  setCarouselEventListener () {
    $(`#${this.carouselIdValue}`).on(
      'slid.bs.carousel',
      this.initializeTextExpander.bind(this)
    )
  }

  mutate (entries) {
    for (const mutation of entries) {
      if (mutation.type === 'childList') {
        this.initializeTextExpander()
      }
    }
  }

  initializeTextExpander () {
    this.bodyTarget.style.removeProperty('max-height')
    const maxHeight = this.maxHeightValue

    if (this.element.offsetHeight > maxHeight) {
      this.element.classList.add(this.constrainedClassValue)
      this.bodyTarget.style.maxHeight = `${maxHeight}px`

      if (this.hasExpanderTarget) {
        this.expanderTarget.addEventListener('click', (event) => {
          event.preventDefault()
          this.element.classList.remove(this.constrainedClassValue)
          this.bodyTarget.style.removeProperty('max-height')
        })
      }
    }
  }
}
