import { Controller } from '@hotwired/stimulus'

export default class TaggingsDisplayController extends Controller {
  static targets = ['container']
  static classes = ['hiddenPanel', 'item']

  connect () {
    const observer = new MutationObserver(
      this.checkForTaggedItems.bind(this)
    )
    observer.observe(this.containerTarget, {
      childList: true
    })

    this.checkForTaggedItems()
  }

  get hasTaggedItems () {
    return (
      this.containerTarget.querySelector(`.${this.itemClass}`) !== null
    )
  }

  checkForTaggedItems () {
    if (this.hasTaggedItems) {
      this.element.classList.remove(this.hiddenPanelClass)
    } else {
      this.element.classList.add(this.hiddenPanelClass)
    }
  }
}
