import { Controller } from '@hotwired/stimulus'

export default class ConversationController extends Controller {
  static values = { triggerEvent: Boolean }

  // eslint-disable-next-line class-methods-use-this
  connect () {
    if (this.triggerEventValue) {
      const event = new CustomEvent('conversation:connected', {
        bubbles: true,
        cancelable: true
      })
      document.dispatchEvent(event)
    }
  }
}
