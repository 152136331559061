import { StradaController } from './strada_controller'

export default class extends StradaController {
  static component = 'share'
  static targets = ['url']

  share (event) {
    if (!this.enabled) return

    event.stopImmediatePropagation()
    event.preventDefault()

    const url = this.urlTarget.innerText

    this.send('share', { url })
  }
}
