import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { getClosestElementFor } from '../utils/dom'

export default class ComponentNavigationController extends Controller {
  static values = {
    url: String,
    frameTarget: String
  }

  get frameTarget () {
    return document.querySelector(`#${this.frameTargetValue}`)
  }

  componentClick (e) {
    if (
      window.getSelection().toString().length !== 0 ||
      $(e.target).is('a') ||
      getClosestElementFor('a.avatar-background')(e.target) ||
      getClosestElementFor('a')(e.target) ||
      getClosestElementFor('button')(e.target)
    ) {
      return
    }

    if (this.frameTargetValue) {
      this.frameTarget.src = this.urlValue
    } else if (e.ctrlKey || e.metaKey) {
      window.open(this.urlValue, '_blank')
    } else {
      Turbo.visit(this.urlValue)
    }
  }
}
