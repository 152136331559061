export function getClosestElementFor (selector) {
  return function getSelfOrClosest (target) {
    if (target.matches(selector)) {
      return target
    }
    return target.closest(selector)
  }
}

export function getMeta (name) {
  const element = document.head.querySelector(`meta[name="${name}"]`)
  return element && element.content
}

export function hideElement (element) {
  element.style.setProperty('display', 'none', 'important')
}
