import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class RefreshTemplatePreviewController extends Controller {
  static targets = ['tabs']
  static values = {
    url: String,
    refreshOnConnect: Boolean
  }

  static classes = ['loading']

  connect () {
    if (this.refreshOnConnectValue) { this.refresh() }
  }

  async refresh (event) {
    this.tabsTargets.forEach(tabTarget => tabTarget.classList.add(this.loadingClass))
    const request = new FetchRequest('patch', this.urlValue, { responseKind: 'turbo-stream' })
    await request.perform()
  }
}
