import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class AnnouncementModalController extends Controller {
  static targets = ['announcement', 'indicator', 'counter', 'dismiss', 'next', 'done']

  initialize () {
    this.announcementCount = this.announcementTargets.length
    this.announcementIndex = 0

    this.disableTurbo()
    this.reloadModal()
  }

  reloadModal () {
    this.showAnnouncement()
    this.updateFooter()
    this.markRead()
  }

  next () {
    if (this.viewingLastAnnouncement()) {
      this.announcementIndex = 0
    } else {
      this.announcementIndex += 1
    }

    this.reloadModal()
  }

  done () {
    this.dismissTarget.click()
  }

  viewingLastAnnouncement () {
    return (this.announcementIndex + 1) === this.announcementCount
  }

  showAnnouncement () {
    this.announcementTargets.forEach((announcement, index) => {
      if (this.announcementIndex === index) {
        announcement.classList.replace('d-none', 'd-block')
      } else {
        announcement.classList.replace('d-block', 'd-none')
      }
    })
  }

  updateFooter () {
    this.indicatorTargets.forEach((indicator, index) => {
      if (this.announcementIndex === index) {
        indicator.style.opacity = 1
      } else {
        indicator.style.opacity = 0.2
      }
    })

    if (this.hasCounterTarget) {
      this.counterTarget.textContent = this.announcementIndex + 1
    }

    if (this.viewingLastAnnouncement()) {
      this.nextTarget.classList.replace('d-flex', 'd-none')
      this.doneTarget.classList.replace('d-none', 'd-flex')
    } else {
      this.nextTarget.classList.replace('d-none', 'd-flex')
      this.doneTarget.classList.replace('d-flex', 'd-none')
    }
  }

  markRead () {
    const url = `/announcements/${this.currentAnnouncement().dataset.announcementId}/mark_read`
    post(url, { responseKind: 'turbo-stream' })
  }

  currentAnnouncement () {
    return this.announcementTargets[this.announcementIndex]
  }

  disableTurbo () {
    this.announcementTargets.forEach(announcement => {
      announcement.querySelectorAll('a').forEach(link => {
        link.dataset.turbo = false
      })
    })
  }
}
