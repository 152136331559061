import BaseClipboard from 'quill/modules/clipboard'
import Delta from 'quill-delta'
import Quill from 'quill/core'
import { VALID_LINK_REGEX } from '../../dynamic_content_editor_controller'

class Clipboard extends BaseClipboard {
  constructor (quill, options) {
    super(quill, options)
    this.quill.root.addEventListener('paste', this.onCapturePaste.bind(this))
  }

  onCapturePaste (e) {
    e.preventDefault()

    const { dynamicContentEditorController } = this.quill.options

    const files = Array.from(e.clipboardData.files || [])

    if (files.length) {
      return files.forEach((file) => {
        dynamicContentEditorController.saveToServerAndInsertInEditor(file)
      })
    }

    const range = this.getRange()
    const html = e.clipboardData.getData('text/html')
    const text = e.clipboardData.getData('text/plain')

    this.onPaste(range, { html, text })
  }

  onPaste (range, data) {
    if (!data) { return }

    const { dynamicContentEditorController } = this.quill.options
    const { html = '', text } = data

    if (VALID_LINK_REGEX.test(text)) {
      return dynamicContentEditorController.insertLink({ range, text, link: text, insertUnfurled: true })
    }

    const textIsSelected = range.length > 0

    if (textIsSelected) {
      this.quill.deleteText(range.index, range.length)
    }

    if (html !== '') {
      const formats = this.quill.getFormat(range.index)
      const pastedDelta = this.convert(html, formats)
      const delta = new Delta().retain(range.index).concat(pastedDelta)
      this.quill.updateContents(delta, Quill.sources.USER)
    } else {
      this.quill.insertText(range.index, text)
    }

    this.quill.setSelection(range.index + text.length, 0)
  }

  getRange () {
    const range = this.quill.getSelection()

    if (range) { return range }

    this.quill.focus()
    return this.quill.getSelection()
  }
}

export default Clipboard
