import { Controller } from '@hotwired/stimulus'

export default class VerificationCodeController extends Controller {
  static targets = [
    'input'
  ]

  connect () {
    this.inputTargets[0].focus()
  }

  insertValue (event) {
    const inputTarget = event.currentTarget
    const inputIndex = this.inputTargets.indexOf(inputTarget)
    const [firstCharacter, ...remainingCharacters] = inputTarget.value
    const isLastInput = (inputIndex + 1) === this.inputTargets.length

    // first character will be undefined when backspace pressed
    if (firstCharacter === undefined) {
      inputTarget.value = ''
      return
    } else {
      inputTarget.value = firstCharacter
    }

    if (!isLastInput) {
      const nextInput = this.inputTargets[inputIndex + 1]

      // continue to input the remaining string
      nextInput.focus()
      nextInput.value = remainingCharacters.join('')
      nextInput.dispatchEvent(new Event(event.type))
    }
  }

  backspace (event) {
    const inputTarget = event.currentTarget
    const inputIndex = this.inputTargets.indexOf(inputTarget)
    const isFirstInput = inputIndex === 0

    // focus previous input when backspace pressed and current input value blank
    if (!isFirstInput && event.keyCode === 8 && inputTarget.value === '') {
      const previousInput = this.inputTargets[inputIndex - 1]
      previousInput.focus()
    }
  }
}
