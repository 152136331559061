import { Controller } from '@hotwired/stimulus'

export default class RequiresChoiceSelectedController extends Controller {
  static targets = [
    'select',
    'submitButton'
  ]

  connect () {
    this.checkSubmitButton()
  }

  get hasSelectedOption () {
    return this.selectTarget.querySelector('select').value !== ''
  }

  checkSubmitButton () {
    if (this.hasSelectedOption) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-enabled')
    } else {
      this.submitButtonTarget.disabled = true
      this.submitButtonTarget.classList.add('disabled')
      this.submitButtonTarget.setAttribute('data-test', 'submit-button-disabled')
    }
  }
}
