import { Controller } from '@hotwired/stimulus'

export default class ScrollToController extends Controller {
  static values = {
    elementId: String
  }

  connect () {
    if (this.elementIdValue) {
      this.scrollToElement()
    }
  }

  scrollToElement () {
    const element = document.getElementById(this.elementIdValue)
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
