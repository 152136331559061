import { Controller } from '@hotwired/stimulus'

export default class StyleguideController extends Controller {
  // eslint-disable-next-line class-methods-use-this
  connect () {
    $('#base-tab a').on('click', function (e) {
      e.preventDefault()
      $(this).tab('show')
    })
    $('#chip-tab a').on('click', function (e) {
      e.preventDefault()
      $(this).tab('show')
    })
    $('.toast').toast('show')
    window.initializeTooltip()
    $('#focusedInputExample').trigger('focus')
  }
}
