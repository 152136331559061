import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'
import { getClosestElementFor } from '../utils/dom'

export const HIGHLIGHT_CARD_CSS = '.highlight-card'
export const HIGHLIGHT_CARD_HOVER_CLASS = 'highlight-card-hover'

export default class CardNavigationController extends Controller {
  // eslint-disable-next-line class-methods-use-this
  static values = {
    navigationUrl: String,
    frameTarget: String
  }

  static targets = ['clickElement']

  connect () {
    this.element.addEventListener('mouseover', this.handleHover.bind(this))
    this.element.addEventListener(
      'mouseleave',
      this.removeHoverState.bind(this)
    )
  }

  disconnect () {
    this.element.removeEventListener('mouseover', this.handleHover)
    this.element.removeEventListener('mouseleave', this.removeHoverState)
  }

  get frameTarget () {
    return document.querySelector(`#${this.frameTargetValue}`)
  }

  // eslint-disable-next-line class-methods-use-this
  handleHover (event) {
    event.stopPropagation()
    const highlightCard = event.target.closest(HIGHLIGHT_CARD_CSS)
    if (
      highlightCard &&
      !highlightCard.classList.contains(HIGHLIGHT_CARD_HOVER_CLASS)
    ) {
      document
        .getElementsByClassName(HIGHLIGHT_CARD_HOVER_CLASS)
        .forEach(el => el.classList.remove(HIGHLIGHT_CARD_HOVER_CLASS))

      highlightCard.classList.add(HIGHLIGHT_CARD_HOVER_CLASS)
    }
  }

  // eslint-disable-next-line class-methods-use-this
  removeHoverState () {
    this.element.classList.remove(HIGHLIGHT_CARD_HOVER_CLASS)
  }

  cardClick (e) {
    if (
      window.getSelection().toString().length !== 0 ||
      $(e.target).is('a') ||
      $(e.target).is('textarea') ||
      $(e.target).is('input') ||
      getClosestElementFor('a.avatar-background')(e.target) ||
      getClosestElementFor('.dropdown')(e.target) ||
      getClosestElementFor('.expander-tile')(e.target) ||
      getClosestElementFor('a')(e.target)
    ) return

    if (this.frameTargetValue) {
      this.frameTarget.src = this.navigationUrlValue
    } else if (e.ctrlKey || e.metaKey) {
      window.open(this.navigationUrlValue, '_blank')
    } else if (this.hasClickElementTarget) {
      this.clickElementTarget.click()
    } else {
      Turbo.visit(this.navigationUrlValue)
    }
  }
}
