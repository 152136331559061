import BlockEmbed from 'quill/blots/embed'

const HTML = `
<div class="loading-image-placeholder">
  <div class="spinner-border" />
</div>
`

class LoadingImagePlaceholder extends BlockEmbed {
  static create (value) {
    const node = super.create(value)

    node.insertAdjacentHTML('afterbegin', HTML)

    return node
  }
}

LoadingImagePlaceholder.blotName = 'loadingImagePlaceholder'
LoadingImagePlaceholder.className = 'loading-image-placeholder-container'
LoadingImagePlaceholder.tagName = 'div'

export default LoadingImagePlaceholder
