import { Controller } from '@hotwired/stimulus'

export default class MultiProfilePreviewIFrameLoaderController extends Controller {
  static targets = ['loadingIcon']
  static values = {
    srcs: [String],
    iframeWrapperId: String,
    descriptionWrapperId: String,
    iframeClasses: String,
    newIframeId: String
  }

  static classes = ['loading']

  connect () {
    this.srcsValue.map((src, idx) => (
      this.loadNewIframe(src, idx)
    ))
  }

  checkLoadedState () {
    const previewIframes = document.querySelectorAll(`#${this.iframeWrapperIdValue} > iframe.--animated-profile-preview-iframe`)

    const allLoaded = Array.from(previewIframes).every((previewIframe) => previewIframe.classList.contains('--loaded'))
    if (allLoaded) {
      const profilePlaceholder = document.querySelectorAll(`#${this.iframeWrapperIdValue} > #profile-placeholder`)
      const previewDescriptions = document.querySelectorAll(`#${this.descriptionWrapperIdValue} > .profile-preview-template-description`)

      $(profilePlaceholder).addClass('--inactive')
      previewIframes.forEach((loadedPreviewIframe, idx) => {
        setTimeout(() => {
          $(loadedPreviewIframe).removeClass('--staged')

          if ((idx + 1) === previewIframes.length) {
            // Post a message-to-sidebar so we show the editor tab preemptively for tooltip tour
            window.postMessage('polywork:message-to-sidebar:switch-to-blocks-tab', '*')
            // Post a message-to-event for the top level window
            window.postMessage('polywork:message-to-event:last-profile-template-preview-loaded', '*')
            // Post a message-to-event for this specific iframe
            loadedPreviewIframe.contentWindow.postMessage('polywork:message-to-event:last-profile-template-preview-loaded', '*')
          } else {
            $(loadedPreviewIframe.contentDocument.body).addClass('trigger-animation--750ms-delay')
            setTimeout(() => {
              $(loadedPreviewIframe).addClass('--inactive')
            }, 2000)
          }

          previewDescriptions.forEach((description) => {
            $(description).addClass('d-none')
            $(description).removeClass('d-flex')
          }
          )
          const correspondingDescription = document.querySelector(`#${this.descriptionWrapperIdValue} > #profile-preview-template-description--index-${idx}`)
          $(correspondingDescription).addClass('d-flex')
          $(correspondingDescription).removeClass('d-none')
        }, 2000 * idx)
      })
    }
  }

  loadNewIframe (src, idx) {
    let lastClass
    let lastId
    if ((idx + 1) === this.srcsValue.length) {
      lastClass = '--last'
      lastId = this.newIframeIdValue
    }
    const newIframe = $('<iframe>', {
      src,
      class: `${this.iframeClassesValue} --animated-profile-preview-iframe --staged ${lastClass}`,
      id: lastId
    })

    const iframeWrapper = $(`#${this.iframeWrapperIdValue}`)
    iframeWrapper.append(newIframe)
    newIframe.on('load', () => {
      newIframe.addClass('--loaded')
      this.checkLoadedState()
    })
  }
}
