import { StradaController } from './strada_controller'
import Quill from 'quill/core'

export default class extends StradaController {
  static component = 'input-accessory-view'
  static values = {
    formats: [],
    selectedButtons: [],
    connected: false
  }

  onFocus () {
    if (!this.enabled) { return }

    if (!this.connectedValue) {
      this.connectedValue = true
      this.editorController = this.application.controllers.find(controller => controller.controllerName === 'content-editor')
      this.editor = this.editorController.editor

      this.send('connect', { buttonTypes: this.formatsValue }, ({ data }) => {
        const { buttonType, selectedImages } = data

        if (buttonType) {
          this.handleButtonClick(buttonType)
        } else if (selectedImages) {
          this.handleImagesUpload(selectedImages)
        }
      })
    }

    this.highlightButtonIfFormatted()
  }

  highlightButtonIfFormatted () {
    if ($('.ql-editor a:hover')[0]) {
      $('#ql-link')[0]?.classList?.add('ql-active')
    } else {
      $('#ql-link')[0]?.classList?.remove('ql-active')
    }

    return this.formatsValue.map((format) => {
      if ($(`#ql-${format}.ql-active`)[0]) {
        if (!this.selectedButtonsValue.includes(format)) {
          this.addButtonTypeToSelectedButtons(format)
        }
      } else if (this.selectedButtonsValue.includes(format)) {
        this.removeButtonTypeFromSelectedButtons(format)
      }

      return null
    })
  }

  onBlur () {
    if (!this.enabled) { return }

    if (this.connectedValue) {
      this.connectedValue = false

      this.selectedButtonsValue = []
      this.send('setSelected', { buttonTypes: [] }, () => {})
      this.send('disconnect', {}, () => {})
    }
  }

  handleButtonClick (buttonType) {
    if (buttonType === 'link') {
      let range = this.editor.getSelection()
      let text = this.editor.getText(range)
      let link = null

      const activeLink = $('.ql-editor a:hover')[0]

      if (activeLink) {
        text = activeLink.innerText
        link = activeLink.href

        const blot = Quill.find(activeLink)
        const blotIndex = this.editor.getIndex(blot)

        this.editor.setSelection(blotIndex, text.length)
        range = this.editor.getSelection()
      }

      this.send(
        'editLink',
        { text, link },
        ({ data }) => this.editorController.insertLink({ range, text: data.text, link: data.link })
      )
    } else {
      if (this.selectedButtonsValue.includes(buttonType)) {
        this.removeButtonTypeFromSelectedButtons(buttonType)
      } else {
        this.addButtonTypeToSelectedButtons(buttonType)
      }

      $(`#ql-${buttonType}`).click()
    }
  }

  handleImagesUpload (selectedImages) {
    return selectedImages.forEach((imageBase64) => {
      this.editorController.insertBase64Image(imageBase64)
    })
  }

  addButtonTypeToSelectedButtons (buttonType) {
    this.selectedButtonsValue = this.selectedButtonsValue.concat([buttonType])
    this.send('setSelected', { buttonTypes: this.selectedButtonsValue }, () => {})
  }

  removeButtonTypeFromSelectedButtons (buttonType) {
    this.selectedButtonsValue = this.selectedButtonsValue.filter((selectedButton) => selectedButton !== buttonType)
    this.send('setSelected', { buttonTypes: this.selectedButtonsValue }, () => {})
  }
}
