import { Controller } from '@hotwired/stimulus'

export default class AlternatingSlidersController extends Controller {
  static targets = ['container', 'slider']
  static values = {
    speed: Number
  }

  initialize () {
    this.animating = false
  }

  connect () {
    window.addEventListener('scroll', () => {
      if (this.animating || !this.isInViewport()) {
        return
      }
      this.currentScroll = window.scrollY
      this.targetScroll = window.scrollY
      this.xCurrent = this.sliderTarget.getBoundingClientRect().left

      this.animateElements()
      this.animating = true
    })
  }

  // eslint-disable-next-line class-methods-use-this
  isEven (index) {
    return index % 2 === 0
  }

  animateElements () {
    this.sliderTargets.forEach((slider, idx) => {
      if (this.isEven(idx)) {
        this.animateElement(slider)
      } else {
        this.animateElement(slider, true)
      }
    })
  }

  animateElement (target, reverse = false) {
    if (!this.isInViewport()) {
      this.animating = false
      return
    }

    this.currentScroll = parseFloat(
      this.linearInterpolator(this.currentScroll, this.targetScroll, 0.3)
    ).toFixed(2)
    this.targetScroll = window.scrollY

    let yDiff = (this.targetScroll - this.currentScroll) * this.speedValue

    if (reverse) {
      yDiff = -yDiff
    }

    this.xCurrent = target.getBoundingClientRect().left + yDiff

    target.style.left = `${this.xCurrent}px`

    window.requestAnimationFrame(() => {
      this.animateElement(target, reverse)
    })
  }

  // eslint-disable-next-line class-methods-use-this
  linearInterpolator (start, end, t) {
    return start * (1 - t) + end * t
  }

  isInViewport () {
    const rect = this.containerTarget.getBoundingClientRect()
    return (
      rect.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.bottom >= 0
    )
  }
}
