import { Controller } from '@hotwired/stimulus'

export default class ShowHiddenController extends Controller {
  static targets = ['hidden', 'showButton']

  show () {
    this.showButtonTarget.classList.add('d-none')
    this.hiddenTargets.forEach((hidden) => {
      hidden.classList.remove('d-none')
    })
  }
}
