import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    url: String
  }

  connect () {
    if (this.urlValue) {
      return this.unfurlLink({ element: this.element, link: this.urlValue })
    } else {
      const element = this.element.querySelector('.url-embed')

      if (!element) { return }

      return this.unfurlLink({ element, link: element.getAttribute('data-unfurler-url-value') })
    }
  }

  async unfurlLink ({ element, link }) {
    if (element.getAttribute('data-unfurled')) { return }

    element.setAttribute('data-unfurled', true)

    element.getElementsByTagName('div').forEach((div) => {
      if (div.innerHTML === '') {
        div.remove()
      }
    })

    const data = await get('/embeds', { query: { link, small: true, format: 'json' } })
    const json = await data.json

    if (json.html) {
      return element.insertAdjacentHTML('beforeend', json.html)
    } else {
      return element.remove()
    }
  }
}
