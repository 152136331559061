import Rails from '@rails/ujs'
import NativeMenuController from './shared/native_menu_controller'

export default class extends NativeMenuController {
  static component = 'nav-menu'
  static values = {
    title: String,
    description: String,
    iconName: String
  }

  connect () {
    if (!this.enabled) return

    this.onStreamRender = this.onStreamRender.bind(this)

    this.displayNativeMenu(this.buildMenuData())

    if (this.hasMenuButtonTarget) {
      this.menuButtonTarget.classList.add('d-none')
    }

    document.addEventListener('turbo:before-stream-render', this.onStreamRender, false)
  }

  disconnect () {
    if (!this.enabled) return

    document.removeEventListener('turbo:before-stream-render', this.onStreamRender, false)
  }

  buildMenuData () {
    return {
      title: this.titleValue,
      description: this.descriptionValue,
      icon_name: this.iconNameValue,
      actions: this.buildActionsArray()
    }
  }

  onActionSelected (actionItem) {
    Rails.fire(this.itemTargets[actionItem.tag], 'click')
  }

  // Redisplay the menu in case menu items have changed.
  onStreamRender () {
    window.requestAnimationFrame(() => {
      this.displayNativeMenu(this.buildMenuData())
    })
  }
}
