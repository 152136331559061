import { Controller } from '@hotwired/stimulus'

const breakpoint = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200
}

export default class CarouselController extends Controller {
  static targets = ['carousel', 'previousSlideButton', 'nextSlideButton']
  static classes = ['visibleButton']
  static values = {
    breakpointXlSlideScroll: Number,
    breakpointXlSlideCount: Number,
    breakpointLgSlideCount: Number,
    breakpointMdSlideCount: Number,
    breakpointSmSlideCount: Number,
    breakpointXsSlideCount: Number,
    breakpointXlCenterPadding: String,
    breakpointLgCenterPadding: String,
    breakpointMdCenterPadding: String,
    breakpointSmCenterPadding: String,
    breakpointXsCenterPadding: String,
    hidePreviousSlideButtonOnInit: Boolean,
    variableWidthXl: Boolean,
    variableWidthLg: Boolean,
    variableWidthMd: Boolean,
    variableWidthSm: Boolean,
    variableWidthXs: Boolean,
    infinite: Boolean,
    centerMode: Boolean,
    singleMode: Boolean,
    autoplay: Boolean,
    autoplayMode: Boolean,
    autoplaySpeed: Number,
    reloadCollapseSelector: String,
    reloadClickSelector: String,
    lazyLoaded: Boolean,
    showDots: Boolean
  }

  connect () {
    if (this.lazyLoadedValue) {
      this.element.addEventListener('carousel:loaded', () => {
        this.initializeCarousel()
      })
    } else {
      this.initializeCarousel()
    }

    if (this.hasReloadCollapseSelectorValue) {
      this.reloadOnCollapseShow()
    }

    if (this.hasReloadClickSelectorValue) {
      this.reloadOnClick()
    }

    this.element[this.identifier] = this
  }

  disconnect () {
    if (this.hasCarouselTarget) {
      this.unslick()
    }
  }

  reloadOnCollapseShow () {
    $(this.reloadCollapseSelectorValue).on('show.bs.collapse', () => {
      this.reload()
    })
  }

  reloadOnClick () {
    const clickElement = document.querySelector(this.reloadClickSelectorValue)

    if (clickElement !== null) {
      clickElement.addEventListener('click', () => {
        this.reload()
      })
    }
  }

  reload () {
    this.unslick()
    this.initializeCarousel()
  }

  unslick () {
    $(this.carouselTarget).slick('unslick')
  }

  initializeCarousel () {
    if (this.singleModeValue) {
      $(this.carouselTarget).slick({
        dots: false,
        arrows: false,
        draggable: false
      })

      document.addEventListener('carousel:nextSlide', () => {
        this.nextSlide()
      })

      document.addEventListener('carousel:previousSlide', () => {
        this.previousSlide()
      })
    } else if (this.autoplayModeValue) {
      $(this.carouselTarget).slick({
        dots: false,
        arrows: false,
        draggable: false,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: this.autoplaySpeedValue ? this.autoplaySpeedValue : 1000,
        centerMode: true
      })
    } else {
      $(this.carouselTarget).slick({
        autoplay: this.autoplayValue ? this.autoplayValue : false,
        autoplaySpeed: this.autoplaySpeedValue ? this.autoplaySpeedValue : 1000,
        centerMode: this.hasCenterModeValue ? this.centerModeValue : false,
        dots: this.hasShowDotsValue ? this.showDotsValue : false,
        infinite: this.hasInfiniteValue ? this.infiniteValue : true,
        speed: 500,
        arrows: false,
        lazyLoad: 'onDemand',
        variableWidth: this.hasVariableWidthXlValue
          ? this.variableWidthXlValue
          : false,
        slidesToShow: this.breakpointXlSlideCountValue || 3,
        slidesToScroll: this.breakpointXlSlideCountValue || 3,
        responsive: [
          {
            breakpoint: breakpoint.lg,
            settings: {
              slidesToShow: this.breakpointLgSlideCountValue || 2,
              slidesToScroll: this.breakpointLgSlideCountValue || 2,
              centerMode: this.hasCenterModeValue ? this.centerModeValue : true,
              centerPadding: this.hasBreakpointSmCenterPaddingValue ? this.breakpointSmCenterPaddingValue : '10px',
              variableWidth: this.hasVariableWidthLgValue
                ? this.variableWidthLgValue
                : false
            }
          },
          {
            breakpoint: breakpoint.md,
            settings: {
              slidesToShow: this.breakpointMdSlideCountValue || 1,
              slidesToScroll: this.breakpointMdSlideCountValue || 1,
              centerMode: this.hasCenterModeValue ? this.centerModeValue : true,
              centerPadding: this.hasBreakpointMdCenterPaddingValue ? this.breakpointMdCenterPaddingValue : '48px',
              variableWidth: this.hasVariableWidthMdValue
                ? this.variableWidthMdValue
                : false
            }
          },
          {
            breakpoint: breakpoint.sm,
            settings: {
              slidesToShow: this.breakpointSmSlideCountValue || 1,
              slidesToScroll: this.breakpointSmSlideCountValue || 1,
              centerMode: this.hasCenterModeValue ? this.centerModeValue : true,
              centerPadding: this.hasBreakpointSmCenterPaddingValue ? this.breakpointSmCenterPaddingValue : '24px',
              variableWidth: this.hasVariableWidthSmValue
                ? this.variableWidthSmValue
                : false
            }
          },
          {
            breakpoint: breakpoint.xs,
            settings: {
              slidesToShow: this.breakpointXsSlideCountValue || 1,
              slidesToScroll: this.breakpointXsSlideCountValue || 1,
              centerMode: this.hasCenterModeValue ? this.centerModeValue : true,
              centerPadding: this.hasBreakpointXsCenterPaddingValue ? this.breakpointXsCenterPaddingValue : '16px',
              variableWidth: this.hasVariableWidthXsValue
                ? this.variableWidthXsValue
                : false
            }
          }
        ]
      })

      if (this.hidePreviousSlideButtonOnInitValue) this.toggleProgressButtons()

      document.addEventListener('carousel:updated', () => {
        this.setProgressButtonState(null, $(this.carouselTarget).slick('getSlick'))
      })
    }
  }

  toggleProgressButtons () {
    $(this.carouselTarget).on(
      'afterChange',
      this.setProgressButtonState.bind(this)
    )
  }

  setProgressButtonState (_, slick) {
    if (this.hasPreviousSlideButtonTarget) this.setPreviousSlideToggleState(slick)
    if (this.hasNextSlideButtonTarget) this.setNextSlideToggleState(slick)
  }

  setPreviousSlideToggleState (slick) {
    if (slick.currentSlide === 0) {
      this.previousSlideButtonTarget.classList.remove(this.visibleButtonClass)
    } else {
      this.previousSlideButtonTarget.classList.add(this.visibleButtonClass)
    }
  }

  setNextSlideToggleState (slick) {
    const finalRotationSlide =
      slick.slideCount - (this.breakPointSlideCount() || 3)

    if (slick.currentSlide >= finalRotationSlide) {
      this.nextSlideButtonTarget.classList.remove(this.visibleButtonClass)
    } else {
      this.nextSlideButtonTarget.classList.add(this.visibleButtonClass)
    }
  }

  nextSlide () {
    $(this.carouselTarget).slick('slickNext')
  }

  previousSlide () {
    $(this.carouselTarget).slick('slickPrev')
  }

  breakPointSlideCount () {
    return this[`breakpoint${this.currentBreakPointSize}SlideCountValue`]
  }

  get currentBreakPointSize () {
    const windowWidth = window.innerWidth

    for (const size of Object.keys(breakpoint)) {
      if (windowWidth <= breakpoint[size]) {
        return size[0].toUpperCase() + size.slice(1)
      }
    };

    return 'Xl'
  }
}
