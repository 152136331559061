import { Controller } from '@hotwired/stimulus'

export default class ColorSwatchController extends Controller {
  toggleChecked (e) {
    const radioButton = e.target
      .closest('label')
      .querySelector('input[type="radio"]')

    if (radioButton.checked) {
      e.preventDefault()
      radioButton.checked = false
    }
  }
}
