import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'
import debounce from 'lodash/debounce'

export default class ProfileHoverCardController extends Controller {
  static targets = ['container']

  connect () {
    this.hide = debounce(this.hide, 200)
  }

  show (e) {
    const cardPath = e.target.dataset.cardUrlPath

    if (!cardPath) return

    if (this.containerTarget.innerHTML) return

    fetch(cardPath)
      .then(r => r.text())
      .then(html => {
        this.displayPopover(e.target, html)
      })
  }

  displayPopover (target, html) {
    const fragment = document.createRange().createContextualFragment(html)

    this.containerTarget.innerHTML = ''
    this.containerTarget.appendChild(fragment)
    target.classList.add('hovercard')

    createPopper(target, this.containerTarget, {
      placement: 'left-start',
      modifiers: [
        {
          name: 'offset',
          options: { offset: [0, 8] }
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['right', 'left-start']
          }
        }
      ]
    })

    this.containerTarget.addEventListener('mouseout', this.hide.bind(this))
  }

  hasMouseOver (element) {
    return (
      element &&
      element.parentElement &&
      element.parentElement.querySelector(':hover') === element
    )
  }

  hide () {
    const elements = Array.prototype.slice.call(
      document.querySelectorAll('.hovercard')
    )
    const hasHover = elements
      .concat(this.containerTarget)
      .some(element => this.hasMouseOver(element))

    if (!hasHover) {
      this.containerTarget.removeEventListener('mouseout', this.hide)
      elements.forEach(el => el.classList.remove('hovercard'))
      this.containerTarget.innerHTML = ''
    }
  }
}
