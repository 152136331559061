// Based on en-US from https://github.com/date-fns/date-fns/blob/main/src/locale/en-US/_lib/formatDistance/index.ts

const formatDistanceLocale = {
  lessThanXSeconds: {
    one: '< 1s',
    other: '< {{count}}s'
  },

  xSeconds: {
    one: '1s',
    other: '{{count}}s'
  },

  halfAMinute: '30s',

  lessThanXMinutes: {
    one: '< 1m',
    other: '< {{count}}m'
  },

  xMinutes: {
    one: '1m',
    other: '{{count}}m'
  },

  aboutXHours: {
    one: '1h',
    other: '{{count}}h'
  },

  xHours: {
    one: '1h',
    other: '{{count}}h'
  },

  xDays: {
    one: '1d',
    other: '{{count}}d'
  },

  aboutXWeeks: {
    one: '1w',
    other: '{{count}}w'
  },

  xWeeks: {
    one: '1w',
    other: '{{count}} weeks'
  },

  aboutXMonths: {
    one: '1mo',
    other: '{{count}}mo'
  },

  xMonths: {
    one: '1mo',
    other: '{{count}}mo'
  },

  aboutXYears: {
    one: '1y',
    other: '{{count}}y'
  },

  xYears: {
    one: '1y',
    other: '{{count}}y'
  },

  overXYears: {
    one: '1y',
    other: '{{count}}y'
  },

  almostXYears: {
    one: '1y',
    other: '{{count}}y'
  }
}

const formatDistance = (token, count, options) => {
  let result

  const tokenValue = formatDistanceLocale[token]
  if (typeof tokenValue === 'string') {
    result = tokenValue
  } else if (count === 1) {
    result = tokenValue.one
  } else {
    result = tokenValue.other.replace('{{count}}', count.toString())
  }

  if (options?.addSuffix) {
    if (options.comparison && options.comparison > 0) {
      return 'in ' + result
    } else {
      return result + ' ago'
    }
  }

  return result
}

export default formatDistance
